import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Multi Line
 */
class MultiLine extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, className, content, children } = this.props;
        const cnt   = String(content || children);
        const lines = cnt.split("\n");
        
        // Nothing to display
        if (!content && !children) {
            return <React.Fragment />;
        }

        switch (variant) {
        case "p":
            return <div className={className}>
                {lines.map((elem, index) => <p key={index}>{elem}</p>)}
            </div>;
        default:
            return <div className={className}>
                {lines.map((elem, index) => <div key={index}>{elem}</div>)}
            </div>;
        }
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        variant   : PropTypes.string,
        className : PropTypes.string,
        content   : PropTypes.string,
        children  : PropTypes.string,
    }
}

export default MultiLine;
