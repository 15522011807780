import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Helmet }           from "react-helmet";
import Url                  from "Utils/App/Url";
import ClassList            from "Utils/Common/ClassList";
import Metrics              from "Utils/Common/Metrics";
import Utils                from "Utils/Common/Utils";

// Components
import AuthContainer        from "Components/Auth/AuthContainer";
import TermsGuest           from "Components/Terms/TermsGuest";
import Container            from "Components/Core/Container";
import Loader               from "Components/Utils/Common/Loader";
import UserRoute            from "Components/Utils/Route/UserRoute";
import AuthRoute            from "Components/Utils/Route/AuthRoute";
import GuestRoute           from "Components/Utils/Route/GuestRoute";
import ScrollToTop          from "Components/Utils/Route/ScrollToTop";

// Styles
import "Styles/Core/Properties.css";
import "Styles/Core/Fonts.css";
import "Styles/Core/Main.css";
import "Styles/Core/App.css";

// Router
import {
    BrowserRouter, Switch, Redirect,
} from "react-router-dom";

// Actions
import {
    fetchData, setResponsive,
} from "Actions/Core/CoreActions";

// Constants
const MICRO_WIDTH   = 500;
const MOBILE_WIDTH  = 850;
const MOBILE_HEIGHT = 450;
const TABLET_WIDTH  = 1050;
const LAPTOP_WIDTH  = 1200;



/**
 * The App
 */
class App extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchData();
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    /**
     * Unloads the Data
     * @returns {Void}
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    /**
     * Data Loaded
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings } = this.props;
        if (!prevProps.loaded && loaded) {
            Metrics.init(settings);
        }
    }

    /**
     * Handles the Resize
     * @returns {Void}
     */
    handleResize = () => {
        const width    = window.innerWidth;
        const height   = window.innerHeight;
        const isMicro  = width <= MICRO_WIDTH;
        const isMobile = !isMicro && (width <= MOBILE_WIDTH || height <= MOBILE_HEIGHT);
        const isTablet = !isMobile && width <= TABLET_WIDTH;
        const isLaptop = width <= LAPTOP_WIDTH;

        this.props.setResponsive(isMicro, isMobile, isTablet, isLaptop);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, isCordova, settings, styles, isAuthenticated, loaded, loading } = this.props;
        const { isOpen, name, description, keywords                                  } = settings;

        const classes = new ClassList("container");
        classes.addIf("app",     isApp);
        classes.addIf("cordova", isCordova);

        const style = {};
        for (const [ name, value ] of Object.entries(styles)) {
            if (value) {
                const key  = Utils.getPropertyName(name);
                style[key] = Utils.getPropertyValue(value);
            }
        }

        const showAuth     = !isOpen && !isAuthenticated;
        const showRedirect = showAuth && settings.allowLogin;
        let   authRoutes   = [];
        if (isOpen || !isAuthenticated) {
            authRoutes = [ Url.LOGIN, Url.REGISTER, Url.RECOVER, Url.RESET, Url.CODE ];
        }

        return <div className={classes.get()} style={style}>
            <Helmet>
                <title>{name}</title>
                <meta name="description" content={description} />
                <meta name="keywords"    content={keywords}    />
            </Helmet>
            <Loader
                variant={isAuthenticated ? "app" : "auth"}
                show={loading || !loaded}
            />
            
            {loaded && <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        {authRoutes.map((path) => <AuthRoute key={path} path={path} component={AuthContainer} />)}
                        {showAuth     && <GuestRoute path={Url.TERMS} component={TermsGuest} exact />}
                        {!showAuth    && <UserRoute  path={Url.HOME}  component={Container}        />}
                        {showRedirect && <Redirect from="*" to={Url.LOGIN} />}
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchData       : PropTypes.func.isRequired,
        setResponsive   : PropTypes.func.isRequired,
        isApp           : PropTypes.bool.isRequired,
        isCordova       : PropTypes.bool.isRequired,
        loaded          : PropTypes.bool.isRequired,
        loading         : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        styles          : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp           : state.core.isApp,
            isCordova       : state.core.isCordova,
            loaded          : state.core.loaded,
            loading         : state.core.loading,
            settings        : state.core.settings,
            styles          : state.core.styles,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(App.mapStateToProps, {
    fetchData, setResponsive,
})(App);
