import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";

// Components
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Actions
import {
    register, setFocused,
} from "Actions/Core/AuthActions";
import {
    assignCart, setCartRedirect,
} from "Actions/Store/CartActions";



/**
 * The Register Page
 */
class RegisterPage extends React.Component {
    // The Current State
    state = {
        data : {
            socialReason    : "",
            ivaCategory     : "",
            cuit            : "",
            dni             : "",
            fantasyName     : "",
            firstName       : "",
            lastName        : "",
            phone           : "",
            cellphone       : "",
            address         : "",
            locality        : "",
            province        : "",
            zipCode         : "",
            email           : "",
            confirmEmail    : "",
            password        : "",
            confirmPassword : "",
        },
        loading : false,
        errors  : {},
        error   : "",
    }
    


    /**
     * Handles the Focus Change
     * @param {Boolean} isFocused
     * @returns {Void}
     */
    handleFocus = (isFocused) => {
        if (this.props.isApp) {
            this.props.setFocused(isFocused);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }
    
    /**
     * Does a Submit on Touch
     * @param {Event} e
     * @returns {Void}
     */
    handleTouch = (e) => {
        if (this.props.isFocused && !this.state.loading) {
            document.activeElement.blur();
            this.handleSubmit(e);
        }
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const {
            history, location, settings, orderHash, cartRedirect,
            register, assignCart, setCartRedirect,
        } = this.props;
        const { data, loading } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, errors : {}, error : "" });
        try {
            data.orderHash = orderHash;
            const response = await register(data);
            if (orderHash) {
                await assignCart(response.clientID, orderHash);
            }
            if (settings.validateRegister) {
                setCartRedirect("");
                history.push(Url.LOGIN);
            } else if (cartRedirect) {
                setCartRedirect("");
                history.push(cartRedirect);
            } else {
                const redirect = location.state ? location.state.from.pathname : "/";
                history.push(redirect);
            }
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }
    


    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, entities                 } = this.props;
        const { ivaCategories, withCUIT, provinces } = entities;
        const { data, loading, errors, error       } = this.state;

        const fantasyName  = settings.fantasyName;
        const fantasyLabel = fantasyName || "ACCOUNT_FANTASY_NAME";
        const fantasyError = fantasyName && errors.fantasyName ? NLS.format("ACCOUNT_ERROR_FANTASY", fantasyName) : errors.fantasyName;
        const reqCUIT      = withCUIT.indexOf(Number(data.ivaCategory)) > -1 || !data.ivaCategory;
        const reqFiscal    = settings.isFiscalRequired;
        const reqAddress   = settings.isAddressRequired;
        const showFiscal   = settings.isFiscalRequested;
        const showAddress  = settings.isAddressRequested;
        
        return <div className="auth-main auth-register">
            <h2>{NLS.get("AUTH_REGISTER_NAME")}</h2>

            <Form
                className="auth-form"
                error={error}
                onSubmit={this.handleSubmit}
                onClose={this.closeAlert}
            >
                <div className="auth-columns">
                    <TextField
                        show={showFiscal}
                        name="socialReason"
                        label="ACCOUNT_SOCIAL_REASON"
                        value={data.socialReason}
                        error={errors.socialReason}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        shrink
                    />
                    <TextField
                        show={showFiscal}
                        name="fantasyName"
                        label={fantasyLabel}
                        value={data.fantasyName}
                        error={fantasyError}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        shrink
                    />

                    <TextField
                        show={showFiscal}
                        type="select"
                        name="ivaCategory"
                        label="ACCOUNT_IVA_CATEGORY"
                        options={ivaCategories}
                        value={data.ivaCategory}
                        error={errors.ivaCategory}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        withNone
                        shrink
                    />
                    <TextField
                        show={showFiscal && reqCUIT}
                        name="cuit"
                        label="ACCOUNT_CUIT"
                        value={data.cuit}
                        error={errors.cuit}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        shrink
                    />
                    <TextField
                        show={showFiscal && !reqCUIT}
                        name="dni"
                        label="ACCOUNT_DNI"
                        value={data.dni}
                        error={errors.dni}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqFiscal}
                        shrink
                    />
                    
                    <TextField
                        name="firstName"
                        label="ACCOUNT_FIRST_NAME"
                        value={data.firstName}
                        error={errors.firstName}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        name="lastName"
                        label="ACCOUNT_LAST_NAME"
                        value={data.lastName}
                        error={errors.lastName}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        name="phone"
                        label="ACCOUNT_PHONE"
                        value={data.phone}
                        error={errors.phone}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        shrink
                    />
                    <TextField
                        name="cellphone"
                        label="ACCOUNT_CELLPHONE"
                        value={data.cellphone}
                        error={errors.phone}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        shrink
                    />
                </div>

                {showAddress && <div className="auth-columns">
                    <TextField
                        name="address"
                        label="ACCOUNT_ADDRESS"
                        value={data.address}
                        error={errors.address}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        shrink
                    />
                    <TextField
                        name="locality"
                        label="ACCOUNT_LOCALITY"
                        value={data.locality}
                        error={errors.locality}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        shrink
                    />
                    <TextField
                        type="select"
                        name="province"
                        label="ACCOUNT_PROVINCE"
                        options={provinces}
                        value={data.province}
                        error={errors.province}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        withNone
                        shrink
                    />
                    <TextField
                        name="zipCode"
                        label="ACCOUNT_ZIP_CODE"
                        value={data.zipCode}
                        error={errors.zipCode}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired={reqAddress}
                        shrink
                    />
                </div>}

                <div className="auth-columns">
                    <TextField
                        name="email"
                        type="email"
                        label="GENERAL_EMAIL"
                        value={data.email}
                        error={errors.email}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        name="confirmEmail"
                        type="email"
                        label="GENERAL_EMAIL_CONFIRM"
                        value={data.confirmEmail}
                        error={errors.confirmEmail}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        name="password"
                        type="password"
                        label="GENERAL_PASSWORD"
                        autoComplete="new-password"
                        value={data.password}
                        error={errors.password}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                    <TextField
                        name="confirmPassword"
                        type="password"
                        label="GENERAL_PASSWORD_CONFIRM"
                        autoComplete="new-password"
                        value={data.confirmPassword}
                        error={errors.confirmPassword}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        isRequired
                        shrink
                    />
                </div>

                <Button
                    variant="primary"
                    message="AUTH_REGISTER_ACTION"
                    onTouchEnd={this.handleTouch}
                    isDisabled={loading}
                    fullWidth
                />
            </Form>

            <p className="auth-link-route">
                <HyperLink
                    className="auth-link-anchor"
                    href={Url.LOGIN}
                    variant="black"
                    message="AUTH_LOGIN_LINK"
                />
            </p>
            {settings.isOpen && <div className="auth-link-block">
                <HyperLink
                    className="auth-link-enter"
                    variant="black"
                    href={Url.HOME}
                    message="AUTH_LOGIN_ENTER"
                />
            </div>}
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        register        : PropTypes.func.isRequired,
        setFocused      : PropTypes.func.isRequired,
        assignCart      : PropTypes.func.isRequired,
        setCartRedirect : PropTypes.func.isRequired,
        history         : PropTypes.object.isRequired,
        location        : PropTypes.object.isRequired,
        isApp           : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        entities        : PropTypes.object.isRequired,
        isFocused       : PropTypes.bool.isRequired,
        orderHash       : PropTypes.string.isRequired,
        cartRedirect    : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp        : state.core.isApp,
            settings     : state.core.settings,
            entities     : state.core.entities,
            isFocused    : state.auth.isFocused,
            orderHash    : state.cart.elem.orderHash,
            cartRedirect : state.cart.redirect,
        };
    }
}

export default withRouter(connect(RegisterPage.mapStateToProps, {
    register, setFocused, assignCart, setCartRedirect,
})(RegisterPage));
