import React                from "react";
import PropTypes            from "prop-types";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Common/Pagination.css";

// Constants
const WINDOW_WIDTH  = 400;
const PAGES_DESKTOP = 5;
const PAGES_MOBILE  = 3;



/**
 * The Pagination
 */
class Pagination extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { current, total, url } = this.props;
        
        if (total <= 1) {
            return <React.Fragment />;
        }
        
        const totalPages = window.innerWidth < WINDOW_WIDTH ? PAGES_MOBILE : PAGES_DESKTOP;
        const middlePage = Math.ceil(totalPages / 2);
        const prevPage   = Math.max(1,     current - 1);
        const nextPage   = Math.min(total, current + 1);
        const fromPad    = current > total - middlePage ? totalPages - (total - current) : middlePage;
        const toPad      = current < middlePage + 1     ? totalPages + 1 - current       : middlePage;
        const fromPage   = Math.max(1,     current - fromPad);
        const toPage     = Math.min(total, current + toPad);
        const pages      = [];
        
        for (let i = fromPage; i <= toPage; i++) {
            pages.push(i);
        }

        return <ul className="no-list pagination">
            <li className="pagination-arrow">
                <HyperLink
                    variant="none"
                    href={`${url}/${prevPage}`}
                    className={current === 1 ? "pagination-disabled" : ""}
                >
                    <Icon variant="left" />
                </HyperLink>
            </li>
            {pages.map((page) => <li key={page}>
                <HyperLink
                    variant="none"
                    href={`${url}/${page}`}
                    message={String(page)}
                    className={page === current ? "pagination-selected" : ""}
                />
            </li>)}
            <li className="pagination-arrow">
                <HyperLink
                    variant="none"
                    href={`${url}/${nextPage}`}
                    className={current === total ? "pagination-disabled" : ""}
                >
                    <Icon variant="right" />
                </HyperLink>
            </li>
        </ul>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        current : PropTypes.number.isRequired,
        total   : PropTypes.number.isRequired,
        url     : PropTypes.string.isRequired,
    }
}

export default Pagination;
