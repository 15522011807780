import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Utils/Common/Utils";

// Styles
import "Styles/Components/Utils/Form/NumberField.css";



/**
 * The Number Field
 */
class NumberField extends React.Component {
    // The Current State
    state = {
        value      : 0,
        startValue : 0,
    }

    // References
    decNode = React.createRef();
    incNode = React.createRef();



    /**
     * Set when the component mounts
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({ value : this.props.value });

        this.decNode.current.ontouchstart = this.touchDecrease;
        this.incNode.current.ontouchstart = this.touchIncrease;
    }

    /**
     * Updated when a Prop change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value : this.props.value });
        }
    }

    /**
     * Handles the Increase
     * @param {Event} e
     * @returns {Void}
     */
    increase = (e) => {
        if (e.nativeEvent.which === 1 && !this.props.isDisabled) {
            this.startTimeout(1, 1);
            Utils.clearSelection();
        }
    }
    
    /**
     * Handles the Decrease
     * @param {Event} e
     * @returns {Void}
     */
    decrease = (e) => {
        if (e.nativeEvent.which === 1 && !this.props.isDisabled) {
            this.startTimeout(-1, 1);
            Utils.clearSelection();
        }
    }

    /**
     * Handles the Touch Increase
     * @param {Event} e
     * @returns {Void}
     */
    touchIncrease = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.startTimeout(1, 1);
        Utils.clearSelection();
    }

    /**
     * Handles the Touch Decrease
     * @param {Event} e
     * @returns {Void}
     */
    touchDecrease = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.startTimeout(-1, 1);
        Utils.clearSelection();
    }

    /**
     * Starts the Timeout
     * @param {Number} amount
     * @param {Number} times
     * @returns {Void}
     */
    startTimeout(amount, times) {
        this.endTimeout();
        const { value, startValue } = this.state;
        const newValue = (value === "" ? startValue : value) + amount;
        
        if (newValue >= this.props.minValue) {
            const timer   = times > 1 ? 100 : 500;
            const timeout = window.setTimeout(() => this.startTimeout(amount, times + 1), timer);
            this.setState({ value : newValue, timeout });
        }
    }

    /**
     * Ends the Timeout
     * @returns {Void}
     */
    endTimeout = () => {
        if (this.state.timeout) {
            window.clearTimeout(this.state.timeout);
            this.setState({ timeout : null });
        }
    }

    /**
     * Releases the Input
     * @returns {Void}
     */
    releaseInput = () => {
        this.endTimeout();
        this.props.onChange(this.props.name, this.state.value);
    }



    /**
     * Handle the Input Change
     * @param {Event} e
     * @returns {Void}
     */
    handleChange = (e) => {
        const value = Number(e.target.value);
        if (value !== this.state.value) {
            this.setState({ value });
        }
    }

    /**
     * Handle the Focus
     * @returns {Void}
     */
    handleFocus = () => {
        this.setState({
            startValue : this.state.value,
            value      : "",
        });
    }

    /**
     * Handle the Blur
     * @returns {Void}
     */
    handleBlur = () => {
        const { startValue, value } = this.state;
        if (value === "") {
            this.setState({ value : startValue });
        } else if (value !== startValue) {
            this.props.onChange(this.props.name, value);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, className, name, isDisabled } = this.props;
        const { value                                } = this.state;
        
        return <div className={`number-field number-${variant} ${className}`}>
            <button
                className="number-dec"
                ref={this.decNode}
                onTouchEnd={this.releaseInput}
                onMouseDown={this.decrease}
                onMouseUp={this.releaseInput}
            >-</button>
            <input
                type="number"
                pattern="\d*"
                name={name}
                value={value}
                disabled={isDisabled}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            />
            <button
                className="number-inc"
                ref={this.incNode}
                onTouchEnd={this.releaseInput}
                onMouseDown={this.increase}
                onMouseUp={this.releaseInput}
            >+</button>
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        variant    : PropTypes.string,
        className  : PropTypes.string,
        name       : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
        value      : PropTypes.number,
        minValue   : PropTypes.number,
        onChange   : PropTypes.func.isRequired,
        isDisabled : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        variant    : "big",
        className  : "",
        minValue   : 1,
        isDisabled : false,
    }
}

export default NumberField;
