import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import HeaderLogo           from "Components/Core/Header/HeaderLogo";
import HeaderBar            from "Components/Core/Header/HeaderBar";
import MenuBar              from "Components/Core/Header/MenuBar";
import Wrapper              from "Components/Utils/Common/Wrapper";

// Styles
import "Styles/Components/Core/Header.css";



/**
 * The Header
 */
class Header extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isLaptop } = this.props;

        const bigLogo = !!settings.bigLogo && !isLaptop;
        const header  = <div className="header-header">
            <HeaderBar />
            <MenuBar />
        </div>;

        return <header className="header-container">
            <Wrapper className={bigLogo ? "header-big-logo" : ""}>
                {!bigLogo ? header : <>
                    <HeaderLogo />
                    {header}
                </>}
            </Wrapper>
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        isLaptop : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            isLaptop : state.core.isLaptop,
        };
    }
}

export default connect(Header.mapStateToProps)(Header);
