import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import Image                from "Components/Utils/Common/Image";
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Common/Slider.css";



/**
 * The Slider
 */
class Slider extends React.Component {
    // The Current State
    state = {
        isMounted   : false,
        curIdx      : 0,
        moveIdx     : 0,
        direction   : 0,
        touchStart  : 0,
        touchDiff   : 0,
        animate     : false,
        loopTimeout : null,
        moveTimeout : null,
        endTimeout  : null,
        zooming     : false,
        zoomBounds  : null,
    }

    // References
    sliderElem = null;

    

    /**
     * Starts the Timeout
     * @returns {Void}
     */
    componentDidMount() {
        this.setState({
            isMounted   : true,
            loopTimeout : this.autoSlide(),
            curIdx      : this.props.index || 0,
        });
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        this.clearTimeouts();
        this.setState({
            isMounted   : false,
            loopTimeout : null,
            moveTimeout : null,
            endTimeout  : null,
        });
    }

    /**
     * Updates the Index
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.index !== prevProps.index && this.state.curIdx !== this.props.index) {
            this.setState({ curIdx : this.props.index });
        }
    }

    /**
     * Returns the Auto Slide
     * @returns {Object}
     */
    autoSlide() {
        const { autoSlide, data, settings } = this.props;
        if (autoSlide && data.length > 1) {
            const time = (settings.slidesTime || 5) * 1000;
            return window.setTimeout(() => this.gotoDir(1), time);
        }
        return null;
    }



    /**
     * Moves the Carousel to the given Index
     * @param {Number} moveIdx
     * @returns {Void}
     */
    gotoIndex = (moveIdx) => {
        this.startMove(moveIdx, moveIdx > this.state.curIdx ? 1 : -1);
    }

    /**
     * Moves the Carousel to the given Direction
     * @param {Number} direction
     * @returns {Void}
     */
    gotoDir = (direction) => {
        let moveIdx = this.state.curIdx + direction;
        if (moveIdx > this.props.data.length - 1) {
            moveIdx = 0;
        } else if (moveIdx < 0) {
            moveIdx = this.props.data.length - 1;
        }
        this.startMove(moveIdx, direction);
    }

    /**
     * Starts the Movements
     * @param {Number} moveIdx
     * @param {Number} direction
     * @returns {Void}
     */
    startMove(moveIdx, direction) {
        if (!this.state.isMounted) {
            return;
        }
        
        this.clearTimeouts();
        this.setState({
            moveIdx, direction,
            animate     : true,
            loopTimeout : null,
            moveTimeout : window.setTimeout(this.endMove, 300),
        });
    }

    /**
     * Ends the Movement
     * @returns {Void}
     */
    endMove = () => {
        if (!this.state.isMounted) {
            return;
        }
        const endTimeout = window.setTimeout(() => {
            this.setState({ animate : false, direction : 0 });
        }, 50);
        
        if (this.props.onSwitch) {
            this.props.onSwitch(this.state.moveIdx);
        }
        this.setState({
            curIdx      : this.state.moveIdx,
            loopTimeout : this.autoSlide(),
            moveTimeout : null,
            endTimeout  : endTimeout,
        });
    }

    /**
     * Clears the Timeouts
     * @returns {Void}
     */
    clearTimeouts() {
        const { loopTimeout, moveTimeout, endTimeout } = this.state;
        if (loopTimeout) {
            window.clearTimeout(loopTimeout);
        }
        if (moveTimeout) {
            window.clearTimeout(moveTimeout);
        }
        if (endTimeout) {
            this.setState({ animate : false, direction : 0 });
            window.clearTimeout(endTimeout);
        }
    }



    /**
     * Moves the Carousel to the given Direction
     * @param {Number} direction
     * @returns {Function}
     */
    handleDir = (direction) => (e) => {
        this.gotoDir(direction);
        e.preventDefault();
        e.stopPropagation();
    }

    /**
     * Handles the Touch Start
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchStart = (e) => {
        const { isMounted, loopTimeout } = this.state;
        if (!isMounted || this.props.data.length === 1) {
            return;
        }
        if (loopTimeout) {
            window.clearTimeout(loopTimeout);
        }
        this.setState({
            loopTimeout : null,
            touchStart  : e.touches[0].clientX,
        });
    }

    /**
     * Handles the Touch Move
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchMove = (e) => {
        const { isMounted, moveTimout, touchStart } = this.state;
        if (!isMounted || moveTimout || this.props.data.length === 1) {
            return;
        }
        const currentX  = e.touches[0].clientX;
        const touchDiff = touchStart - currentX;
        this.setState({ touchDiff });
    }

    /**
     * Handles the Touch End
     * @param {Event} e
     * @returns {Void}
     */
    handleTouchEnd = (e) => {
        const { isMounted, curIdx, touchDiff } = this.state;
        if (!isMounted || this.props.data.length === 1) {
            return;
        }
        this.setState({ touchStart : 0, touchDiff : 0 });
        if (Math.abs(touchDiff) < 30 && this.props.onClick) {
            this.handleClick(this.props.data[curIdx]);
        } else if (touchDiff > 0) {
            this.gotoDir(1);
        } else {
            this.gotoDir(-1);
        }
    }

    /**
     * Handles the Click
     * @param {Object} elem
     * @returns {Void}
     */
    handleClick = (elem) => {
        if (this.props.onClick) {
            this.props.onClick(elem);
        }
    }

    /**
     * Handles the Zoom
     * @param {Boolean} zooming
     * @param {Object}  zoomBounds
     * @returns {Void}
     */
    handleZoom = (zooming, zoomBounds) => {
        this.setState({ zooming, zoomBounds });
    }



    /**
     * Parses the State and Returns the Slides
     * @returns {Object}
     */
    getSlides() {
        const { curIdx, moveIdx, direction } = this.state;
        const { data }                       = this.props;

        let prevIdx = curIdx - 1 < 0 ? data.length - 1 : curIdx - 1;
        let nextIdx = curIdx + 1 > data.length - 1 ? 0 : curIdx + 1;
        let moveTo  = 1;
        
        if (direction !== 0) {
            if (direction > 0) {
                nextIdx = moveIdx;
                moveTo  = 2;
            } else {
                prevIdx = moveIdx;
                moveTo  = 0;
            }
        }
        const slides = {
            "prev"    : data[prevIdx],
            "current" : data[curIdx],
            "next"    : data[nextIdx],
        };

        return { slides, moveTo, moveIdx };
    }

    /**
     * Renders an Element
     * @param {Object} elem
     * @returns {Object}
     */
    renderElem(elem) {
        const { variant, withZoom } = this.props;

        if (elem.isVideo) {
            return <div className="slider-video">
                <video
                    width="1900"
                    height="800"
                    src={elem[variant]}
                    preload="auto"
                    muted
                    loop
                    autoPlay
                >
                    <source type="video/mp4" src={elem[variant]} />
                </video>
            </div>;
        }
            
        return <Image
            source={elem[variant]}
            zoomed={elem.large}
            name={elem.name}
            onZoom={this.handleZoom}
            onClick={() => this.handleClick(elem)}
            withZoom={withZoom}
            hideZoomed
        />;
    }

    /**
     * Renders the Zoom
     * @param {Object} elem
     * @returns {Object}
     */
    renderZoom(source, name) {
        const { zooming, zoomBounds } = this.state;
        if (zooming) {
            const style    = { left      : `${zoomBounds.left}px` };
            const imgStyle = { transform : `translate(-${zoomBounds.x}px, -${zoomBounds.y}px)` };
            return <div className="image-zoomed" style={style}>
                <img src={source} alt={name} style={imgStyle} />
            </div>;
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { slides, moveTo, moveIdx                         } = this.getSlides();
        const { centerImage, data, className, withDots, heights } = this.props;
        const { touchDiff, animate                              } = this.state;

        const dots    = Utils.createArrayOf(data.length);
        const hasNav  = data.length > 1;
        const hasDots = withDots && data.length > 1;
        const styleCt = {};
        const styleCn = {};
        
        const classes = new ClassList("slider-container", className);
        classes.addIf("slider-centered", centerImage);
        classes.addIf("slider-animate",  animate);

        if (heights && heights.length) {
            for (const [ index, value ] of heights.entries()) {
                styleCt[`--slider-height${index + 1}`] = `${value}px`;
            }
        }
        if (this.sliderElem && touchDiff !== 0) {
            const rect = this.sliderElem.getBoundingClientRect();
            const size = rect.width + touchDiff;
            styleCn.transform = `translateX(-${size}px)`;
        }

        return <>
            <div
                ref={(elem) => { this.sliderElem = elem; }}
                className={classes.get()}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
                style={styleCt}
            >
                <div className="slider-images" data-move={moveTo} style={styleCn}>
                    {Object.entries(slides).map(([ type, elem ]) => (
                        <div key={type} className={`slider-${type}`}>
                            {this.renderElem(elem)}
                        </div>
                    ))}
                </div>
                {hasNav && <nav className="slider-nav">
                    <button className="slider-prev" onClick={this.handleDir(-1)}>
                        <Icon variant="left" />
                    </button>
                    <button className="slider-next" onClick={this.handleDir(1)}>
                        <Icon variant="right" />
                    </button>
                </nav>}
                {hasDots && <nav className="slider-dots">
                    <ul className="no-list">
                        {dots.map((elem, index) => <li key={index}>
                            <button
                                className={moveIdx === index ? "slider-active" : ""}
                                onClick={() => this.gotoIndex(index)}
                            >
                                {index}
                            </button>
                        </li>)}
                    </ul>
                </nav>}
            </div>
            {this.renderZoom(slides.current.large, slides.current.name)}
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings    : PropTypes.object.isRequired,
        data        : PropTypes.array.isRequired,
        variant     : PropTypes.string,
        index       : PropTypes.number,
        onClick     : PropTypes.func,
        onSwitch    : PropTypes.func,
        className   : PropTypes.string,
        autoSlide   : PropTypes.bool,
        withDots    : PropTypes.bool,
        withZoom    : PropTypes.bool,
        centerImage : PropTypes.bool,
        heights     : PropTypes.array,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        variant     : "image",
        autoSlide   : false,
        withDots    : false,
        withZoom    : false,
        centerImage : false,
        heights     : [],
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(Slider.mapStateToProps)(Slider);
