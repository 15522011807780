import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Terms/Terms.css";



/**
 * The Guest Terms Page
 */
class TermsGuest extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, terms } = this.props;
        const { logo, name      } = settings;

        return <div className="terms-container">
            <header className="terms-header">
                <h1>{logo ? <img src={logo} alt={name} /> : name}</h1>
                <div className="terms-title">
                    <HyperLink variant="icon" icon="back" href={Url.LOGIN} />
                    <h2>{NLS.get("TERMS_NAME")}</h2>
                </div>
            </header>
            <div className="terms-content">
                <Card className="terms-card" withSpacing withBorder>
                    {terms ? <Html content={terms} /> : NLS.get("TERMS_NAME")}
                </Card>
            </div>
        </div>;
    }


    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings : PropTypes.object.isRequired,
        terms    : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            terms    : state.core.terms,
        };
    }
}

export default connect(TermsGuest.mapStateToProps)(TermsGuest);
