import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCategories }  from "Actions/Store/ProductActions";
import Url                  from "Utils/App/Url";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Store/Category.css";



/**
 * The Category Page
 */
class CategoryPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchCategories();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { list } = this.props;

        return <>
            <Title message="CATEGORIES_TITLE" />
            <Wrapper withSpacing>
                <Card className="categories-container" withBorder>
                    {list.map((elem) => <section key={elem.id}>
                        <SubTitle
                            message={elem.name}
                            href={`${Url.PRODUCTS}/${elem.url}`}
                        />
                        <div className="spacing categories-content">
                            <ul className="no-list categories-list">
                                {elem.childs.map((subelem) => <li key={subelem.id}>
                                    <HyperLink
                                        variant="opacity"
                                        href={`${Url.PRODUCTS}/${elem.url}/${subelem.url}`}
                                        message={subelem.name}
                                    />
                                    {subelem.childs.length > 0 && <ul className="no-list">
                                        {subelem.childs.map((terelem) => <li key={terelem.id}>
                                            <HyperLink
                                                variant="opacity"
                                                href={`${Url.PRODUCTS}/${elem.url}/${subelem.url}/${terelem.url}`}
                                                message={terelem.name}
                                            />
                                        </li>)}
                                    </ul>}
                                </li>)}
                            </ul>
                        </div>
                    </section>)}
                </Card>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCategories : PropTypes.func.isRequired,
        list            : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            list : state.product.categories,
        };
    }
}

export default connect(CategoryPage.mapStateToProps, {
    fetchCategories,
})(CategoryPage);
