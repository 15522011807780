import React                from "react";
import PropTypes            from "prop-types";
import { withRouter }       from "react-router";
import Metrics              from "Utils/Common/Metrics";
import Utils                from "Utils/Common/Utils";



/**
 * Scrolls to the Top on location change
 */
class ScrollToTop extends React.Component {
    /**
     * Scroll to the Top
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            Metrics.pageView(prevProps.location.pathname);

            const top = Utils.getScrollTop();
            if (top > 0) {
                this.speed = Math.max(Math.floor(top / 40), 100);
                this.scrollUp();
            }
        }
    }

    /**
     * Scrolls to the top smoothly
     * @returns {Void}
     */
    scrollUp = () => {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        const top = Utils.getScrollTop();
        if (top > 0) {
            window.scrollTo(0, top - this.speed);
            this.timeout = window.setTimeout(this.scrollUp, 10);
        }
    }
    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return this.props.children;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        location : PropTypes.object.isRequired,
        children : PropTypes.any.isRequired,
    }
}
  
export default withRouter(ScrollToTop);
