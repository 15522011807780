import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import Title                from "Components/Utils/Common/Title";
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Product/List/ProductsHeader.css";



/**
 * The Products Header
 */
class ProductsHeader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, className, message, submessage, banner, logo, url,
            height, showBanner, showOverlay, showLogo, showTitle,
        } = this.props;
        const { titleCenter, titleBottomSpace, titleShadows } = settings;
    
        if (!showBanner || !banner) {
            return <Title message={message} submessage={submessage} />;
        }

        const style = {};
        if (banner) {
            style.backgroundImage = `url(${banner})`;
        }
        if (height) {
            style.height = `${height}px`;
        }

        const classes = new ClassList("products-header", className);
        classes.addIf("title-space",   titleBottomSpace);
        classes.addIf("title-shadows", titleShadows);
        classes.addIf("title-center",  titleCenter);

        return <header className={classes.get()} style={style}>
            {showOverlay && <div className="title-overlay" />}
            <Wrapper className="title-wrapper">
                {showLogo && <HyperLink variant="none" href={url} className="products-logo">
                    {logo && <img src={logo} alt={message} />}
                    {!logo && <h2>{NLS.get(message)}</h2>}
                </HyperLink>}
                {showTitle && <div className="title-content">
                    <h2>{NLS.get(message)}</h2>
                    {submessage && <h3>{submessage}</h3>}
                </div>}
            </Wrapper>
        </header>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings    : PropTypes.object.isRequired,
        className   : PropTypes.string,
        message     : PropTypes.string,
        submessage  : PropTypes.string,
        banner      : PropTypes.string,
        logo        : PropTypes.string,
        url         : PropTypes.string,
        height      : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        showBanner  : PropTypes.bool,
        showOverlay : PropTypes.bool,
        showLogo    : PropTypes.bool,
        showTitle   : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        height      : 0,
        showBanner  : false,
        showOverlay : false,
        showLogo    : false,
        showTitle   : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductsHeader.mapStateToProps)(ProductsHeader);
