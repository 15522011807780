import API                  from "Utils/App/API";

// Types
import {
    ORDER_LOADING, ORDER_LIST, ORDER_ELEM,
} from "Utils/Types";



/**
 * Fetches the Order List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchOrders(params) {
    return async (dispatch) => {
        dispatch({ type : ORDER_LOADING });
        const data = await API.Order.getOrders(params);
        dispatch({ type : ORDER_LIST, data });
    };
}

/**
 * Fetches the Order Data
 * @param {Number} orderID
 * @returns {Function}
 */
export function fetchOrder(orderID) {
    return async (dispatch) => {
        dispatch({ type : ORDER_LOADING });
        const data = await API.Order.getOrder({ orderID });
        dispatch({ type : ORDER_ELEM, data });
    };
}
