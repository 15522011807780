import Utils                from "Utils/Common/Utils";

// Types
import {
    ADDRESS_LOADING, ADDRESS_UNSET,
    ADDRESS_LIST, ADDRESS_ELEM,
    ADDRESS_EDITED, ADDRESS_ACK,
} from "Utils/Types";



// The initial State
const initialState = {
    loading : false,
    error   : false,
    list    : [],
    total   : 0,
    elem    : {},
    edited  : "",
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, ADDRESS_LIST, ADDRESS_ELEM)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case ADDRESS_LOADING:
        return {
            ...state,
            loading : true,
        };
    case ADDRESS_UNSET:
        return {
            ...state,
            loading : true,
            elem    : { ...initialState.elem },
        };
    
    case ADDRESS_LIST:
        return {
            ...state,
            loading : false,
            error   : false,
            list    : action.data.list,
            total   : action.data.total,
        };
    case ADDRESS_ELEM:
        return {
            ...state,
            loading : false,
            error   : false,
            elem    : action.data,
        };
    
    case ADDRESS_EDITED:
        return {
            ...state,
            loading : false,
            error   : false,
            edited  : action.data.success,
        };
    case ADDRESS_ACK:
        return {
            ...state,
            edited  : "",
        };
    default:
        return state;
    }
};
