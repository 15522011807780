import NLS                  from "Utils/App/NLS";



/** @const {Object.<String, string>} The formats used in toString */
const FORMATS = {
    time          : "DATE_TIME",
    dayTime       : "DATE_DAY_TIME",
    dayMonth      : "DATE_DAY_MONTH",
    dayMonthYear  : "DATE_DAY_MONTH_YEAR",
    monthYear     : "DATE_MONTH_YEAR",
    reduced       : "DATE_REDUCED",
    complete      : "DATE_COMPLETE",
    shortComplete : "DATE_SHORT_COMPLETE",
    dashes        : "DATE_DASHES",
    dashesReverse : "DATE_DASHES_REVERSE",
    dashesTime    : "DATE_DASHES_TIME",
    slashes       : "DATE_SLASHES",
    slashesDay    : "DATE_SLASHES_DAY",
    sortable      : "DATE_SORTABLE",

    now           : "DATE_NOW",
    minAgo        : "DATE_MIN_AGO",
    minsAgo       : "DATE_MINS_AGO",
    hourAgo       : "DATE_HOUR_AGO",
    hoursAgo      : "DATE_HOURS_AGO",
    dayAgo        : "DATE_DAY_AGO",
    daysAgo       : "DATE_DAYS_AGO",
    inMin         : "DATE_IN_MIN",
    inMins        : "DATE_IN_MINS",
    inHour        : "DATE_IN_HOUR",
    inHours       : "DATE_IN_HOURS",
    inDay         : "DATE_IN_DAY",
    inDays        : "DATE_IN_DAYS",
    tomorrow      : "DATE_TOMORROW_AT",
    today         : "DATE_TODAY_AT",
    yesterday     : "DATE_YESTERDAY_AT",
    thisYear      : "DATE_THIS_YEAR",
    otherYear     : "DATE_OTHER_YEAR",
};



/**
 * Returns number as a String with a 0 infront
 * @param {Number} time
 * @returns {String}
 */
function parseTime(time) {
    return time < 10 ? "0" + time : time;
}

/**
 * Returns the month name for a given month
 * @param {Number=} month - A possible month number (starting from 1)
 * @returns {String}
 */
function monthToName(month) {
    const months = NLS.get("DATE_MONTH_NAMES");
    return months[month] || "";
}



/**
 * The DateTime class, a Date object with lots of added functions
 * @constructor
 * @param {(Number|Date)} date
 * @param {Boolean=}      inMiliseconds
 */
class DateTime {
    constructor(date, inMiliseconds) {
        if (date && !(date instanceof Date)) {
            this.date = new Date(parseInt(date, 10) * (!inMiliseconds ? 1000 : 1));
        } else if (date) {
            this.date = new Date(date);
        } else {
            this.date = new Date();
        }
    }
    
    /**
     * Returns a Copy of DateTime
     * @returns {DateTime}
     */
    copy() {
        return new DateTime(this.date);
    }
    
    /**
     * Creates a new DateTime with the given data and the current saved date
     * @param {Number=} year
     * @param {Number=} month
     * @param {Number=} day
     * @param {Number=} hours
     * @param {Number=} mins
     * @param {Number=} secs
     * @param {Number=} milis
     * @returns {DateTime}
     */
    createDay(year, month, day, hours, mins, secs, milis) {
        return new DateTime(this.toDate(year, month, day, hours, mins, secs, milis));
    }
    
    /**
     * Returns a new Date using the current saved date
     * @param {Number=} year
     * @param {Number=} month
     * @param {Number=} day
     * @param {Number=} hours
     * @param {Number=} mins
     * @param {Number=} secs
     * @param {Number=} milis
     * @returns {Date}
     */
    toDate(year, month, day, hours, mins, secs, milis) {
        return new Date(
            (year  || year  === 0) ? year  : this.date.getFullYear(),
            (month || month === 0) ? month : this.date.getMonth(),
            (day   || day   === 0) ? day   : this.date.getDate(),
            (hours || hours === 0) ? hours : this.date.getHours(),
            (mins  || mins  === 0) ? mins  : this.date.getMinutes(),
            (secs  || secs  === 0) ? secs  : this.date.getSeconds(),
            (milis || milis === 0) ? milis : this.date.getMilliseconds()
        );
    }
    
    /**
     * Returns the time in seconds
     * @returns {Number}
     */
    toTime() {
        return Math.round(this.date.getTime() / 1000);
    }
    
    /**
     * Returns a new DateTime with the same day, but at 0 hours, 0 minutes, 0 seconds and 0 miliseconds
     * @param {Number=} hours
     * @returns {DateTime}
     */
    toDayStart(hours) {
        return this.createDay(undefined, undefined, undefined, hours !== undefined ? hours : 0, 0, 0, 0);
    }
    
    /**
     * Returns a new DateTime with the same day, but at 23 hours, 59 minutes, 59 seconds and 0 miliseconds
     * @returns {DateTime}
     */
    toDayEnd() {
        return this.createDay(undefined, undefined, undefined, 23, 59, 29, 0);
    }
    
    /**
     * Returns a new DateTime at the start of the week, depending on the week day given
     * @param {Number=} weekDay
     * @returns {DateTime}
     */
    toWeekStart(weekDay = 0) {
        let day = this;
        while (day.getWeekDay() !== weekDay) {
            day = day.moveDay(-1);
        }
        return day;
    }
    
    /**
     * Returns a new DateTime at the end of the week, depending on the week day given
     * @param {Number=} weekDay
     * @returns {DateTime}
     */
    toWeekEnd(weekDay) {
        return this.moveDay(7).toWeekStart(weekDay).moveDay(-1);
    }
    
    /**
     * Returns a new DateTime at the start of the month
     * @returns {DateTime}
     */
    toMonthStart() {
        return this.changeDay(1);
    }
    
    /**
     * Returns a new DateTime at the end of the month
     * @returns {DateTime}
     */
    toMonthEnd() {
        return this.changeDay(this.getMonthDays());
    }
    
    
    
    /**
     * Gives a string format to the Date depending on the type chosen
     * @param {String} type
     * @returns {String}
     */
    toString(type) {
        if (FORMATS[type]) {
            return NLS.get(FORMATS[type])
                .replace("{d}",  this.getDay())
                .replace("{d0}", parseTime(this.getDay()))
                .replace("{dn}", this.getDayName())
                .replace("{d3}", this.getDayName(3))
                .replace("{m}",  this.getMonth() + 1)
                .replace("{m0}", parseTime(this.getMonth() + 1))
                .replace("{mn}", this.getMonthName())
                .replace("{m3}", this.getMonthName(3))
                .replace("{y}",  this.getYear())
                .replace("{h}",  parseTime(this.getHours()))
                .replace("{i}",  parseTime(this.getMinutes()));
        }
        return "";
    }
    
    /**
     * Gives a string format to the Date depending on date
     * @returns {String}
     */
    toTimeString() {
        const min   = 60 * 1000;
        const hour  = 60 * min;
        const day   = 24 * hour;
        const today = new Date().getTime();
        const time  = this.date.getTime();
        const diff1 = time - today;
        const diff2 = today - time;
        const diff  = diff1 > 0 ? diff1 : diff2;
        const dmin  = Math.round(diff / min);
        const dhour = Math.round(diff / hour);
        const dday  = Math.round(diff / day);
        let   format;
        
        if (dmin === 0) {
            return NLS.get(FORMATS.now);
        }

        if (diff1 > 0) {
            if (diff1 < hour * 2) {
                format = dmin === 1  ? "inMin"  : "inMins";
            } else if (diff1 < day * 2) {
                format = dhour === 1 ? "inHour" : "inHours";
            } else if (diff1 < day * 3) {
                format = dday === 1  ? "inDay"  : "inDays";
            }
        } else if (diff2 > 0) {
            if (diff2 < hour * 2) {
                format = dmin === 1  ? "minAgo"  : "minsAgo";
            } else if (diff2 < day * 2) {
                format = dhour === 1 ? "hourAgo" : "hoursAgo";
            } else if (diff2 < day * 3) {
                format = dday === 1  ? "dayAgo"  : "daysAgo";
            }
        }

        if (format) {
            return NLS.get(FORMATS[format])
                .replace("{i}", dmin)
                .replace("{h}", dhour)
                .replace("{d}", dday);
        }
        
        if (this.isToday()) {
            format = "today";
        } else if (this.isYesterday()) {
            format = "yesterday";
        } else if (this.isTomorrow()) {
            format = "tomorrow";
        } else if (this.isThisYear()) {
            format = "thisYear";
        } else {
            format = "otherYear";
        }
        return this.toString(format);
    }
    
    /**
     * Returns the name of the day of the saved date to Today/Yesterday/Tomorrow
     * @returns {String}
     */
    toDayString() {
        let result;
        if (this.isYesterday()) {
            result = NLS.get("DATE_YESTERDAY");
        } else if (this.isToday()) {
            result = NLS.get("DATE_TODAY");
        } else if (this.isTomorrow()) {
            result = NLS.get("DATE_TOMORROW");
        } else if (this.isThisYear()) {
            result = this.toString("reduced");
        } else {
            result = this.toString("otherYear");
        }
        return result;
    }
    
    /**
     * Knowing that the current day is at the start of the week, it returns a string that represents it
     * @returns {String}
     */
    toWeekString() {
        const thisWeek = new DateTime().toWeekStart(this.getWeekDay());
        const lastWeek = thisWeek.moveDay(-7);
        const nextWeek = thisWeek.moveDay(+7);

        if (this.isEqualDayTo(lastWeek)) {
            return NLS.get("DATE_LAST_WEEK");
        }
        if (this.isEqualDayTo(thisWeek)) {
            return NLS.get("DATE_THIS_WEEK");
        }
        if (this.isEqualDayTo(nextWeek)) {
            return NLS.get("DATE_NEXT_WEEK");
        }
        return NLS.format(
            "DATE_PARSED_WEEK",
            this.toString("slashesDay"),
            this.moveDay(6).toString("slashesDay")
        );
    }
    
    /**
     * Parses the duration between the current DateTime and the given one
     * @param {DateTime} day
     * @returns {String}
     */
    parseDuration(day) {
        return NLS.format("DATE_DURATION", this.toString("time"), day.toString("time"));
    }
    
    
    
    /**
     * Adds color to the string depending on the Date
     * @param {String} str
     * @returns {String}
     */
    addColor(str) {
        const days = this.getExpiredDays();
        if (days < 0) {
            return `<span class="error-result">${str}</span>`;
        }
        if (days < 3) {
            return `<span class="warning-result">${str}</span>`;
        }
        return `<span class="success-result">${str}</span>`;
    }
    
    /**
     * Returns a circle with a color depending on the Date
     * @param {String} str
     * @returns {String}
     */
    addCircle(str) {
        return `<span class="${this.getCircleClass()}"></span> ${str}`;
    }
    
    /**
     * Returns a circle class depending on the Date
     * @param {String} str
     * @returns {String}
     */
    getCircleClass() {
        const days = this.getExpiredDays();
        if (days < 0) {
            return "circle circle-red";
        }
        if (days < 3) {
            return "circle circle-yellow";
        }
        return "circle circle-green";
    }

    /**
     * Returns a class depending on the Date
     * @param {String} str
     * @returns {String}
     */
    getTextClass() {
        const days = this.getExpiredDays();
        if (days < 0) {
            return "text-error";
        }
        if (days < 3) {
            return "text-warning";
        }
        return "text-success";
    }
    
    /**
     * Returns an amount of days since the expiration
     * @returns {Number}
     */
    getExpiredDays() {
        const today = (new Date()).getTime();
        const time  = this.date.getTime();
        const diff  = time - today;
        const mil   = 24 * 60 * 60 * 1000;
        const days  = Math.round(diff / mil);
        
        return days;
    }
    
    
    
    /**
     * Returns true if the day is Suterday or Sunday
     * @returns {Boolean}
     */
    isWeekend() {
        const day = this.getWeekDay();
        return day === 0 || day === 6;
    }
    
    /**
     * Returns true if the saved date is the same as today date
     * @returns {Boolean}
     */
    isToday() {
        return this.isEqualDayTo(new DateTime());
    }
    
    /**
     * Returns true if the saved date is the same as yesterday date
     * @returns {Boolean}
     */
    isYesterday() {
        return this.isNextDay(-1);
    }
    
    /**
     * Returns true if the saved date is the same as tomorrows date
     * @returns {Boolean}
     */
    isTomorrow() {
        return this.isNextDay(1);
    }
    
    /**
     * Returns true if the saved day is the same as the same date plus the given amount of days
     * @param {Number} amount
     * @returns {Boolean}
     */
    isNextDay(amount) {
        const day = new DateTime().moveDay(amount);
        return this.isEqualDayTo(day);
    }
    
    /**
     * Returns true if the saved day comes after today
     * @returns {Boolean}
     */
    isFutureDay() {
        return this.toDayStart().isGreaterThan(new DateTime().toDayStart());
    }
    
    /**
     * Returns true if the year of the saved date is the same as the current year
     * @returns {Boolean}
     */
    isThisYear() {
        return this.getYear() === new DateTime().getYear();
    }
    
    /**
     * Returns true if the time of the saved date is the same as the time of the given day
     * @param {DateTime} day
     * @returns {Boolean}
     */
    isEqualTimeTo(day) {
        return this.toTime() === day.toTime();
    }
    
    /**
     * Returns true if the year, the month and the date are the same between the saved and the given date
     * @param {DateTime} day
     * @returns {Boolean}
     */
    isEqualDayTo(day) {
        return (
            this.getYear()  === day.getYear()  &&
            this.getMonth() === day.getMonth() &&
            this.getDay()   === day.getDay()
        );
    }
    
    /**
     * Returns true if the week is the same between the saved and the given date
     * @param {DateTime} day
     * @returns {Boolean}
     */
    isEqualWeekTo(day) {
        return this.getWeek() === day.getWeek();
    }
    
    /**
     * Returns true if the year and the month are the same between the saved and the given date
     * @param {DateTime} day
     * @returns {Boolean}
     */
    isEqualMonthTo(day) {
        return this.getYear() === day.getYear() && this.getMonth() === day.getMonth();
    }
    
    /**
     * Returns true if the year is the same between the saved and the given date
     * @param {DateTime} day
     * @returns {Boolean}
     */
    isEqualYearTo(day) {
        return this.getYear() === day.getYear();
    }
    
    /**
     * Returns true if the given day is greater than the saved day
     * @param {DateTime=} day
     * @returns {Boolean}
     */
    isGreaterThan(day) {
        const time = day ? day.toTime() : new DateTime().toTime();
        return this.toTime() > time;
    }
    
    /**
     * Returns true if the Current time is in the Past
     * @param {Number=} hours
     * @returns {Boolean}
     */
    isPastTime(hours = 0) {
        return this.toTime() < new DateTime().toTime() - (hours * 3600);
    }
    
    /**
     * Returns max day between the saved and the given one
     * @param {DateTime} day
     * @returns {DateTime}
     */
    max(day) {
        return this.isGreaterThan(day) ? this : day;
    }
    
    /**
     * Returns min day between the saved and the given one
     * @param {DateTime} day
     * @returns {DateTime}
     */
    min(day) {
        return this.isGreaterThan(day) ? day : this;
    }
    
    /**
     * Changes the day to the given one
     * @param {DateTime} day
     * @returns {DateTime}
     */
    changeToDay(day) {
        return this.createDay(day.getYear(), day.getMonth(), day.getDay());
    }
    
    /**
     * Creates a new DateTime as amount of years ahead the saved date
     * @param {Number}  amount
     * @param {Number=} month
     * @param {Number=} day
     * @returns {DateTime}
     */
    moveYear(amount, month, day) {
        return this.createDay(this.getYear() + amount, month, day);
    }
    
    /**
     * Creates a new DateTime with the given year and the saved date
     * @param {Number}  year
     * @param {Number=} month
     * @param {Number=} day
     * @returns {DateTime}
     */
    changeYear(year, month, day) {
        return this.createDay(year, month, day);
    }
    
    /**
     * Creates a new DateTime as amount of months ahead of the saved date
     * @param {Number}  amount
     * @param {Number=} day
     * @returns {DateTime}
     */
    moveMonth(amount, day) {
        return this.createDay(undefined, this.getMonth() + amount, day);
    }
    
    /**
     * Creates a new DateTime with the given month and day and the saved date
     * @param {Number}  month
     * @param {Number=} day
     * @returns {DateTime}
     */
    changeMonth(month, day) {
        return this.createDay(undefined, month, day);
    }
    
    /**
     * Creates a new DateTime as amount of days ahead of the saved date
     * @param {Number} amount
     * @returns {DateTime}
     */
    moveDay(amount) {
        return this.createDay(undefined, undefined, this.getDay() + amount);
    }
    
    /**
     * Creates a new DateTime with the given day and the saved date
     * @param {Number} day
     * @returns {DateTime}
     */
    changeDay(day) {
        return this.createDay(undefined, undefined, day);
    }
    
    /**
     * Creates a new DateTime as the given amount of hours ahead of the saved date
     * @param {Number}  amount
     * @param {Number=} minutes
     * @returns {DateTime}
     */
    moveHours(amount, minutes) {
        return this.createDay(undefined, undefined, undefined, this.getHours() + amount, minutes);
    }
    
    /**
     * Creates a new DateTime with the given hours and minutes and the saved date
     * @param {Number}  hours
     * @param {Number=} minutes
     * @returns {DateTime}
     */
    changeHours(hours, minutes) {
        return this.createDay(undefined, undefined, undefined, hours, minutes);
    }
    
    /**
     * Creates a new DateTime as the given amount of minutes ahead of the saved date
     * @param {Number} minutes
     * @returns {DateTime}
     */
    moveMins(minutes) {
        return this.createDay(undefined, undefined, undefined, undefined, this.getMinutes() + minutes);
    }
    
    /**
     * Creates a new DateTime with the given minutes and the saved date
     * @param {Number} minutes
     * @returns {DateTime}
     */
    changeMins(minutes) {
        return this.createDay(undefined, undefined, undefined, undefined, minutes);
    }
    
    /**
     * Creates a new DateTime with the given minutes ahead of the saved date
     * @param {Number} time
     * @returns {DateTime}
     */
    addTime(time) {
        return new DateTime(this.toTime() + time);
    }
    
    
    
    /**
     * Returns the minutes of the saved date
     * @returns {Number}
     */
    getMinutes() {
        return this.date.getMinutes();
    }
    
    /**
     * Returns the minute difference between the saved day and the given one
     * @param {DateTime=} day
     * @returns {Number}
     */
    getMinutesDiff(day) {
        const time = day ? day.toTime() : new DateTime().toTime();
        return Math.ceil(Math.abs(this.toTime() - time) / 60);
    }

    /**
     * Returns the second difference between the saved day and the given one
     * @param {DateTime=} day
     * @returns {Number}
     */
    getSecondsDiff(day) {
        const time = day ? day.toTime() : new DateTime().toTime();
        return Math.abs(this.toTime() - time);
    }

    /**
     * Returns the hours of the saved date
     * @returns {Number}
     */
    getHours() {
        return this.date.getHours();
    }
    
    /**
     * Returns the hour difference between the saved day and the given one
     * @param {DateTime=} day
     * @returns {Number}
     */
    getHoursDiff(day) {
        const time = day ? day.toTime() : new DateTime().toTime();
        return Math.floor(Math.abs(this.toTime() - time) / 3600);
    }

    /**
     * Returns the hours and minutes number of the saved date as hours
     * @returns {Number}
     */
    asHours() {
        return this.getHours() + this.getMinutes() / 60;
    }

    
    
    /**
     * Returns the day number of the saved date
     * @returns {Number}
     */
    getDay() {
        return this.date.getDate();
    }
    
    /**
     * Returns the week day number of the saved date
     * @returns {Number}
     */
    getWeekDay() {
        return this.date.getDay();
    }
    
    
    
    /**
     * Returns the name of the day of the saved date
     * @param {Number} amount
     * @returns {String}
     */
    getDayName(amount) {
        const day  = this.getWeekDay();
        const days = NLS.get("DATE_DAY_NAMES");
        const name = days[day] || "";
        
        return amount ? name.substr(0, amount) : name;
    }
    
    /**
     * Returns the day difference between the saved day and the given one
     * @param {DateTime} day
     * @returns {Number}
     */
    getDaysDiff(day) {
        return Math.floor(Math.abs(this.toTime() - day.toTime()) / (24 * 3600));
    }

    
    
    /**
     * Returns the number of the weeks starting with the last years week
     * @param {Number=} weekDay
     * @returns {Number}
     */
    getWeek(weekDay = 0) {
        const actualDay = this.getWeekStart(weekDay);
        const startDay  = actualDay.createDay(undefined, 0, 1);

        return Math.ceil(((actualDay.toTime() - startDay.toTime()) / 86400) / 7);
    }
    
    /**
     * Returns the date with the first day of the week
     * @param {Number=} weekDay
     * @returns {DateTime}
     */
    getWeekStart(weekDay = 0) {
        const day = this.getDay() - this.getWeekDay() + weekDay;
        return this.createDay(undefined, undefined, day);
    }
    
    /**
     * Returns the amount of weeks in a month
     * @param {Number=} weekDay
     * @returns {Number}
     */
    getWeeksAmount(weekDay = 0) {
        const startDay  = this.createDay(undefined, undefined, 1);
        const endDay    = this.createDay(undefined, undefined, this.getMonthDays());
        const startWeek = startDay.getWeek(weekDay);
        const endWeek   = endDay.getWeek(weekDay);

        return endWeek - (startWeek > endWeek ? 0 : startWeek) + 1;
    }
    
    
    
    /**
     * Returns the month number of the saved date starting on 1
     * @returns {Number}
     */
    getMonth() {
        return this.date.getMonth();
    }
    
    /**
     * Returns the name of the month of the saved date
     * @param {Number} amount
     * @returns {String}
     */
    getMonthName(amount) {
        const name = monthToName(this.getMonth());
        return amount ? name.substr(0, amount) : name;
    }
    
    /**
     * Returns the month difference between the saved and the given dates
     * @param {Date} date
     * @returns {Number}
     */
    getMonthDiff(date) {
        return 12 * (this.getYear() - date.getYear()) + this.getMonth() - date.getMonth();
    }
    
    /**
     * Returns the first day of the month of the saved date
     * @returns {Number}
     */
    getMonthStart() {
        return new Date(this.getYear(), this.getMonth(), 1, this.getHours(), this.getMinutes());
    }
    
    /**
     * Returns the amount of Days in the month of the saved date
     * @returns {Number}
     */
    getMonthDays() {
        return DateTime.getMonthDays(this.getMonth(), this.getYear());
    }
    
    
    
    /**
     * Returns the full year of the saved date
     * @returns {Number}
     */
    getYear() {
        return this.date.getFullYear();
    }
    
    /**
     * Returns the amount of years between the saved date and the given date (or today) AKA the age
     * @param {DateTime} dateTime
     * @returns {Number}
     */
    getAge(dateTime) {
        const day = dateTime || new DateTime();
        return day.getYear() - this.getYear() - (this.changeYear(day.getYear()).isGreaterThan(day) ? 1 : 0);
    }
    
    
    
    /**
     * Returns a data structure with the required information to create a week calendar interface
     * @param {Number}  hourStart - Starting hour of the day
     * @param {Object=} events    - The events
     * @returns {{title: String, names: Array.<String>, hours: Array.<Object>}}
     */
    getDayData(hourStart, events) {
        const result = { title : this.toDayString(), names : [], hours : [] };
        const day    = this.copy();
        
        this.getDaysData(result, day, hourStart, 2, events);
        return result;
    }
    
    /**
     * Returns a data structure with the required information to create a week calendar interface
     * @param {Number}  weekDay   - Starting day of the week
     * @param {Number}  hourStart - Starting hour of the day
     * @param {Object=} events    - The events
     * @returns {{title: String, names: Array.<String>, hours: Array.<Object>}}
     */
    getWeekData(weekDay, hourStart, events) {
        const result = { title : this.toWeekString(), names : [], hours : [] };
        const day    = this.toWeekStart(weekDay);
        
        this.getDaysData(result, day, hourStart, 7, events);
        return result;
    }
    
    /**
     * Parses the names and hours structure for the days and week calendar interfaces
     * @param {Object}   result
     * @param {DateTime} dateTime
     * @param {Number}   hourStart
     * @param {Number}   dayAmount
     * @param {Object=}  events
     * @returns {Object}
     */
    getDaysData(result, dateTime, hourStart, dayAmount, events) {
        let day = dateTime;

        // First all the day names
        for (let i = 0; i < dayAmount; i += 1) {
            result.names.push({
                number : parseTime(day.getDay()),
                name   : day.getDayName(3),
            });
            day = day.moveDay(1);
        }
        
        // Then all the hours for each day
        for (let j = hourStart; j < 24; j += 1) {
            result.hours.push({ hour : j, days : [] });
            
            day = day.moveDay(-dayAmount);
            for (let i = 0; i < dayAmount; i += 1) {
                result.hours[j - hourStart].days.push({
                    isToday   : day.isToday(),
                    isWeekend : day.isWeekend(),
                    events    : events ? events[day.toDayStart(j).toTime()] || [] : [],
                });
                day = day.moveDay(1);
            }
        }
        return result;
    }
    
    /**
     * Returns a data structure with the required information to create a month calendar interface
     * @param {Number}    weekDay    - Starting day of the week
     * @param {Boolean}   fullWeeks  - True to always show 6 weeks, false to show the amount of weeks of the month
     * @param {Number=}   dayLetters - Amount of letters to use for the week days names
     * @param {DateTime=} currentDay - The selected day
     * @param {Object=}   events     - The events
     * @returns {{title: String, names: Array.<String>, weeks: Array.<Obeject>}}
     */
    getMonthData(weekDay, fullWeeks, dayLetters, currentDay, events) {
        const result  = { title : this.toString("monthYear"), names : [], weeks : [] };
        const lastDay = fullWeeks ? 6 * 7 : this.getWeeksAmount() * 7;
        let   day     = this.toMonthStart().toWeekStart(weekDay);
        let   month   = day.getMonth() - 1;
        let week      = -1;

        const addToWeek = (data) => {
            if (week < 0 || result.weeks[week].days.length % 7 === 0) {
                week += 1;
                result.weeks[week] = { time : data.time, days : [] };
            }
            result.weeks[week].days.push(data);
        };
        
        // First all the day names
        for (let i = 0; i < 7; i += 1) {
            result.names.push(day.getDayName(1));
            day = day.moveDay(1);
        }
        
        // Then all the days in the weeks
        day = day.moveDay(-7);
        for (let i = 0; i < lastDay; i += 1) {
            const isDay = this.isEqualMonthTo(day);
            let   name  = day.getDay();
            
            if (month !== day.getMonth()) {
                name  = day.toString("dayMonth");
                month = day.getMonth();
            }
            
            addToWeek({
                isDay     : isDay,
                day       : day,
                name      : name,
                time      : day.toTime(),
                isToday   : isDay && day.isToday(),
                isWeekend : isDay && day.isWeekend(),
                isCurrent : isDay && currentDay && currentDay.isEqualDayTo(day),
                events    : events ? events[day.toDayStart().toTime()] || [] : [],
            });
            day = day.moveDay(1);
        }
        
        return result;
    }
    
    
    
    
    /**
     * Creates a new DateTime from a slash separated string (YYYY/MM/DD or DD/MM/YYYY or /MM/DD)
     * @param {String} string
     * @param {String} separator
     * @returns {DateTime}
     */
    static fromString(string, separator = "-") {
        const parts = string.split(separator);
        let date  = new Date();
        
        if (parts.length === 3) {
            if (parts[0].length === 0) {
                date = new Date(date.getFullYear(), parseInt(parts[1], 10) - 1, parts[2]);
            } else if (parts[0].length === 4) {
                date = new Date(parts[0], parseInt(parts[1], 10) - 1, parts[2]);
            } else {
                date = new Date(parts[2], parseInt(parts[1], 10) - 1, parts[0]);
            }
        }
        return new DateTime(date);
    }
    
    /**
     * Formats the give date
     * @param {(Number|Date)} date
     * @param {String}        format
     * @returns {String}
     */
    static formatDate(date, format) {
        return new DateTime(date).toString(format);
    }

    /**
     * Formats the give date as a String
     * @param {(Number|Date)} date
     * @returns {String}
     */
    static formatString(date) {
        return new DateTime(date).toTimeString();
    }

    /**
     * Formats the give date as a Day
     * @param {(Number|Date)} date
     * @returns {String}
     */
    static formatDay(date) {
        return new DateTime(date).toDayString();
    }
    
    /**
     * Returns the amount of Days in the month of the saved date
     * @param {Number} month
     * @param {Number} year
     * @returns {Number}
     */
    static getMonthDays(month, year) {
        const days   = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
        let result = days[month] || null;
        
        if (month === 1 && (((year % 4) === 0 && (year % 100) !== 0) || (year % 400) === 0)) {
            result = 29;
        }
        return result;
    }
    
    
    
    
    /**
     * Returns a list of days for templates
     * @param {Number=} dayInit
     * @returns {Array.<{id: String, name: String, selected: Boolean}>}
     */
    static createDaySelect(dayInit) {
        const day    = dayInit || new Date().getDate();
        const result = [];

        for (let i = 1; i <= 31; i += 1) {
            result.push({
                id       : i,
                name     : i,
                selected : i === day,
            });
        }
        return result;
    }
    
    /**
     * Returns a list of month names for templates
     * @param {Number=} monthInit
     * @returns {Array.<{id: String, name: String, selected: Boolean}>}
     */
    static createMonthSelect(monthInit) {
        const month  = monthInit || new Date().getMonth() + 1;
        const result = [];
        
        for (let i = 0; i < 12; i += 1) {
            result.push({
                id       : i + 1,
                name     : monthToName(i),
                selected : i === month,
            });
        }
        return result;
    }
    
    /**
     * Returns a list of the last 100 years for templates
     * @param {Number=} yearInit
     * @param {Number=} yearDiff
     * @returns {Array.<{id: String, name: String, selected: Boolean}>}
     */
    static createYearSelect(yearInit, yearDiff) {
        const thisYear  = new Date().getFullYear();
        const startYear = yearDiff !== undefined ? thisYear - yearDiff        : thisYear - 5;
        const endYear   = yearDiff !== undefined ? thisYear + (10 - yearDiff) : thisYear + 5;
        const year      = yearInit || thisYear;
        const result    = [];

        for (let i = startYear; i <= endYear; i += 1) {
            result.push({
                id         : i,
                name       : i,
                isSelected : i === year,
            });
        }
        return result;
    }
    
    /**
     * Returns a list of years to be used in year selects with an all option
     * @param {Number=} year
     * @param {Number=} yearDiff
     * @returns {Array.<Object>}
     */
    static createYearSelectWithAll(year, yearDiff) {
        const result = DateTime.createYearSelect(year, yearDiff);
        result.unshift({ id : "all", name : NLS.get("GENERAL_ALL"), isSelected : year === "all" });
        return result;
    }
    
    /**
     * Returns a list of days, months and years to be used in date selects
     * @param {Number=} day
     * @param {Number=} month
     * @param {Number=} year
     * @param {Number=} yearDiff
     * @returns {{days: Array.<Object>, months: Array.<Object>, years: Array.<Object>}}
     */
    static createDateSelects(day, month, year, yearDiff) {
        return {
            days   : this.createDaySelect(day),
            months : this.createMonthSelect(month),
            years  : this.createYearSelect(year, yearDiff),
        };
    }
    
    /**
     * Returns a list of months and years to be used in date selects
     * @param {Number=} month
     * @param {Number=} year
     * @param {Number=} yearDiff
     * @returns {{months: Array.<Object>, years: Array.<Object>}}
     */
    static createMYSelects(month, year, yearDiff) {
        return {
            months : this.createMonthSelect(month),
            years  : this.createYearSelect(year, yearDiff),
        };
    }
}
    
    
    
    
export default DateTime;
