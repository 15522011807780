import React                from "react";
import PropTypes            from "prop-types";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Product/List/Breadcrumb.css";



/**
 * The Breadcrumb
 */
class Breadcrumb extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data } = this.props;

        return <ul className="no-list breadcrumb">
            {data.map((elem) => <li key={elem.to}>
                <HyperLink variant="opacity" href={elem.to} message={elem.name} />
                <Icon className="breadcrumb-chevron" variant="right" />
            </li>)}
        </ul>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data : PropTypes.array.isRequired,
    }
}

export default Breadcrumb;
