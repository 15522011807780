import React                from "react";
import PropTypes            from "prop-types";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";

// Styles
import "Styles/Components/Product/List/ProductsContent.css";



/**
 * The Products Content
 */
class ProductsContent extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { getUrl, onChange, hasFilters, setFilter, openFilter, loading, children } = this.props;
        const childrenWithProps = React.Children.map(children, (child) => (
            React.cloneElement(child, {
                getUrl, onChange, hasFilters, setFilter, openFilter, loading,
            })
        ));

        return <Wrapper className="products-wrapper">
            {childrenWithProps}
        </Wrapper>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        getUrl     : PropTypes.func,
        onChange   : PropTypes.func,
        hasFilters : PropTypes.bool,
        setFilter  : PropTypes.func,
        openFilter : PropTypes.bool,
        loading    : PropTypes.bool,
        children   : PropTypes.any,
    }
}

export default ProductsContent;
