import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";
import { toggleNav }        from "Actions/Store/StoreActions";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";
import MenuItems            from "Utils/App/MenuItems";
import ClassList            from "Utils/Common/ClassList";
import Utils                from "Utils/Common/Utils";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Core/Navigation.css";



/**
 * The Navigation
 */
class Navigation extends React.Component {
    // The Current State
    state = {
        menu : "",
    }

    /**
     * Handles the Navigation Closing
     * @returns {Void}
     */
    handleClose = () => {
        this.props.toggleNav(false);
    }

    /**
     * Handles the Click
     * @param {String} menu
     * @param {Object} submenu
     * @returns {Function}
     */
    handleClick = (menu, submenu) => () => {
        if (!submenu) {
            this.handleClose();
        } else if (this.state.menu === menu) {
            this.setState({ menu : "" });
        } else {
            this.setState({ menu });
        }
    }



    /**
     * Does the Nav Render
     * @returns {Object}
     */
    renderNavItems() {
        const { settings, menus, categories, menuData } = this.props;
        const items  = MenuItems.getNavigation(menus, settings, menuData);
        const result = [];

        for (const { key, icon, href, target, message, submenu, submenuID } of items) {
            const item = <HyperLink
                variant="opacity"
                href={href}
                target={target}
                message={message}
                icon={icon}
                onClick={this.handleClose}
            />;

            let subitem = null;
            if (submenu === "category") {
                subitem = <ul className="no-list">
                    {categories.map(({ id, name, url }) => <li key={id}>
                        <HyperLink
                            variant="opacity"
                            href={`${Url.PRODUCTS}/${url}`}
                            message={name}
                            onClick={this.handleClose}
                        />
                    </li>)}
                </ul>;
            } else if (submenu === "subcategory" && submenuID) {
                const category = Utils.getData(categories, "id", submenuID);
                if (category) {
                    subitem = <ul className="no-list">
                        {category.childs.map(({ id, name, url }) => <li key={id}>
                            <HyperLink
                                variant="opacity"
                                href={`${Url.PRODUCTS}/${category.url}/${url}`}
                                message={name}
                                onClick={this.handleClose}
                            />
                        </li>)}
                    </ul>;
                }
            }

            result.push(<li key={key}>{item}{subitem}</li>);
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { menu } = this.state;
        const {
            navOpen, settings, isAuthenticated, credential,
            menuData, chat, notification, logout,
        } = this.props;
        
        if (!navOpen) {
            return <React.Fragment />;
        }
        const showChat    = isAuthenticated && settings.hasChat;
        const clientItems = MenuItems.getClient(settings);

        const classes = new ClassList("navigation-container");
        classes.addIf("navigation-right", !settings.menuLeft);
        classes.addIf("navigation-left",  settings.menuLeft);

        return <section className={classes.get()}>
            <header className="navigation-header">
                <Icon variant="client" />
                {isAuthenticated ? <div>
                    <HyperLink
                        className="navigation-title"
                        variant="black"
                        href={Url.ACCOUNT}
                        message={NLS.format("HEADER_WELCOME_CLIENT", credential.userName)}
                        onClick={this.handleClose}
                    />
                    <HyperLink
                        className="navigation-notifications"
                        variant="black"
                        href={Url.NOTIFICATIONS}
                        message={NLS.pluralize("HEADER_NOTIFICATIONS", notification.length)}
                        onClick={this.handleClose}
                    />
                </div> : <div>
                    <h2 className="navigation-title">{NLS.get("HEADER_WELCOME_TITLE")}</h2>
                    {settings.allowLogin && <h3 className="navigation-subtitle">{NLS.get("HEADER_WELCOME_LOGIN")}</h3>}
                    {settings.allowLogin && <div className="navigation-login">
                        <HyperLink
                            variant="black"
                            href={Url.LOGIN}
                            message="AUTH_LOGIN_ACTION"
                            onClick={this.handleClose}
                        />
                        {settings.allowRegister && <HyperLink
                            variant="balck"
                            href={Url.REGISTER}
                            message="AUTH_REGISTER_LINK"
                            onClick={this.handleClose}
                        />}
                    </div>}
                </div>}
            </header>

            <div className="navigation-content">
                <ul className="navigation-list no-list">
                    {this.renderNavItems()}
                </ul>
                
                <ul className="navigation-list no-list">
                    {settings.headerWhatsapp && <li>
                        <HyperLink
                            variant="opacity"
                            message={NLS.format("HEADER_WHATSAPP_NUMBER", settings.whatsapp)}
                            href={settings.whatsapp}
                            icon="whatsapp"
                            whatsapp
                        />
                    </li>}
                    {settings.headerPhone && <li>
                        <HyperLink
                            variant="opacity"
                            message={NLS.format("HEADER_PHONE_NUMBER", settings.phone)}
                            href={settings.phone}
                            icon="phone"
                            tel
                        />
                    </li>}
                    {showChat && <li>
                        <HyperLink
                            variant="opacity"
                            href={Url.CHAT}
                            message={NLS.format("CHAT_TITLE_FULL", chat.unreadClient)}
                            icon="chat"
                            onClick={this.handleClose}
                        />
                    </li>}
                    {settings.allowCart && <li>
                        <HyperLink
                            variant="opacity"
                            href={Url.CART}
                            message={NLS.format("CART_TITLE_FULL", menuData.cart)}
                            icon="cart"
                            onClick={this.handleClose}
                        />
                    </li>}
                </ul>

                {isAuthenticated && <ul className="navigation-list no-list">
                    {clientItems.map(({ type, icon, href, message, submenu }) => <li key={type}>
                        <HyperLink
                            variant="opacity"
                            href={!submenu ? href : ""}
                            message={message}
                            icon={icon}
                            afterIcon={submenu ? (type === menu ? "down" : "right") : ""}
                            onClick={this.handleClick(type, submenu)}
                        />
                        {type === menu && <ul className="no-list">
                            {submenu.map(({ type, href, message }) => <li key={type}>
                                <HyperLink
                                    variant="opacity"
                                    href={href}
                                    message={message}
                                    onClick={this.handleClose}
                                />
                            </li>)}
                        </ul>}
                    </li>)}
                    {settings.hasProductPurchases && <li>
                        <HyperLink
                            variant="opacity"
                            icon="purchases"
                            href={Url.PURCHASES}
                            message="PURCHASES_TITLE"
                            onClick={this.handleClose}
                        />
                    </li>}
                    {settings.hasProductFavorites && <li>
                        <HyperLink
                            variant="opacity"
                            icon="favorite"
                            href={Url.FAVORITES}
                            message="FAVORITES_TITLE"
                            onClick={this.handleClose}
                        />
                    </li>}
                    {settings.hasProductHistory && <li>
                        <HyperLink
                            variant="opacity"
                            icon="history"
                            href={Url.HISTORY}
                            message="HISTORY_TITLE"
                            onClick={this.handleClose}
                        />
                    </li>}
                    <li>
                        <HyperLink
                            variant="opacity"
                            message="CLIENT_LOGOUT"
                            icon="logout"
                            onClick={logout}
                        />
                    </li>
                </ul>}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout          : PropTypes.func.isRequired,
        toggleNav       : PropTypes.func.isRequired,
        navOpen         : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        credential      : PropTypes.object.isRequired,
        menus           : PropTypes.object.isRequired,
        categories      : PropTypes.array.isRequired,
        menuData        : PropTypes.object.isRequired,
        chat            : PropTypes.object.isRequired,
        notification    : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            credential      : state.auth.credential,
            navOpen         : state.store.navOpen,
            menus           : state.store.menus,
            menuData        : state.store.menuData,
            categories      : state.store.categories,
            chat            : state.chat.elem,
            notification    : state.notification.list,
        };
    }
}

export default connect(Navigation.mapStateToProps, {
    logout, toggleNav,
})(Navigation);
