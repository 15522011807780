import API                  from "Utils/App/API";

// Types
import {
    STORE_DATA, STORE_UPDATES, STORE_LAST_UPDATE,
    STORE_PREFERENCE, STORE_FAVORITE, STORE_HISTORY,
    STORE_MENU, STORE_NAVIGATION, STORE_SEARCH,
} from "Utils/Types";



/**
 * Fetches the App Data
 * @param {String} orderHash
 * @returns {Function}
 */
export function fetchData(orderHash) {
    return async (dispatch) => {
        const data = await API.Store.getData({ orderHash });
        dispatch({ type : STORE_DATA, data });
    };
}

/**
 * Fetches the Updates
 * @param {Number} time
 * @returns {Function}
 */
export function fetchUpdates(time) {
    return async (dispatch) => {
        const data = await API.Store.getUpdates({ time });
        dispatch({ type : STORE_UPDATES, data });
        dispatch({ type : STORE_LAST_UPDATE });
        return data;
    };
}



/**
 * Sets the Sort Preference
 * @param {Number}  sort
 * @param {Boolean} save
 * @returns {Function}
 */
export function setSort(sort, save) {
    return async (dispatch) => {
        const data = { key : "sort", value : sort };
        if (save) {
            await API.Store.setPreference(data);
        }
        dispatch({ type : STORE_PREFERENCE, data });
    };
}

/**
 * Sets the Columns Preference
 * @param {Number}  columns
 * @param {Boolean} save
 * @returns {Function}
 */
export function setColumns(columns, save) {
    return async (dispatch) => {
        const data = { key : "columns", value : columns };
        if (save) {
            await API.Store.setPreference(data);
        }
        dispatch({ type : STORE_PREFERENCE, data });
    };
}

/**
 * Sets the Price Preference
 * @param {Number}  price
 * @param {Boolean} save
 * @returns {Function}
 */
export function setPrice(price, save) {
    return async (dispatch) => {
        const data = { key : "price", value : price };
        if (save) {
            await API.Store.setPreference(data);
        }
        dispatch({ type : STORE_PREFERENCE, data });
    };
}

/**
 * Sets the Amount Preference
 * @param {Number} amount
 * @returns {Function}
 */
export function setAmount(amount) {
    return async (dispatch) => {
        const data = { key : "amount", value : amount };
        dispatch({ type : STORE_PREFERENCE, data });
    };
}




/**
 * Toggles the Product Favorite
 * @param {String} productID
 * @returns {Function}
 */
export function toggleFavorite(productID) {
    return async (dispatch) => {
        const data = await API.Store.toggleFavorite({ productID });
        dispatch({ type : STORE_FAVORITE, data });
        return data.success;
    };
}

/**
 * Adds a Product to the History
 * @param {String} productID
 * @returns {Function}
 */
export function addToHistory(productID) {
    return async (dispatch) => {
        const data = await API.Store.addToHistory({ productID });
        dispatch({ type : STORE_HISTORY, data });
        return data.success;
    };
}



/**
 * Toggles a Menu
 * @param {Boolean} open
 * @returns {Function}
 */
export function toggleMenu(open) {
    return (dispatch) => dispatch({ type : STORE_MENU, open });
}

/**
 * Toggles the Navigation
 * @param {Boolean} open
 * @returns {Function}
 */
export function toggleNav(open) {
    return (dispatch) => dispatch({ type : STORE_NAVIGATION, open });
}

/**
 * Toggles the Search
 * @param {Boolean} open
 * @returns {Function}
 */
export function toggleSearch(open) {
    return (dispatch) => dispatch({ type : STORE_SEARCH, open });
}
