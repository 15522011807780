import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";
import Utils                from "Utils/Common/Utils";
import ClassList            from "Utils/Common/ClassList";

// Components
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Common/Dialog.css";



/**
 * The Dialog
 */
class Dialog extends React.Component {
    // The Current State
    state = {
        closing : false,
    }

    // References
    dialogRef = React.createRef();



    /**
     * Close / Open the Dialog
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            if (prevProps.open === true) {
                document.body.classList.remove("dialog-noscroll");
            } else {
                document.body.classList.add("dialog-noscroll");
            }
        }
    }

    /**
     * Close the Dialog
     * @returns {Void}
     */
    componentWillUnmount() {
        document.body.classList.remove("dialog-noscroll");
    }

    /**
     * Handles the Dialog Backdrop Close
     * @param {Event} e
     * @returns {Void}
     */
    handleBackdrop = (e) => {
        const node = Utils.getNode(this.dialogRef);
        if (node) {
            const bounds = node.getBoundingClientRect();
            if (e.clientX < bounds.left || e.clientX > bounds.right ||
                e.clientY < bounds.top  || e.clientY > bounds.bottom) {
                this.closeDialog();
            }
        }
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        if (this.state.closing) {
            return;
        }
        this.setState({ closing : true });
        window.setTimeout(() => {
            this.setState({ closing : false });
            this.props.onClose();
        }, 200);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open,isWide, hasFooter, className, message, icon, onClose, children } = this.props;
        const { closing } = this.state;

        const hasTitle = !!message;
        const classes  = new ClassList("dialog-backdrop");
        classes.addIf("dialog-open",        open);
        classes.addIf("dialog-closing",     closing);
        classes.addIf("dialog-wide",        isWide);
        classes.addIf("dialog-with-header", hasTitle);
        classes.addIf("dialog-with-footer", hasFooter);

        const childrenWithProps = React.Children.map(children, (child) => (
            React.cloneElement(child, { hasTitle, onClose })
        ));

        return <div className={classes.get()} onMouseDown={this.handleBackdrop}>
            <Card className={`dialog-container ${className}`} ref={this.dialogRef}>
                {hasTitle && <header className="dialog-header">
                    <div className="dialog-header-content">
                        {!!icon && <Icon className="dialog-icon" variant={icon} />}
                        <h2>{NLS.get(message)}</h2>
                    </div>
                    <HyperLink
                        className="dialog-close"
                        variant="icon"
                        icon="close"
                        onClick={onClose}
                    />
                </header>}
                {childrenWithProps}
            </Card>
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open      : PropTypes.bool,
        isWide    : PropTypes.bool,
        hasFooter : PropTypes.bool,
        className : PropTypes.string,
        message   : PropTypes.string,
        icon      : PropTypes.string,
        onClose   : PropTypes.func.isRequired,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        open      : false,
        isWide    : false,
        hasFooter : false,
        className : "",
        message   : "",
        icon      : "",
    }
}

export default Dialog;
