import API                  from "Utils/App/API";
import Auth                 from "Utils/App/Auth";

// Types
import {
    AUTH_CURRENT_USER,
    AUTH_DATA, AUTH_FOCUSED,
    AUTH_RESET, AUTH_REGISTER, AUTH_LOGOUT,
} from "Utils/Types";



/**
 * Sets the Current Credential
 * @param {Object} credential
 * @returns {{type: String, user: Object}}
 */
export function setCurrentUser(credential) {
    return { type : AUTH_CURRENT_USER, credential };
}

/**
 * Fetches the Auth Data
 * @returns {Function}
 */
export function fetchData() {
    return async (dispatch) => {
        const data = await API.Auth.getData();
        dispatch({ type : AUTH_DATA, data });
    };
}

/**
 * Sets the Focused State
 * @param {Object} isFocused
 * @returns {Function}
 */
export function setFocused(isFocused) {
    return async (dispatch) => {
        dispatch({ type : AUTH_FOCUSED, isFocused });
    };
}



/**
 * Logs In the Client
 * @param {Object} params
 * @returns {Function}
 */
export function login(params) {
    return async () => API.Auth.login(params);
}

/**
 * Recovers the Client's Password
 * @param {Object} params
 * @returns {Function}
 */
export function recover(params) {
    return async () => {
        const data = await API.Auth.recover(params);
        return data.success;
    };
}

/**
 * Resets the Client's Password
 * @param {Object} params
 * @returns {Function}
 */
export function reset(params) {
    return async (dispatch) => {
        const data = await API.Auth.reset(params);
        if (data.success) {
            dispatch({ type : AUTH_RESET, data });
        }
        return data.success;
    };
}

/**
 * Registers a new Client
 * @param {Object} params
 * @returns {Function}
 */
export function register(params) {
    return async (dispatch) => {
        const data = await API.Auth.register(params);
        if (data.success) {
            dispatch({ type : AUTH_REGISTER, data });
        }
        return data.success;
    };
}

/**
 * Logouts the User
 * @returns {Function}
 */
export function logout() {
    return (dispatch) => {
        dispatch({ type : AUTH_LOGOUT });
        API.abort();
        API.Auth.logout();
        Auth.unsetToken();
    };
}
