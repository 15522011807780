import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchBrands }      from "Actions/Store/BrandActions";
import Url                  from "Utils/App/Url";

// Styles
import Title                from "Components/Utils/Common/Title";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Content              from "Components/Utils/Common/Content";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Store/Brands.css";



/**
 * The Brand List
 */
class BrandList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchBrands();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, list } = this.props;

        return <>
            <Title message="BRANDS_TITLE" />
            <Wrapper className="brands-content" withSpacing>
                <Content
                    none="BRANDS_NONE_AVAILABLE"
                    loading={loading}
                    hasContent={!!list.length}
                    withCard
                    withSpacing
                    withBorder
                >
                    <ul className="brands-grid no-list">
                        {list.map((elem) => <li key={elem.id}>
                            <HyperLink variant="none" href={`${Url.BRANDS}/${elem.url}`}>
                                {elem.image
                                    ? <img src={elem.image} alt={elem.name} />
                                    : <h4>{elem.name}</h4>
                                }
                            </HyperLink>
                        </li>)}
                    </ul>
                </Content>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchBrands : PropTypes.func.isRequired,
        loading     : PropTypes.bool.isRequired,
        list        : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading : state.brand.loading,
            list    : state.brand.list,
        };
    }
}

export default connect(BrandList.mapStateToProps, {
    fetchBrands,
})(BrandList);
