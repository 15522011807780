import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Products Category Filter
 */
class ProductsCategory extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, selected, getUrl, onChange, isSub, isTer } = this.props;

        const type  = isTer ? "tercategory" : (isSub ? "subcategory" : "category");
        const title = isTer ? "TERCATEGORIES_SINGULAR" : (isSub ? "SUBCATEGORIES_SINGULAR" : "CATEGORIES_SINGULAR");
        const total = data.reduce((res, elem) => res + elem.total, 0);

        return <div className="aside-options">
            <h4>{NLS.get(title)}</h4>
            <ul className="no-list">
                <li>
                    <HyperLink
                        variant="opacity"
                        href={getUrl(type)}
                        onClick={onChange}
                        icon={!selected ? "check" : "blank"}
                    >
                        {NLS.get("CATEGORIES_ALL")}
                        <span className="aside-amount">{total}</span>
                    </HyperLink>
                </li>
                {data.map((elem) => <li key={elem.id}>
                    <HyperLink
                        variant="opacity"
                        href={getUrl(type, elem.slug)}
                        onClick={onChange}
                        icon={selected === elem.slug ? "check" : "blank"}
                    >
                        {elem.name}
                        <span className="aside-amount">{elem.total}</span>
                    </HyperLink>
                </li>)}
            </ul>
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data     : PropTypes.array.isRequired,
        selected : PropTypes.string.isRequired,
        getUrl   : PropTypes.func.isRequired,
        onChange : PropTypes.func.isRequired,
        isSub    : PropTypes.bool,
        isTer    : PropTypes.bool,
    }
}

export default ProductsCategory;
