import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCreditNotes } from "Actions/Client/VoucherActions";
import { setPage }          from "Actions/Client/ClientActions";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import SubTitle             from "Components/Utils/Common/SubTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";



/**
 * The Credit Note Page
 */
class CreditNotePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchCreditNotes(params);
        this.props.setPage(params.page);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);
        
        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchCreditNotes(newParams);
            this.props.setPage(newParams.page);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return { page : Number(params.page) || 1 };
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, loading, list, total } = this.props;
        const { page                           } = this.getParams();

        return <>
            <SubTitle show={!isMobile} message="VOUCHERS_CREDIT_NOTES" icon="voucher" />
            <Table
                none="VOUCHERS_NONE_CREDIT_NOTES"
                loading={loading}
                hasContent={!!list.length}
                hasActions
                hasSpacing
            >
                <TableHead>
                    <TableCell message="VOUCHERS_DESCRIPTION" />
                    <TableCell message="VOUCHERS_DATE"        />
                    <TableCell message="VOUCHERS_AMOUNT"      align="right" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.creditNoteID}>
                        <TableCell message={elem.name}            isBold />
                        <TableCell message={elem.voucherTimeDate} nowrap />
                        <TableCell message={`$ ${elem.amount}`}   align="right" nowrap />
                        <TableCell
                            href={`${process.env.REACT_APP_VOUCHER}notas-de-credito/${elem.hash}`}
                            target="_blank"
                            message="GENERAL_DOWNLOAD"
                            align="right"
                            isLink
                        />
                    </TableRow>)}
                </TableBody>
            </Table>
            <Pagination
                current={page}
                total={total}
                url={Url.CREDIT_NOTES}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCreditNotes : PropTypes.func.isRequired,
        setPage          : PropTypes.func.isRequired,
        match            : PropTypes.object.isRequired,
        isMobile         : PropTypes.bool.isRequired,
        loading          : PropTypes.bool.isRequired,
        list             : PropTypes.array.isRequired,
        total            : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile : state.core.isMobile,
            loading  : state.voucher.loading,
            list     : state.voucher.creditNotes.list,
            total    : state.voucher.creditNotes.total,
        };
    }
}

export default connect(CreditNotePage.mapStateToProps, {
    fetchCreditNotes, setPage,
})(CreditNotePage);
