import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Url                  from "Utils/App/Url";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/Floater.css";



/**
 * The Floater
 */
class Floater extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, chats, isAuthenticated                                    } = this.props;
        const { phone, whatsapp, floatPhone, floatWhatsapp, floatContact, floatChat } = settings;

        const showChat = isAuthenticated && floatChat;
        if (!floatPhone && !floatWhatsapp && !floatContact && !showChat) {
            return <React.Fragment />;
        }

        return <ul className="floater-container no-list">
            {floatWhatsapp && <li>
                <HyperLink
                    className="floater-whatsapp"
                    variant="none"
                    href={whatsapp}
                    icon="whatsapp"
                    whatsapp
                />
            </li>}
            {floatPhone && <li>
                <HyperLink
                    className="floater-phone"
                    variant="none"
                    href={phone}
                    icon="phone"
                    tel
                />
            </li>}
            {floatContact && <li>
                <HyperLink
                    className="floater-contact"
                    variant="none"
                    href={Url.CONTACT}
                    icon="email"
                />
            </li>}
            {showChat && <li>
                <HyperLink
                    className="floater-chat"
                    variant="none"
                    href={Url.CHAT}
                    icon="chat"
                    badge={chats}
                />
            </li>}
        </ul>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings        : PropTypes.object.isRequired,
        chats           : PropTypes.number.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            chats           : state.chat.elem.unreadClient,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(Floater.mapStateToProps, null)(Floater);
