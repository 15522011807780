import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Styles
import "Styles/Components/Utils/Common/Loader.css";



/**
 * The Loader
 */
class Loader extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, show } = this.props;

        return <div className={`loader loader-${variant} ${show ? "loader-show" : ""}`}>
            <div className="loader-ring">
                <div />
                <div />
                <div />
                <div />
            </div>
            <div className="loader-text">
                {NLS.get("GENERAL_LOADING")}
            </div>
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        variant : PropTypes.string.isRequired,
        show    : PropTypes.bool.isRequired,
    }
}

export default Loader;
