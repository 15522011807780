import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";

// Components
import QueryReply           from "./QueryReply";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Content              from "Components/Utils/Common/Content";
import Status               from "Components/Utils/Common/Status";
import MultiLine            from "Components/Utils/Common/MultiLine";
import Alert                from "Components/Utils/Form/Alert";

// Actions
import {
    fetchQuery, ackQuery,
} from "Actions/Client/QueryActions";

// Styles
import "Styles/Components/Client/Query.css";



/**
 * The Query Elem
 */
class QueryElem extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchQuery(this.props.match.params.query);
    }

    /**
     * Unloads the Data
     * @returns {Void}
     */
    componentWillUnmount() {
        this.props.ackQuery();
    }

    /**
     * Acknowledge a Query Create
     * @returns {Void}
     */
    closeAlert = () => {
        this.props.ackQuery();
    }

    

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, elem, created                                                } = this.props;
        const { queryID, subject, queryTypeID, queryTypeName, isOpen, unread, replies } = elem;
        const { queryID : newQueryID, success                                         } = created;

        const message     = loading ? "QUERIES_SINGULAR" : NLS.format("QUERIES_SINGULAR_TITLE", subject);
        const showSuccess = newQueryID !== 0 && newQueryID === queryID;

        return <>
            <SubTitle message={message} icon="query" />
            <Content loading={loading} hasContent withCard>
                <header className="query-header">
                    {!!queryTypeID && <div>
                        <b>{NLS.get("QUERIES_TYPE")}</b>
                        {queryTypeName}
                    </div>}
                    <div>
                        <b>{NLS.get("GENERAL_STATUS")}</b>
                        <Status
                            condition={!!isOpen}
                            success="QUERIES_STATUS_OPEN"
                            error="QUERIES_STATUS_CLOSE"
                        />
                    </div>
                    <div>
                        <b>{NLS.get("QUERIES_READING")}</b>
                        <Status
                            condition={!unread}
                            success="QUERIES_READ"
                            error="QUERIES_UNREAD"
                        />
                    </div>
                </header>
                <section className="query-content">
                    <Alert
                        open={showSuccess}
                        variant="success"
                        message={success}
                        onClose={this.closeAlert}
                    />

                    {replies.map((elem) => <section key={elem.queryReplyID} className="query-reply">
                        <header className="query-reply-header">
                            {elem.socialReason && <label className="query-reply-admin">
                                {elem.socialReason}
                            </label>}
                            {elem.credentialName && <label className="query-reply-client">
                                {elem.credentialName}
                            </label>}
                            <div>{NLS.format("QUERIES_PUBLISH", elem.createdDate)}</div>
                        </header>
                        <section className="query-reply-content">
                            <MultiLine>{elem.message}</MultiLine>
                            {elem.image && <img src={elem.imageUrl} alt={elem.image} />}
                        </section>
                    </section>)}
                </section>
                <QueryReply queryID={queryID} />
            </Content>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQuery : PropTypes.func.isRequired,
        ackQuery   : PropTypes.func.isRequired,
        loading    : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
        created    : PropTypes.object.isRequired,
        match      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading : state.query.loading,
            elem    : state.query.elem,
            created : state.query.created,
        };
    }
}

export default connect(QueryElem.mapStateToProps, {
    fetchQuery, ackQuery,
})(QueryElem);
