import Utils                from "Utils/Common/Utils";

// Types
import {
    HOME_LOADING, HOME_ELEM,
    STORE_FAVORITE, STORE_HISTORY,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    error       : false,
    productLink : "",
    chatLink    : "",
    slides      : [],
    lightnings  : [],
    promotions  : [],
    news        : [],
    categories  : [],
    newests     : [],
    importants  : [],
    purchases   : [],
    favorites   : [],
    history     : [],
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, HOME_ELEM)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case HOME_LOADING:
        return {
            ...state,
            loading     : true,
        };
    
    case HOME_ELEM:
        return {
            ...state,
            loading     : false,
            error       : false,
            productLink : action.data.productLink,
            chatLink    : action.data.chatLink,
            slides      : action.data.slides,
            lightnings  : action.data.lightnings,
            promotions  : action.data.promotions,
            news        : action.data.news,
            categories  : action.data.categories,
            newests     : action.data.newests,
            importants  : action.data.importants,
            purchases   : action.data.purchases,
            favorites   : action.data.favorites,
            history     : action.data.history,
        };
    
    // Update the Favorite
    case STORE_FAVORITE: {
        const productID  = action.data.productID;
        const categories = [ ...state.categories ];
        if (state.categories.length) {
            for (const [ index, list ] of categories.entries()) {
                categories[index] = Utils.setFavorite(list, productID);
            }
        }
        return {
            ...state,
            categories : categories,
            favorites  : action.data.list,
            newests    : Utils.setFavoriteList(state.newests,    productID),
            importants : Utils.setFavoriteList(state.importants, productID),
            purchases  : Utils.setFavoriteList(state.purchases,  productID),
            history    : Utils.setFavoriteList(state.history,    productID),
        };
    }

    // Update the History
    case STORE_HISTORY:
        return {
            ...state,
            history : action.data.list,
        };

    default:
        return state;
    }
};
