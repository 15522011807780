import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import Price                from "Components/Utils/Common/Price";

// Styles
import "Styles/Components/Product/Item/ProductPrice.css";



/**
 * The Product Price
 */
class ProductPrice extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, className, withPercent, withNew } = this.props;
        const {
            currencySymbol, priceFormat,
            hasDiscount, discountPrice, discountPercent,
            semaphoreClass, isNew,
        } = data;

        const hasNew       = withNew && isNew;
        const showDiscount = hasDiscount;
        const showNew      = !hasDiscount && hasNew;
        const showNothing  = !hasDiscount && !hasNew;
        const hasPercent   = !hasNew && withPercent && Boolean(Number(discountPercent));
        const hasSemaphore = Boolean(semaphoreClass);
        
        return <div className={`product-price ${className}`}>
            {showDiscount && <div className="product-price-cnt">
                <Price
                    className="product-price-old"
                    symbol={currencySymbol}
                    price={priceFormat}
                />
                <div className="product-price-new">
                    <Price
                        className="product-new-value"
                        symbol={currencySymbol}
                        price={discountPrice}
                    />
                    {hasNew && <div className="product-discount">
                        {NLS.get("PRODUCTS_IS_NEW")}
                    </div>}
                    {hasPercent && <div className="product-discount">
                        {NLS.format("PRODUCTS_DISCOUNT", discountPercent)}
                    </div>}
                </div>
            </div>}
            {showNew && <div className="product-price-new">
                <Price
                    className="product-new-value"
                    symbol={currencySymbol}
                    price={priceFormat}
                />
                <div className="product-discount">
                    {NLS.get("PRODUCTS_IS_NEW")}
                </div>
            </div>}
            {showNothing && <Price
                className="product-price-cnt"
                symbol={currencySymbol}
                price={priceFormat}
            />}
            {hasSemaphore && <div
                className={`product-semaphore product-semaphore-${semaphoreClass}`}
            />}
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        data        : PropTypes.object.isRequired,
        className   : PropTypes.string,
        withPercent : PropTypes.bool,
        withNew     : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withPercent : false,
        withNew     : false,
    }
}

export default ProductPrice;
