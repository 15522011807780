import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setProduct }       from "Actions/Store/ProductActions";
import NLS                  from "Utils/App/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import ProductCard          from "Components/Product/Item/ProductCard";
import Pagination           from "Components/Utils/Common/Pagination";

// Styles
import "Styles/Components/Product/List/ProductsList.css";



/**
 * The Products List
 */
class ProductsList extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            preferences, loading, products, page, pages, url,
            hasFilters, onFavorite, setProduct,
        } = this.props;

        // Show the Loader
        if (loading) {
            return <div className="nothing">
                <h3>{NLS.get("GENERAL_LOADING")}</h3>
            </div>;
        }

        // There is no content
        if (!products.length) {
            return <div className="nothing">
                <h3>{NLS.get("PRODUCTS_NONE_AVAILABLE")}</h3>
            </div>;
        }

        const columns = preferences.columns === 3 && !hasFilters ? 4 : preferences.columns;
        const classes = new ClassList("products-list");

        classes.addIf("products-list-filters", hasFilters);
        classes.addIf("products-list-wide",    !hasFilters);
        
        // Show the Content
        return <section className={classes.get()}>
            <div className={`products-grid products-grid-${columns}`}>
                {products.map((elem, index) => <ProductCard
                    key={index}
                    data={elem}
                    columns={preferences.columns}
                    onClick={() => setProduct(elem)}
                    onFavorite={onFavorite}
                />)}
            </div>
            <Pagination
                current={page}
                total={pages}
                url={url}
            />
        </section>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setProduct  : PropTypes.func.isRequired,
        preferences : PropTypes.object.isRequired,
        loading     : PropTypes.bool.isRequired,
        products    : PropTypes.array.isRequired,
        page        : PropTypes.number.isRequired,
        pages       : PropTypes.number,
        url         : PropTypes.string,
        hasFilters  : PropTypes.bool,
        onFavorite  : PropTypes.func,
        onClose     : PropTypes.func,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            preferences : state.store.preferences,
        };
    }
}

export default connect(ProductsList.mapStateToProps, {
    setProduct,
})(ProductsList);
