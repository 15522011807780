import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleFavorite }   from "Actions/Store/StoreActions";
import ClassList            from "Utils/Common/ClassList";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductInfo          from "Components/Product/Item/ProductInfo";
import ProductVariants      from "Components/Product/Item/ProductVariants";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Product/Item/ProductCard.css";



/**
 * The Product Card
 */
class ProductCard extends React.Component {
    /**
     * Handles the Favorite
     * @param {Event} e
     * @returns {Void}
     */
    handleFavorite = (e) => {
        const { data, toggleFavorite, onFavorite } = this.props;
        toggleFavorite(data.productID);
        if (onFavorite) {
            onFavorite(data.productID);
        }
        e.preventDefault();
    }



    /**
     * Does the Content Render
     * @returns {Object}
     */
    renderContent() {
        const { settings, data, columns                 } = this.props;
        const { productCode, hasVariants, isUnavailable } = data;

        const isWide          = columns === 1;
        const hasCode         = Boolean(settings.showCode && productCode);
        const hasBottomPrice  = settings.showPrice && !settings.showPriceTop && !isWide && !hasVariants;

        if (isWide) {
            return <>
                <ProductImage
                    className="product-card-image"
                    variant="small"
                    data={data}
                    withPreview
                    showUnavailable={isUnavailable}
                />
                <ProductInfo data={data} withCode />
                <ProductVariants data={data} />
                <div className="product-wide-price">
                    <ProductPrice
                        className="product-card-price"
                        data={data}
                        withNew={settings.showNewTag}
                        withPercent
                    />
                </div>
            </>;
        }

        return <>
            <div className="product-card-top">
                {hasCode && <div className="product-card-code">{productCode}</div>}
                <ProductImage
                    className="product-card-image"
                    variant="small"
                    data={data}
                    showUnavailable={isUnavailable}
                    withPreview
                />
                <ProductInfo data={data} withPrice={settings.showPriceTop} />
                <ProductVariants data={data} />
            </div>
            {hasBottomPrice && <ProductPrice
                className="product-bottom-price product-card-price"
                data={data}
                withNew={settings.showNewTag}
                withPercent
            />}
        </>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isAuthenticated, isMobile, isTablet, data, columns, onClick } = this.props;

        const isApp        = isMobile || isTablet;
        const isWide       = columns === 1;
        const hasFavorites = isAuthenticated && settings.hasProductFavorites;
        const hasButton    = (isApp && settings.showMobileBtn) || (!isApp && settings.showDesktopBtn);
        const button       = settings.allowCart ? "PRODUCTS_ADD_TO_CART" : "PRODUCTS_VIEW_TITLE";
        
        const classes      = new ClassList("product-card");
        classes.addIf("product-narrow",           !isWide);
        classes.addIf("product-wide",             isWide);
        classes.addIf("product-hover",            !isApp && !isTablet && !settings.showDesktopBtn);
        classes.addIf("product-with-variants",    data.hasVariants);
        classes.addIf("product-without-variants", !data.hasVariants);
        classes.addIf("product-hide-variants",    isApp && settings.hideVariants);
        classes.addIf("product-big-price",        settings.showPriceBig);
        classes.addIf("product-center-text",      !isWide && settings.centerText);
        classes.addIf("product-show-border",      settings.showBorder);
        classes.addIf("product-show-button",      hasButton);

        return <div className={classes.get()} onClick={onClick}>
            {this.renderContent()}
            <div className="product-button">
                <Button
                    variant="primary"
                    message={button}
                    icon="cart"
                    fullWidth
                />
            </div>
            {hasFavorites && <HyperLink
                className="product-card-favorite"
                variant="icon"
                icon={data.isFavorite ? "favorite" : "unfavorite"}
                onClick={this.handleFavorite}
            />}
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleFavorite  : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        isMobile        : PropTypes.bool.isRequired,
        isTablet        : PropTypes.bool.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        data            : PropTypes.object.isRequired,
        onClick         : PropTypes.func.isRequired,
        onFavorite      : PropTypes.func,
        columns         : PropTypes.number,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        hover   : false,
        columns : 0,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isMobile        : state.core.isMobile,
            isTablet        : state.core.isTablet,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(ProductCard.mapStateToProps, {
    toggleFavorite,
})(ProductCard);
