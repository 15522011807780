import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Url                  from "Utils/App/Url";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";



/**
 * The Offer Menu
 */
class OfferMenu extends React.Component {
    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, offers, toggleMenu } = this.props;
        if (offers.length && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, offers, onEnter, onLeave, onClose } = this.props;

        if (!open || !offers.length) {
            return <React.Fragment />;
        }
        return <nav
            className="submenu-container submenu-offer"
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="submenu-offer-grid submenu-grid no-list">
                {offers.map((elem) => <li key={elem.id}>
                    <HyperLink variant="none" href={`${Url.OFFERS}/${elem.id}`} onClick={onClose}>
                        <div className="submenu-offer-image">
                            <img src={elem.image} alt={elem.name} />
                        </div>
                        <h2>{elem.name}</h2>
                    </HyperLink>
                </li>)}
            </ul>
            <div className="submenu-more">
                <Button
                    variant="primary"
                    href={Url.OFFERS}
                    message="HEADER_MORE"
                    onClick={onClose}
                />
            </div>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu : PropTypes.func.isRequired,
        offers     : PropTypes.array.isRequired,
        open       : PropTypes.bool.isRequired,
        onEnter    : PropTypes.func.isRequired,
        onLeave    : PropTypes.func.isRequired,
        onClose    : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            offers : state.store.offers,
        };
    }
}

export default connect(OfferMenu.mapStateToProps, {
    toggleMenu,
})(OfferMenu);
