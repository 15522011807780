import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setProduct }       from "Actions/Store/ProductActions";
import { addProduct }       from "Actions/Store/CartActions";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";
import Metrics              from "Utils/Common/Metrics";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import ProductDesc          from "Components/Product/Item/ProductDesc";
import Dialog               from "Components/Utils/Dialog/Dialog";
import DialogContent        from "Components/Utils/Dialog/DialogContent";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Alert                from "Components/Utils/Form/Alert";
import NumberField          from "Components/Utils/Form/NumberField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    toggleFavorite, addToHistory,
} from "Actions/Store/StoreActions";

// Styles
import "Styles/Components/Product/Item/ProductDialog.css";



/**
 * The Product Dialog
 */
class ProductDialog extends React.Component {
    // The Initial Data
    initialData = {
        amount  : 1,
        variant : {},
    }

    // The Current State
    state = {
        data       : { ...this.initialData },
        stock      : 1,
        discount   : 1,
        isFavorite : false,
        loading    : false,
        success    : "",
        errors     : {},
        error      : "",
    }



    /**
     * Unsets the State if the Product is new
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { isAuthenticated, settings, product, addToHistory } = this.props;
        if (prevProps.product.productID !== product.productID && product.productID) {
            this.setState({
                data       : { amount : 1, variant : {} },
                stock      : {},
                discount   : 1,
                isFavorite : product.isFavorite,
                loading    : false,
                success    : "",
                errors     : {},
                error      : "",
            });
            if (isAuthenticated && settings.hasProductHistory) {
                addToHistory(product.productID);
            }
            Metrics.productClick(product);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const { data, errors } = this.state;
        
        if (this.props.product.hasVariants) {
            const variant = data.variant;
            if (!value) {
                delete variant[name];
            } else {
                variant[name] = value;
            }
            this.setState({
                data   : { ...data,   variant      },
                errors : { ...errors, variant : "" },
            });
        } else {
            this.setState({
                data   : { ...data,   [name] : value },
                errors : { ...errors, [name] : ""    },
            });
        }
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Promise}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { product, addProduct, orderHash      } = this.props;
        const { data : { amount, variant }, loading } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, success : "", error : "" });
        const data = {
            orderHash : orderHash,
            productID : product.productID,
            amount    : amount,
            variant   : JSON.stringify(variant),
        };
        
        try {
            const success = await addProduct(data);
            Metrics.addToCart(product, amount);
            this.setState({ loading : false, success, data : { ...this.initialData } });
        } catch (response) {
            if (response.errors) {
                const stock = response.data || {};
                this.setState({ loading : false, error : response.errors.form, stock });
            }
        }
    }

    /**
     * Handles the Favorite
     * @param {Event} e
     * @returns {Promise}
     */
    handleFavorite = async (e) => {
        e.preventDefault();
        const { product, toggleFavorite, onFavorite } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true, error : "" });
        try {
            await toggleFavorite(product.productID);
            if (onFavorite) {
                onFavorite(product.productID);
            }
            this.setState({ loading : false, isFavorite : !this.state.isFavorite });
        } catch (error) {
            this.setState({ loading : false, error });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.props.setProduct(null);
    }



    /**
     * Sets the Discount value
     * @param {Number} discount
     * @returns {Void}
     */
    setDiscount = (discount) => {
        this.setState({ discount });
    }

    /**
     * Returns the Price data
     * @param {Object} product
     * @returns {Object}
     */
    getPrice(product) {
        const discount = this.state.discount - 1;
        const result   = {
            currencySymbol  : product.currencySymbol || "",
            priceFormat     : product.priceFormat    || 0,
            semaphoreClass  : product.semaphoreClass,
            hasDiscount     : false,
            discountPrice   : 0,
            discountPercent : 0,
            discountOffer   : false,
            discountName    : "",
        };
        if (discount >= 0 && product.discounts && product.discounts[discount]) {
            result.hasDiscount     = true;
            result.discountOffer   = true;
            result.discountPrice   = product.discounts[discount].price;
            result.discountPercent = product.discounts[discount].percent;
        }
        return result;
    }



    /**
     * Renders the Footer
     * @returns {React.ReactElement}
     */
    renderFooter() {
        const { settings, product             } = this.props;
        const { data, loading, success, error } = this.state;

        // Not allowed
        if (!settings.allowCart) {
            return <React.Fragment />;
        }

        if (product.isDisabled) {
            return <div className="product-dialog-without">
                {NLS.get("PRODUCTS_NO_STOCK")}
            </div>;
        }

        // Show the Success
        if (success) {
            return <>
                <h3 className="product-success">{NLS.get(success)}</h3>
                <div className="product-dialog-success">
                    <Button
                        variant="primary"
                        icon="cart"
                        message="PRODUCTS_SHOW_TO_CART"
                        href={Url.CART}
                        onClick={this.closeDialog}
                    />
                    <Button
                        variant="primary"
                        icon="product"
                        message="PRODUCTS_BUY_MORE"
                        onClick={this.closeDialog}
                    />
                </div>
            </>;
        }

        return <>
            <Alert variant="error" message={error} onClose={this.closeAlert} />
            {product.showStock && <div className="product-dialog-stock">
                {NLS.pluralize("PRODUCTS_STOCK", product.stock)}
            </div>}
            <div className="product-dialog-input">
                {!product.hasVariants && <div className="product-dialog-amount">
                    <h3>{NLS.get("PRODUCTS_ADD_AMOUNT")}</h3>
                    <NumberField
                        name="amount"
                        value={data.amount}
                        onChange={this.handleChange}
                    />
                </div>}
                <Button
                    className="product-dialog-button"
                    variant="primary"
                    icon="cart"
                    message="PRODUCTS_ADD_TO_CART"
                    onClick={this.handleSubmit}
                    isDisabled={loading}
                    fullWidth
                />
            </div>
        </>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, settings, product,                                         } = this.props;
        const { success, data : { variant }, discount, isFavorite                           } = this.state;
        const { productCode, name, description, brandName, hasVariants, variants, discounts } = product;

        const open           = Boolean(product.productID);
        const showInput      = Boolean(!success);
        const hasCode        = Boolean(settings.showCode && !!productCode);
        const hasBrand       = Boolean(settings.showBrand && brandName);
        const hasPrice       = settings.showPrice;
        const bigPrice       = settings.showPrice && settings.showPriceBig;
        const hasDiscounts   = Boolean(settings.showOfferFilter && hasPrice && discounts && discounts.length > 0);
        const hasDescription = Boolean(description);
        const showFavorite   = isAuthenticated && settings.hasProductFavorites;

        return <Dialog open={open} className="product-dialog" onClose={this.closeDialog} isWide>
            <DialogContent className="product-dialog-container">
                <div className="product-dialog-content spacing">
                    <aside className="product-dialog-aside">
                        <ProductImage
                            className="product-dialog-image"
                            variant="medium"
                            data={product}
                            withPreview
                            clickPreview
                            withZoom
                        />
                    </aside>
                    <div className="product-dialog-info">
                        <header>
                            {showFavorite && <HyperLink
                                className="product-dialog-favorite"
                                variant="icon"
                                icon={isFavorite ? "favorite" : "unfavorite"}
                                onClick={this.handleFavorite}
                            />}
                            {hasCode && <h5>{productCode}</h5>}
                            {hasBrand && <h4>{brandName}</h4>}
                            <h3>{name}</h3>
                        </header>
                        {hasDescription && <ProductDesc
                            className="product-dialog-desc"
                            description={description}
                            maxLength={500}
                            withMore
                        />}
                        {hasVariants && <ul className="product-dialog-variants no-list">
                            {variants.map((subelem) => <li key={subelem.variantID}>
                                <h4>{subelem.name}</h4>
                                {hasPrice && <ProductPrice
                                    className="product-dialog-price"
                                    data={this.getPrice(subelem)}
                                />}
                                {showInput && <NumberField
                                    variant="medium"
                                    name={subelem.variantID}
                                    value={variant[subelem.variantID] || 0}
                                    minValue={0}
                                    onChange={this.handleChange}
                                />}
                            </li>)}
                        </ul>}
                        {!hasVariants && hasPrice && <ProductPrice
                            className={"product-dialog-price" + (bigPrice ? " product-dialog-bprice" : "")}
                            data={this.getPrice(product)}
                            withNew={settings.showNewTag}
                            withPercent
                        />}
                        {hasDiscounts && <ul className="product-dialog-discounts no-list">
                            <li
                                className={!discount ? "product-dialog-selected" : ""}
                                onClick={() => this.setDiscount(0)}
                            >
                                {NLS.get("PRODUCTS_NO_DISCOUNT")}
                            </li>
                            {discounts.map((subelem) => <li
                                key={subelem.key}
                                className={subelem.key === discount ? "product-dialog-selected" : ""}
                                onClick={() => this.setDiscount(subelem.key)}
                            >
                                {subelem.name}
                            </li>)}
                        </ul>}
                        <footer className="product-dialog-footer">
                            {this.renderFooter()}
                        </footer>
                    </div>
                </div>
            </DialogContent>
        </Dialog>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setProduct      : PropTypes.func.isRequired,
        addProduct      : PropTypes.func.isRequired,
        toggleFavorite  : PropTypes.func.isRequired,
        addToHistory    : PropTypes.func.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        product         : PropTypes.object.isRequired,
        orderHash       : PropTypes.string.isRequired,
        onFavorite      : PropTypes.func,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            settings        : state.core.settings,
            product         : state.product.product,
            orderHash       : state.cart.elem.orderHash,
        };
    }
}

export default connect(ProductDialog.mapStateToProps, {
    setProduct, addProduct, toggleFavorite, addToHistory,
})(ProductDialog);
