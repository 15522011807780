import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import Utils                from "Utils/Common/Utils";

// Components
import EditDialog           from "Components/Utils/Dialog/EditDialog";
import TextField            from "Components/Utils/Form/TextField";
import Columns              from "Components/Utils/Form/Columns";
import Alert                from "Components/Utils/Form/Alert";

// Actions
import {
    fetchAddress, editAddress,
} from "Actions/Client/AddressActions";



/**
 * The Address Edit
 */
class AddressEdit extends React.Component {
    // The Initial Data
    initialData = {
        addressID      : "",
        name           : "",
        street         : "",
        streetNumber   : "",
        betweenStreets : "",
        floor          : "",
        appartment     : "",
        locality       : "",
        province       : "",
        zipCode        : "",
        contactName    : "",
        dni            : "",
        email          : "",
        phone          : "",
        schedule       : "",
        isDefault      : 0,
    }
    
    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
        error   : "",
    }



    /**
     * Get the Address when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, elemID, elem, fetchAddress } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens. Load the new data
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            if (elemID) {
                fetchAddress(elemID);
                loading = true;
            }
        
        // Elem Updated
        } else if (elem.addressID !== prevProps.elem.addressID && elem.addressID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, errors : {}, error : "" });
        try {
            const { success, addressID } = await this.props.editAddress(data);
            this.setState({ loading : false });
            this.props.onSubmit(success, addressID);
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { provinces, elem, elemID, open, onClose } = this.props;
        const { data, loading, errors, error           } = this.state;
        const isEdit = !!elemID;

        return <EditDialog
            open={open}
            className="address-dialog"
            message={isEdit ? "ADDRESSES_EDIT" : "ADDRESSES_CREATE"}
            icon="address"
            isDisabled={loading}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            hasPrimary
            withSpacing
        >
            <Alert variant="error" message={error} onClose={this.closeAlert} />
            {elem.isPending && <p className="client-warning">{NLS.get("ADDRESSES_PENDING")}</p>}

            <Columns amount="2">
                <TextField
                    className="columns-double"
                    name="name"
                    label="ADDRESSES_ALIAS"
                    value={data.name}
                    error={errors.name}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    name="street"
                    label="ADDRESSES_STREET"
                    value={data.street}
                    error={errors.street}
                    onChange={this.handleChange}
                    autoComplete="street-address address-line1"
                    isRequired
                />
                <TextField
                    name="streetNumber"
                    type="number"
                    label="ADDRESSES_STREET_NUMBER"
                    value={data.streetNumber}
                    error={errors.streetNumber}
                    onChange={this.handleChange}
                    autoComplete="address-line2"
                    isRequired
                />

                <TextField
                    name="floor"
                    type="number"
                    label="ADDRESSES_FLOOR"
                    value={data.floor || ""}
                    error={errors.floor}
                    onChange={this.handleChange}
                    autoComplete="address-line3"
                />
                <TextField
                    name="appartment"
                    label="ADDRESSES_APPARTMENT"
                    value={data.appartment}
                    error={errors.appartment}
                    onChange={this.handleChange}
                    autoComplete="address-line4"
                />

                <TextField
                    name="betweenStreets"
                    label="ADDRESSES_BETWEEN_STREETS"
                    value={data.betweenStreets}
                    error={errors.betweenStreets}
                    onChange={this.handleChange}
                />
                <TextField
                    name="zipCode"
                    label="ADDRESSES_ZIP_CODE"
                    value={data.zipCode}
                    error={errors.zipCode}
                    onChange={this.handleChange}
                    autoComplete="postal-code"
                    isRequired
                />
                <TextField
                    name="locality"
                    label="ADDRESSES_LOCALITY"
                    value={data.locality}
                    error={errors.locality}
                    onChange={this.handleChange}
                    autoComplete="address-level2"
                    isRequired
                />
                <TextField
                    type="select"
                    name="province"
                    label="ADDRESSES_PROVINCE"
                    options={provinces}
                    value={data.province}
                    error={errors.province}
                    onChange={this.handleChange}
                    autoComplete="address-level1"
                    withNone
                    isRequired
                />

                <TextField
                    name="contactName"
                    label="ADDRESSES_CONTACT_NAME"
                    helperText="ADDRESSES_CONTACT_HELP"
                    value={data.contactName}
                    error={errors.contactName}
                    onChange={this.handleChange}
                />
                <TextField
                    name="dni"
                    label="ADDRESSES_DNI"
                    value={data.dni}
                    error={errors.dni}
                    onChange={this.handleChange}
                />
                <TextField
                    name="email"
                    label="ADDRESSES_EMAIL"
                    value={data.email}
                    error={errors.email}
                    onChange={this.handleChange}
                />
                <TextField
                    name="phone"
                    label="ADDRESSES_PHONE"
                    value={data.phone}
                    error={errors.phone}
                    onChange={this.handleChange}
                />
                <TextField
                    name="schedule"
                    label="ADDRESSES_SCHEDULE"
                    value={data.schedule}
                    error={errors.schedule}
                    onChange={this.handleChange}
                />

                <TextField
                    type="checkbox"
                    name="isDefault"
                    label="ADDRESSES_IS_DEFAULT"
                    value={data.isDefault}
                    error={errors.isDefault}
                    onChange={this.handleChange}
                    isRequired
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAddress : PropTypes.func.isRequired,
        editAddress  : PropTypes.func.isRequired,
        provinces    : PropTypes.array.isRequired,
        elem         : PropTypes.object.isRequired,
        open         : PropTypes.bool,
        elemID       : PropTypes.number,
        onSubmit     : PropTypes.func.isRequired,
        onClose      : PropTypes.func.isRequired,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        open   : false,
        elemID : 0,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            provinces : state.core.entities.provinces,
            elem      : state.address.elem,
        };
    }
}

export default connect(AddressEdit.mapStateToProps, {
    fetchAddress, editAddress,
})(AddressEdit);
