import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchNews }        from "Actions/Content/NewsActions";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Content              from "Components/Utils/Common/Content";
import Pagination           from "Components/Utils/Common/Pagination";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Content/News.css";



/**
 * The News List
 */
class NewsList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchNews(params);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);
        
        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchNews(newParams);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return {
            page : Number(params.page) || 1,
        };
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, isMobile, loading, list, total } = this.props;
        const { page                                  } = this.getParams();
        
        const backUrl = isApp ? Url.ACCOUNT : "";

        for (const elem of list) {
            elem.image = isMobile ? elem.mobileImageUrl : elem.desktopImageUrl;
        }

        return <>
            <Title message="NEWS_NAME" href={backUrl} />
            <Wrapper withSpacing>
                <Content
                    className="news-container"
                    none="NEWS_NONE_AVAILABLE"
                    loading={loading}
                    hasContent={!!list.length}
                    withCard
                    withBorder
                >
                    {list.map((elem) => <section key={elem.newsID}>
                        <SubTitle message={elem.title} smallPadding />
                        <div className="news-content">
                            <div className="news-image">
                                <HyperLink
                                    variant="none"
                                    href={elem.link || `${Url.NEWS_VIEW}/${elem.newsID}`}
                                >
                                    <img src={elem.image} alt={elem.title} />
                                </HyperLink>
                            </div>
                            {!!elem.description && <Html
                                className="news-description spacing"
                                content={elem.description}
                            />}
                        </div>
                    </section>)}
                </Content>
                <Pagination
                    current={page}
                    total={total}
                    url={Url.NEWS}
                />
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchNews : PropTypes.func.isRequired,
        match     : PropTypes.object.isRequired,
        isApp     : PropTypes.bool.isRequired,
        isMobile  : PropTypes.bool.isRequired,
        loading   : PropTypes.bool.isRequired,
        list      : PropTypes.array.isRequired,
        total     : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp    : state.core.isApp,
            isMobile : state.core.isMobile,
            loading  : state.news.loading,
            list     : state.news.list,
            total    : state.news.total,
        };
    }
}

export default connect(NewsList.mapStateToProps, {
    fetchNews,
})(NewsList);
