import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import Url                  from "Utils/App/Url";
import MenuItems            from "Utils/App/MenuItems";
import ClassList            from "Utils/Common/ClassList";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/Footer.css";



/**
 * The Footer
 */
class Footer extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, menuData, isAuthenticated } = this.props;
        const {
            name, footerLogo, facebook, twitter, instagram, youtube,
            floatPhone, floatWhatsApp, floatContact, floatChat,
        } = settings;

        const year          = new Date().getFullYear();
        const hasSocial     = Boolean(facebook || twitter || instagram || youtube);
        const hasFloater    = Boolean(floatPhone || floatWhatsApp || floatContact || (isAuthenticated && floatChat));
        const aboutItems    = MenuItems.getAboutUs(settings, isAuthenticated);
        const conectedItems = MenuItems.getConnected();
        const siteItems     = MenuItems.getSite(settings, menuData, isAuthenticated);
        const columns       = [
            { message : "FOOTER_ABOUT_US",       items : aboutItems    },
            { message : "FOOTER_STAY_CONNECTED", items : conectedItems },
            { message : "FOOTER_SITE_MAP",       items : siteItems     },
        ];

        const classes = new ClassList("copyright-container");
        classes.addIf("copyright-with-social",  hasSocial);
        classes.addIf("copyright-with-floater", hasFloater);

        return <>
            <footer className="footer-container">
                <Wrapper className="footer-content">
                    <div className="footer-logo">
                        {footerLogo ? <HyperLink variant="none" href={Url.HOME}>
                            <img src={footerLogo} alt={name} />
                        </HyperLink> : <h1>
                            <HyperLink variant="primary" href={Url.HOME} message={name} />
                        </h1>}
                    </div>
                    {columns.map(({ message, items }) => <div key={message}>
                        <h3>{NLS.get(message)}</h3>
                        <ul className="no-list">
                            {items.map(({ key, href, message }) => <li key={key}>
                                <HyperLink
                                    variant="white"
                                    href={href}
                                    message={message}
                                />
                            </li>)}
                        </ul>
                    </div>)}
                </Wrapper>
            </footer>

            <footer className={classes.get()}>
                <Wrapper className="copyright-content">
                    <div className="copyright-text">
                        {NLS.format("FOOTER_COPYRIGHT", name, year)}
                    </div>
                    {hasSocial && <ul className="copyright-social no-list">
                        {!!facebook && <li>
                            <HyperLink
                                className="facebook"
                                variant="none"
                                href={facebook}
                                message="Facebook"
                            />
                        </li>}
                        {!!twitter && <li>
                            <HyperLink
                                className="twitter"
                                variant="none"
                                href={twitter}
                                message="Twitter"
                            />
                        </li>}
                        {!!instagram && <li>
                            <HyperLink
                                className="instagram"
                                variant="none"
                                href={instagram}
                                message="Instagram"
                            />
                        </li>}
                        {!!youtube && <li>
                            <HyperLink
                                className="youtube"
                                variant="none"
                                href={youtube}
                                message="YouTube"
                            />
                        </li>}
                    </ul>}
                </Wrapper>
            </footer>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings        : PropTypes.object.isRequired,
        menuData        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            menuData        : state.store.menuData,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(Footer.mapStateToProps, null)(Footer);
