import Utils                from "Utils/Common/Utils";

// Types
import {
    OFFER_LOADING, OFFER_LIST, OFFER_ELEM,
    STORE_FAVORITE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading : true,
    error   : false,
    banners : [],
    list    : [],
    elem    : {
        name          : "GENERAL_LOADING",
        url           : "",
        categories    : [],
        subcategories : [],
        products      : [],
        results       : 0,
        total         : 0,
        pages         : 1,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, OFFER_LIST, OFFER_ELEM)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case OFFER_LOADING:
        return {
            ...state,
            loading    : true,
        };
    
    case OFFER_LIST:
        return {
            ...state,
            loading : false,
            error   : false,
            banners : action.data.banners,
            list    : action.data.list,
        };
    case OFFER_ELEM:
        if (Utils.isEmpty(action.data)) {
            return {
                ...state,
                loading : false,
                error   : true,
                elem    : { ...initialState.elem },
            };
        }
        return {
            ...state,
            loading : false,
            error   : false,
            elem    : action.data,
        };

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error   : false,
            elem    : Utils.setFavorite(state.elem, action.data.productID),
        };
    
    default:
        return state;
    }
};
