import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import Sort                 from "Utils/App/Sort";
import ClassList            from "Utils/Common/ClassList";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";
import TextField            from "Components/Utils/Form/TextField";
import Breadcrumb           from "Components/Product/List/Breadcrumb";

// Actions
import {
    setSort, setColumns,
} from "Actions/Store/StoreActions";

// Styles
import "Styles/Components/Product/List/ProductsOptions.css";



/**
 * The Products Options
 */
class ProductsOptions extends React.Component {
    /**
     * Handles the Sort Change
     * @param {Stirng} name
     * @param {Number} value
     * @returns {Void}
     */
    setSort = async (name, value) => {
        const { isAuthenticated, preferences, setSort, onChange } = this.props;
        if (value !== preferences.sort) {
            await setSort(value, isAuthenticated);
            onChange();
        }
    }

    /**
     * Handles the Column Change
     * @param {Number} value
     * @returns {Void}
     */
    setColumns = (value) => () => {
        const { isAuthenticated, preferences, setColumns } = this.props;
        if (value !== preferences.columns) {
            setColumns(value, isAuthenticated);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, preferences, crumbs, hideSort, hasFilters, setFilter, hasLogo } = this.props;
        const { sort, columns } = preferences;

        const options  = Sort.getOptions(settings);
        const showSort = settings.showSortFilter && !hideSort;
        const use2Rows = !showSort && hasFilters;
        const use2Cols = showSort  && !hasFilters;

        const classes  = new ClassList("prodoptions-container");
        classes.addIf("prodoptions-2rows", use2Rows);
        classes.addIf("prodoptions-2cols", use2Cols);
        classes.addIf("prodoptions-logo",  hasLogo);

        return <Wrapper className={classes.get()}>
            <div className="prodoptions-breadcrumb">
                <Breadcrumb data={crumbs} />
            </div>
            {hasFilters && <HyperLink
                className="prodoptions-filter"
                variant="menu"
                message="PRODUCTS_FILTER"
                icon="filter"
                onClick={() => setFilter(true)}
            />}
            {showSort && <div className="prodoptions-sort">
                <h4>{NLS.get("PRODUCTS_SORT")}</h4>
                <TextField
                    type="select"
                    name="sort"
                    options={options}
                    value={sort}
                    onChange={this.setSort}
                />
            </div>}
            <div className="prodoptions-columns">
                <HyperLink
                    variant="none"
                    className={`prodoptions-cols ${columns === 3 ? "prodoptions-selected" : ""}`}
                    onClick={this.setColumns(3)}
                >
                    <span />
                    <span />
                    <span />
                </HyperLink>
                <HyperLink
                    variant="none"
                    className={`prodoptions-cols prodoptions-wide ${columns === 1 ? "prodoptions-selected" : ""}`}
                    onClick={this.setColumns(1)}
                >
                    <span />
                </HyperLink>
            </div>
        </Wrapper>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setSort         : PropTypes.func.isRequired,
        setColumns      : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        preferences     : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        onChange        : PropTypes.func,
        crumbs          : PropTypes.array,
        hideSort        : PropTypes.bool,
        hasFilters      : PropTypes.bool,
        setFilter       : PropTypes.func,
        hasLogo         : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        hideSort : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            preferences     : state.store.preferences,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(ProductsOptions.mapStateToProps, {
    setSort, setColumns,
})(ProductsOptions);
