import API                  from "Utils/App/API";

// Types
import {
    QUERY_LOADING, QUERY_LIST, QUERY_ELEM,
    QUERY_CREATE, QUERY_CREATED, QUERY_ACK,
} from "Utils/Types";



/**
 * Fetches the Query List
 * @param {Object} params
 * @returns {Function}
 */
export function fetchQueries(params) {
    return async (dispatch) => {
        dispatch({ type : QUERY_LOADING });
        const data = await API.Query.getQueries(params);
        dispatch({ type : QUERY_LIST, data });
    };
}

/**
 * Fetches the Query Data
 * @param {Object} queryID
 * @returns {Function}
 */
export function fetchQuery(queryID) {
    return async (dispatch) => {
        dispatch({ type : QUERY_LOADING });
        const data = await API.Query.getQuery({ queryID });
        dispatch({ type : QUERY_ELEM, data });
    };
}

/**
 * Fetches the Query Create
 * @returns {Function}
 */
export function fetchQueryCreate() {
    return async (dispatch) => {
        dispatch({ type : QUERY_LOADING });
        const data = await API.Query.getQueryCreate();
        dispatch({ type : QUERY_CREATE, data });
    };
}



/**
 * Creates a Query
 * @param {Object} params
 * @returns {Function}
 */
export function createQuery(params) {
    return async (dispatch) => {
        const data = await API.Query.createQuery(params);
        dispatch({ type : QUERY_CREATED, data });
        return data.queryID;
    };
}

/**
 * Acknowledge a Query Create
 * @returns {Function}
 */
export function ackQuery() {
    return async (dispatch) => {
        dispatch({ type : QUERY_ACK });
    };
}

/**
 * Replies to a Query
 * @param {Object} params
 * @returns {Function}
 */
export function replyQuery(params) {
    return async (dispatch) => {
        const data = await API.Query.replyQuery(params);
        dispatch({ type : QUERY_ELEM, data });
        return data.success;
    };
}
