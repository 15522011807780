import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { fetchUpdates }     from "Actions/Store/StoreActions";
import Url                  from "Utils/App/Url";
import Notifications        from "Utils/Common/Notifications";
import Utils                from "Utils/Common/Utils";



/**
 * The Updater
 */
class Updater extends React.Component {
    /**
     * Starts the Timeout
     * @returns {Void}
     */
    componentDidMount() {
        this.startFetch();
    }

    /**
     * Starts the Timeout
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.lastUpdate !== prevProps.lastUpdate) {
            this.startFetch();
        }
    }

    /**
     * End the Timeouts
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = null;
    }

    /**
     * Starts the Auto Fetch
     * @returns {Void}
     */
    startFetch() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(this.doFetch, 10000);
    }

    /**
     * Does the Auto Fetch
     * @returns {Void}
     */
    doFetch = async () => {
        const request       = await this.props.fetchUpdates(this.props.lastUpdate);
        const notifications = request.notifications || [];
        const messages      = request.messages      || [];
        
        if (Notifications.isGranted()) {
            for (const elem of notifications) {
                const data = Notifications.getData(elem);
                Notifications.show(data.title, data.body, this.handleClick(elem.url));
            }
            if (!this.props.onChat && messages.length > 0) {
                const data = Notifications.getData({
                    type : "message",
                    body : [ request.chat.unreadClient ],
                });
                Notifications.show(data.title, data.body, this.handleClick(Url.CHAT));
            }
        }
    }

    /**
     * Goes to the Url
     * @param {String} href
     * @returns {Function}
     */
    handleClick = (href) => () => {
        if (href) {
            Utils.handleHref(href, this.props.history);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <React.Fragment />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history      : PropTypes.object.isRequired,
        fetchUpdates : PropTypes.func.isRequired,
        lastUpdate   : PropTypes.number.isRequired,
        onChat       : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            lastUpdate : state.store.lastUpdate,
            onChat     : state.chat.isViewing,
        };
    }
}

export default withRouter(connect(Updater.mapStateToProps, {
    fetchUpdates,
})(Updater));
