import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";

// Components
import SubTitle             from "Components/Utils/Common/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";
import ProductsCategory     from "Components/Product/List/ProductsCategory";

// Actions
import {
    setPrice, setAmount,
} from "Actions/Store/StoreActions";

// Styles
import "Styles/Components/Product/List/ProductsAside.css";



/**
 * The Products Aside
 */
class ProductsAside extends React.Component {
    /**
     * Handles the Amount Change
     * @param {Number} value
     * @returns {Void}
     */
    setAmount = (value) => async () => {
        const { preferences, setAmount, setFilter, onChange } = this.props;
        if (value !== preferences.amunt) {
            await setAmount(value);
            setFilter(false);
            onChange();
        }
    }

    /**
     * Handles the Price Change
     * @param {Number} value
     * @returns {Void}
     */
    setPrice = (value) => async () => {
        const { isAuthenticated, preferences, setPrice, setFilter, onChange } = this.props;
        if (value !== preferences.price) {
            await setPrice(value, isAuthenticated);
            setFilter(false);
            onChange();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            settings, preferences,
            showLogo, logo, url, logoInBanner,
            showBanner, name, results,
            categories, category,
            subcategories, subcategory,
            tercategories, tercategory,
            prices, amounts,
            openFilter, setFilter, getUrl,
        } = this.props;

        const { showOfferFilter, showCategoryFilter, showPriceFilter, showPrice } = settings;
        const { price, amount                                                   } = preferences;
        
        const showAmounts = Boolean(showOfferFilter    && amounts && amounts.length);
        const showCats    = Boolean(showCategoryFilter && categories && categories.length);
        const showSubs    = Boolean(showCategoryFilter && subcategories && subcategories.length && category);
        const showTers    = Boolean(showCategoryFilter && tercategories && tercategories.length && category && subcategory);
        const showPrices  = Boolean(showPriceFilter    && showPrice && prices && prices[1]);
        const hasFilters  = showAmounts || showCats || showSubs || showPrices;
        
        if (!hasFilters) {
            return <React.Fragment />;
        }

        let priceOptions = [];
        if (showPriceFilter) {
            priceOptions = [
                { id : 1, name : NLS.get("PRODUCTS_TYPE_ALL"),                                     total : prices[1].total },
                { id : 2, name : NLS.format("PRODUCTS_PRICE_TO",    prices[2].to),                 total : prices[2].total },
                { id : 3, name : NLS.format("PRODUCTS_PRICE_RANGE", prices[3].from, prices[3].to), total : prices[3].total },
                { id : 4, name : NLS.format("PRODUCTS_PRICE_FROM",  prices[4].from),               total : prices[4].total },
            ];
        }

        return <aside className={"aside" + (logoInBanner ? " aside-top" : "")}>
            {showLogo && <HyperLink variant="none" href={url} className="aside-logo">
                {logo && <img src={logo} alt={name} />}
                {!logo && <h2>{NLS.get(name)}</h2>}
            </HyperLink>}
            {showBanner && <header className="aside-header">
                <div className="aside-info">
                    <h3>{NLS.get(name)}</h3>
                    <p className="aside-results">
                        {NLS.pluralize("PRODUCTS_RESULTS", results)}
                    </p>
                </div>
            </header>}
            <main className={"aside-content" + (openFilter ? " aside-open" : "")}>
                <SubTitle
                    className="aside-subtitle"
                    message="PRODUCTS_FILTER_TITLE"
                    icon="filter"
                >
                    <HyperLink
                        variant="icon"
                        icon="close"
                        onClick={() => setFilter(false)}
                    />
                </SubTitle>
                <section className="aside-filters">
                    {showAmounts && <div className="aside-options">
                        <h4>{NLS.get("OFFERS_FILTER")}</h4>
                        <ul className="products-option no-list">
                            {amounts.map((elem) => <li key={elem.id}>
                                <HyperLink
                                    variant="opacity"
                                    onClick={this.setAmount(elem.id)}
                                    icon={amount === elem.id ? "check" : "blank"}
                                    message={elem.filter}
                                />
                            </li>)}
                        </ul>
                    </div>}
                    {showCats && <ProductsCategory
                        data={categories}
                        selected={category}
                        getUrl={getUrl}
                        onChange={() => setFilter(false)}
                    />}
                    {showSubs && <ProductsCategory
                        data={subcategories}
                        selected={subcategory}
                        getUrl={getUrl}
                        onChange={() => setFilter(false)}
                        isSub
                    />}
                    {showTers && <ProductsCategory
                        data={tercategories}
                        selected={tercategory}
                        getUrl={getUrl}
                        onChange={() => setFilter(false)}
                        isTer
                    />}
                    {showPrices && <div className="aside-options">
                        <h4>{NLS.get("PRODUCTS_PRICE")}</h4>
                        <ul className="products-option no-list">
                            {priceOptions.map((elem) => <li key={elem.id}>
                                <HyperLink
                                    variant="opacity"
                                    onClick={this.setPrice(elem.id)}
                                    icon={price === elem.id ? "check" : "blank"}
                                >
                                    {elem.name}
                                    <span className="aside-amount">{elem.total}</span>
                                </HyperLink>
                            </li>)}
                        </ul>
                    </div>}
                </section>
            </main>
        </aside>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPrice        : PropTypes.func.isRequired,
        setAmount       : PropTypes.func.isRequired,
        onChange        : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        preferences     : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        showLogo        : PropTypes.bool,
        logo            : PropTypes.string,
        url             : PropTypes.string,
        showBanner      : PropTypes.bool,
        logoInBanner    : PropTypes.bool,
        name            : PropTypes.string,
        results         : PropTypes.number,
        amounts         : PropTypes.array,
        categories      : PropTypes.array,
        category        : PropTypes.string,
        subcategories   : PropTypes.array,
        subcategory     : PropTypes.string,
        tercategories   : PropTypes.array,
        tercategory     : PropTypes.string,
        prices          : PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
        openFilter      : PropTypes.bool,
        setFilter       : PropTypes.func,
        getUrl          : PropTypes.func,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            preferences     : state.store.preferences,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(ProductsAside.mapStateToProps, {
    setPrice, setAmount,
})(ProductsAside);
