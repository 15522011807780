import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Subcategory Menu
 */
class SubcategoryMenu extends React.Component {
    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, categories, categoryID, toggleMenu } = this.props;
        const category = Utils.getData(categories, "id", categoryID);
        if (category && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, settings, categories, categoryID, onEnter, onLeave, onClose } = this.props;
        const category = Utils.getData(categories, "id", categoryID);

        if (!open || !category) {
            return <React.Fragment />;
        }
        const hasTer = settings.hasTercategories;

        return <nav
            className={"submenu-container submenu-subcategory submenu-category-subs" + (hasTer ? " submenu-category-ters" : "")}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <h2>{category.name}</h2>
            <ul className="no-list">
                {category.childs.map((elem) => <li key={elem.id}>
                    <HyperLink
                        variant="opacity"
                        href={`${Url.PRODUCTS}/${category.url}/${elem.url}`}
                        message={elem.name}
                        onClick={onClose}
                    />
                    {elem.childs.length > 0 && <ul className="no-list">
                        {elem.childs.map((subelem) => <li key={subelem.id}>
                            <HyperLink
                                variant="opacity"
                                href={`${Url.PRODUCTS}/${category.url}/${elem.url}/${subelem.url}`}
                                message={subelem.name}
                                onClick={onClose}
                            />
                        </li>)}
                    </ul>}
                </li>)}
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        categories : PropTypes.array.isRequired,
        open       : PropTypes.bool.isRequired,
        categoryID : PropTypes.number.isRequired,
        onEnter    : PropTypes.func.isRequired,
        onLeave    : PropTypes.func.isRequired,
        onClose    : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings   : state.core.settings,
            categories : state.store.categories,
        };
    }
}

export default connect(SubcategoryMenu.mapStateToProps, {
    toggleMenu,
})(SubcategoryMenu);
