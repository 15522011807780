import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Metrics              from "Utils/Common/Metrics";
import Utils                from "Utils/Common/Utils";

// Components
import CartOption           from "Components/Cart/Utils/CartOption";
import CartSummary          from "Components/Cart/Utils/CartSummary";
import PaymentDialog        from "Components/Cart/Payment/PaymentDialog";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Html                 from "Components/Utils/Common/Html";
import TextField            from "Components/Utils/Form/TextField";

// Actions
import {
    unconfirmToShipment, confirmPayment, setPayment,
} from "Actions/Store/CartActions";



/**
 * The Payment Content
 */
class PaymentContent extends React.Component {
    // The Current State
    state = {
        showDialog : "",
        loading    : false,
        data       : {
            paymentType : 0,
            message     : "",
        },
    }



    /**
     * Set the Data When Loading
     * @returns {Void}
     */
    componentDidMount() {
        const { settings, elem                 } = this.props;
        const { payments, paymentType, message } = elem;

        if (paymentType) {
            const payment = Utils.getData(payments, "type", paymentType);
            if (Utils.isEmpty(payment)) {
                this.setState({ data : { paymentType : 0, message } });
            } else {
                this.setState({ data : { paymentType, message } });
            }
        } else if (settings.hidePayments) {
            this.setPayment(payments[0].type);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data : { ...this.state.data, [name] : value },
        });
    }

    /**
     * Selects the Payment Type
     * @param {Number} paymentType
     * @returns {Void}
     */
    setPayment = (paymentType) => {
        this.setState({ data : { ...this.state.data, paymentType } });
        this.props.setPayment(paymentType);
    }



    /**
     * Handles a Cart Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { isApp, elem, confirmPayment, openAlert, closeAlert, onSubmit } = this.props;
        const { data, loading                                                } = this.state;
        if (loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            const response = await confirmPayment(data);
            Metrics.checkout(Metrics.stepPayment, elem.products);
            if (response.checkout) {
                if (isApp) {
                    this.setState({ showDialog : response.checkout, loading : false });
                } else {
                    window.location.href = response.checkout;
                }
            } else {
                Metrics.purchase(response.orderID, elem.products);
                onSubmit(response.orderID);
            }
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }

    /**
     * Handles a Cart Cancel
     * @returns {Promise}
     */
    handleCancel = async () => {
        const { elem, unconfirmToShipment, openAlert, closeAlert, onSubmit } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            await unconfirmToShipment();
            Metrics.checkout(Metrics.stepShipment, elem.products);
            onSubmit();
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, elem, openAlert         } = this.props;
        const { payments, shipments, shipmentType } = elem;
        const { showDialog, loading, data         } = this.state;

        const payment     = Utils.getData(payments,  "type", data.paymentType);
        const shipment    = Utils.getData(shipments, "type", shipmentType);
        const isDisabled  = loading || data.paymentType === 0;
        const hasShipment = !settings.hideShipments || shipment.reqAddress || shipment.reqSubsidiary;

        return <>
            <section className="cart-content">
                <CartOption
                    show={!settings.hidePayments}
                    data={payments}
                    icon="payment"
                    message="CART_PAYMENT_TITLE"
                    help="CART_PAYMENT_HELP"
                    selected={data.paymentType}
                    onClick={this.setPayment}
                />
                
                {!!payment.data && <Card className="cart-payment-card" withBorder>
                    <SubTitle message="CART_PAYMENT_DATA" icon="info" />
                    <Html className="spacing" content={payment.data} />
                </Card>}

                <Card className="cart-message-card" withBorder>
                    <SubTitle message="CART_MESSAGE" icon="query" />
                    <div className="spacing">
                        <TextField
                            type="textarea"
                            name="message"
                            label="CART_WRITE_MESSAGE"
                            value={data.message}
                            onChange={this.handleChange}
                            isLarge
                            autoGrow
                        />
                    </div>
                </Card>
            </section>

            <CartSummary
                submit="CART_CONFIRM_PAYMENT"
                cancel={hasShipment ? "CART_EDIT_SHIPMENT" : "CART_EDIT_PRODUCTS"}
                openAlert={openAlert}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                isDisabled={isDisabled}
            />
            <PaymentDialog
                open={!!showDialog}
                checkout={showDialog}
                onClose={this.closeDialog}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmToShipment : PropTypes.func.isRequired,
        confirmPayment      : PropTypes.func.isRequired,
        setPayment          : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        openAlert           : PropTypes.func.isRequired,
        closeAlert          : PropTypes.func.isRequired,
        isApp               : PropTypes.bool.isRequired,
        settings            : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp    : state.core.isApp,
            settings : state.core.settings,
            elem     : state.cart.elem,
        };
    }
}

export default connect(PaymentContent.mapStateToProps, {
    unconfirmToShipment, confirmPayment, setPayment,
})(PaymentContent);
