import React                from "react";
import PropTypes            from "prop-types";

// Styles
import "Styles/Components/Utils/Common/Icon.css";
import "Styles/Components/Utils/Common/Icomoon.css";



/**
 * The Icon
 */
class Icon extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { variant, className, onClick } = this.props;

        return <span
            className={`icon icon-${variant} ${className}`}
            onClick={onClick}
        />;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        variant   : PropTypes.string.isRequired,
        className : PropTypes.string,
        onClick   : PropTypes.func,
    }

    /**
     * The Default Properties
     * @type {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default Icon;
