import React                from "react";
import PropTypes            from "prop-types";

// Components
import Dialog               from "Components/Utils/Dialog/Dialog";
import DialogContent        from "Components/Utils/Dialog/DialogContent";
import DialogFooter         from "Components/Utils/Dialog/DialogFooter";
import Button               from "Components/Utils/Form/Button";



/**
 * The Edit Dialog
 */
class EditDialog extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            open, className, message, icon,
            hasPrimary, primary, isDisabled, onSubmit, onClose,
            withSpacing, children,
        } = this.props;

        return <Dialog
            open={open}
            className={className}
            message={message}
            icon={icon}
            onClose={onClose}
            hasFooter
        >
            <DialogContent withSpacing={withSpacing}>
                {children}
            </DialogContent>
            <DialogFooter>
                {hasPrimary && <Button
                    variant="primary"
                    message={primary || "GENERAL_SAVE"}
                    isDisabled={isDisabled}
                    onClick={onSubmit}
                />}
                <Button
                    variant="cancel"
                    message="GENERAL_CANCEL"
                    onClick={onClose}
                />
            </DialogFooter>
        </Dialog>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open        : PropTypes.bool,
        className   : PropTypes.string,
        message     : PropTypes.string,
        icon        : PropTypes.string,
        hasPrimary  : PropTypes.bool,
        primary     : PropTypes.string,
        isDisabled  : PropTypes.bool,
        onSubmit    : PropTypes.func,
        onClose     : PropTypes.func.isRequired,
        withSpacing : PropTypes.bool,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        open        : false,
        className   : "",
        message     : "",
        icon        : "",
        hasPrimary  : false,
        primary     : "GENERAL_SAVE",
        isDisabled  : false,
        withSpacing : false,
    }
}

export default EditDialog;
