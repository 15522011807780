import API                  from "Utils/App/API";

// Types
import {
    PRODUCT_LOADING, PRODUCT_CATEGORIES, PRODUCT_LIST,
    PRODUCT_SEARCH, PRODUCT_IMPORTANTS, PRODUCT_NEWESTS,
    PRODUCT_FAVORITES, PRODUCT_PURCHASES, PRODUCT_HISTORY,
    PRODUCT_DIALOG, PRODUCT_PREVIEW,
} from "Utils/Types";



/**
 * Fetches the Category List
 * @returns {Function}
 */
export function fetchCategories() {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getCategories();
        dispatch({ type : PRODUCT_CATEGORIES, data });
    };
}

/**
 * Fetches the Search
 * @param {Object} params
 * @returns {Function}
 */
export function fetchProducts(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getProducts(params);
        dispatch({ type : PRODUCT_LIST, data });
    };
}

/**
 * Fetches the Search
 * @param {Object} params
 * @returns {Function}
 */
export function fetchSearch(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getSearch(params);
        dispatch({ type : PRODUCT_SEARCH, data });
    };
}



/**
 * Fetches the Important Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchImportants(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getImportants(params);
        dispatch({ type : PRODUCT_IMPORTANTS, data });
    };
}

/**
 * Fetches the Newest Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchNewests(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getNewests(params);
        dispatch({ type : PRODUCT_NEWESTS, data });
    };
}

/**
 * Fetches the Favorite Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchFavorites(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getFavorites(params);
        dispatch({ type : PRODUCT_FAVORITES, data });
    };
}

/**
 * Fetches the Purchase Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchPurchases(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getPurchases(params);
        dispatch({ type : PRODUCT_PURCHASES, data });
    };
}

/**
 * Fetches the History Products
 * @param {Object} params
 * @returns {Function}
 */
export function fetchHistory(params) {
    return async (dispatch) => {
        dispatch({ type : PRODUCT_LOADING });
        const data = await API.Product.getHistory(params);
        dispatch({ type : PRODUCT_HISTORY, data });
    };
}



/**
 * Sets the Product Dialog
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setProduct(data) {
    return { type : PRODUCT_DIALOG, data };
}

/**
 * Sets the Product Preview
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setPreview(data) {
    return { type : PRODUCT_PREVIEW, data };
}
