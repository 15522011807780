import React                from "react";
import PropTypes            from "prop-types";

// Components
import TextField            from "Components/Utils/Form/TextField";

// Styles
import "Styles/Components/Utils/Form/Columns.css";



/**
 * The Form Columns
 */
class Columns extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { amount, onSubmit, autoFocus, children } = this.props;
        let isFirst = true;
        const childrenWithProps = React.Children.map(children, (child) => {
            if (child) {
                const canFocus = child.type === TextField && child.props.show && !child.props.isDisabled;
                const result   = React.cloneElement(child, {
                    onSubmit, autoFocus : canFocus && autoFocus && isFirst,
                });
                if (canFocus) {
                    isFirst = false;
                }
                return result;
            }
            return null;
        });

        return <div className={`columns-${amount}`}>
            {childrenWithProps}
        </div>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        amount    : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        onSubmit  : PropTypes.func,
        autoFocus : PropTypes.bool,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        amount : 2,
    }
}

export default Columns;
