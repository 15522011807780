export const CORE_CONFIG            = "CORE_CONFIG";
export const CORE_PLATFORM          = "CORE_PLATFORM";
export const CORE_DATA              = "CORE_DATA";
export const CORE_LOADING           = "CORE_LOADING";
export const CORE_REDIRECT          = "CORE_REDIRECT";
export const CORE_SECTION           = "CORE_SECTION";
export const CORE_RESULT            = "CORE_RESULT";
export const CORE_RESPONSIVE        = "CORE_RESPONSIVE";

export const AUTH_CURRENT_USER      = "AUTH_CURRENT_USER";
export const AUTH_DATA              = "AUTH_DATA";
export const AUTH_FOCUSED           = "AUTH_FOCUSED";
export const AUTH_RESET             = "AUTH_RESET";
export const AUTH_REGISTER          = "AUTH_REGISTER";
export const AUTH_LOGOUT            = "AUTH_LOGOUT";

export const STORE_DATA             = "STORE_DATA";
export const STORE_UPDATES          = "STORE_UPDATES";
export const STORE_LAST_UPDATE      = "STORE_LAST_UPDATE";
export const STORE_PREFERENCE       = "STORE_PREFERENCE";
export const STORE_FAVORITE         = "STORE_FAVORITE";
export const STORE_HISTORY          = "STORE_HISTORY";
export const STORE_MENU             = "STORE_MENU";
export const STORE_NAVIGATION       = "STORE_NAVIGATION";
export const STORE_SEARCH           = "STORE_SEARCH";



// Store Types
export const PRODUCT_LOADING        = "PRODUCT_LOADING";
export const PRODUCT_CATEGORIES     = "PRODUCT_CATEGORIES";
export const PRODUCT_LIST           = "PRODUCT_LIST";
export const PRODUCT_SEARCH         = "PRODUCT_SEARCH";
export const PRODUCT_IMPORTANTS     = "PRODUCT_IMPORTANTS";
export const PRODUCT_NEWESTS        = "PRODUCT_NEWESTS";
export const PRODUCT_FAVORITES      = "PRODUCT_FAVORITES";
export const PRODUCT_PURCHASES      = "PRODUCT_PURCHASES";
export const PRODUCT_HISTORY        = "PRODUCT_HISTORY";
export const PRODUCT_DIALOG         = "PRODUCT_DIALOG";
export const PRODUCT_PREVIEW        = "PRODUCT_PREVIEW";

export const OFFER_LOADING          = "OFFER_LOADING";
export const OFFER_LIST             = "OFFER_LIST";
export const OFFER_ELEM             = "OFFER_ELEM";

export const BRAND_LOADING          = "BRAND_LOADING";
export const BRAND_LIST             = "BRAND_LIST";
export const BRAND_ELEM             = "BRAND_ELEM";

export const CART_LOADING           = "CART_LOADING";
export const CART_ELEM              = "CART_ELEM";
export const CART_ASSIGN            = "CART_ASSIGN";
export const CART_REDIRECT          = "CART_REDIRECT";
export const CART_SHIPMENT          = "CART_SHIPMENT";
export const CART_PRICES            = "CART_PRICES";
export const CART_PAYMENT           = "CART_PAYMENT";
export const CART_COMPLETE          = "CART_COMPLETE";



// Content Types
export const HOME_LOADING           = "HOME_LOADING";
export const HOME_ELEM              = "HOME_ELEM";

export const CONTACT_LOADING        = "CONTACT_LOADING";
export const CONTACT_ELEM           = "CONTACT_ELEM";

export const NEWS_LOADING           = "NEWS_LOADING";
export const NEWS_LIST              = "NEWS_LIST";
export const NEWS_ELEM              = "NEWS_ELEM";

export const CHAT_ELEM              = "CHAT_ELEM";
export const CHAT_MESSAGES          = "CHAT_MESSAGES";
export const CHAT_NEW_MESSAGES      = "CHAT_NEW_MESSAGES";
export const CHAT_VIEWING           = "CHAT_VIEWING";

export const NOTIFICATION_DISCARDED = "NOTIFICATION_DISCARDED";
export const NOTIFICATION_REDIRECT  = "NOTIFICATION_REDIRECT";



// Client Types
export const CLIENT_ACCOUNT         = "CLIENT_ACCOUNT";
export const CLIENT_PAGE            = "CLIENT_PAGE";

export const ADDRESS_LOADING        = "ADDRESS_LOADING";
export const ADDRESS_UNSET          = "ADDRESS_UNSET";
export const ADDRESS_LIST           = "ADDRESS_LIST";
export const ADDRESS_ELEM           = "ADDRESS_ELEM";
export const ADDRESS_EDITED         = "ADDRESS_EDITED";
export const ADDRESS_DELETED        = "ADDRESS_DELETED";
export const ADDRESS_ACK            = "ADDRESS_ACK";

export const VOUCHER_LOADING        = "VOUCHER_LOADING";
export const VOUCHER_INVOICES       = "VOUCHER_INVOICES";
export const VOUCHER_RECEIPTS       = "VOUCHER_RECEIPTS";
export const VOUCHER_CREDIT_NOTES   = "VOUCHER_CREDIT_NOTES";
export const VOUCHER_DEBIT_NOTES    = "VOUCHER_DEBIT_NOTES";

export const QUERY_LOADING          = "QUERY_LOADING";
export const QUERY_LIST             = "QUERY_LIST";
export const QUERY_ELEM             = "QUERY_ELEM";
export const QUERY_CREATE           = "QUERY_CREATE";
export const QUERY_CREATED          = "QUERY_CREATED";
export const QUERY_ACK              = "QUERY_ACK";

export const ORDER_LOADING          = "ORDER_LOADING";
export const ORDER_LIST             = "ORDER_LIST";
export const ORDER_ELEM             = "ORDER_ELEM";
