import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import { fetchData }        from "Actions/Core/AuthActions";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import LoginPage            from "./LoginPage";
import RegisterPage         from "./RegisterPage";
import RecoverPage          from "./RecoverPage";
import ResetPage            from "./ResetPage";
import GuestRoute           from "Components/Utils/Route/GuestRoute";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Auth/Auth.css";



/**
 * The Auth Container
 */
class AuthContainer extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (!this.props.loaded) {
            this.props.fetchData();
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isMobile, background, isFocused } = this.props;
        const { logo, name, authLogo                      } = settings;

        const style   = {};
        const logoImg = authLogo || logo;
        let   bg      = isMobile ? background.mobile : background.desktop;

        if (bg) {
            if (bg.isImage) {
                style["--auth-image"] = `url(${bg.url})`;
            }
            if (!bg.showBackdrop) {
                style["--auth-drop"] = "none";
            }
        } else {
            bg = {};
        }
        
        const classes = new ClassList("auth-container");
        classes.addIf("auth-focused",  isFocused);
        classes.addIf("auth-centered", settings.centerAuthLogo);

        return <section className={classes.get()} style={style}>
            <div className="auth-content">
                <header className="auth-header">
                    {logoImg ? <HyperLink variant="none" href={Url.HOME}>
                        <img src={logoImg} alt={name} />
                    </HyperLink> : <h1>
                        <HyperLink variant="primary" href={Url.HOME} message={name} />
                    </h1>}
                </header>
                
                <Switch>
                    <GuestRoute path={Url.LOGIN}    component={LoginPage}    exact />
                    <GuestRoute path={Url.REGISTER} component={RegisterPage} exact />
                    <GuestRoute path={Url.RECOVER}  component={RecoverPage}  exact />
                    <GuestRoute path={Url.RESET}    component={ResetPage}    exact />
                    <GuestRoute path={Url.CODE}     component={ResetPage}    exact />
                    <Redirect from="*" to={Url.LOGIN} />
                </Switch>
                
                <footer className="auth-terms">
                    {NLS.format("AUTH_TERMS1", name)}
                    <HyperLink
                        className="auth-terms-anchor"
                        variant="black"
                        href={Url.TERMS}
                        target="_blank"
                        message="AUTH_TERMS2"
                    />
                    {NLS.get("AUTH_TERMS3")}
                </footer>
            </div>
            <div className="auth-aside">
                {bg.isVideo && <div className="auth-video">
                    <video width="1900" height="800" src={bg.url} preload="auto" muted loop autoPlay>
                        <source type="video/mp4" src={bg.url} />
                    </video>
                </div>}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchData  : PropTypes.func.isRequired,
        isMobile   : PropTypes.bool.isRequired,
        settings   : PropTypes.object.isRequired,
        loaded     : PropTypes.bool.isRequired,
        background : PropTypes.object.isRequired,
        isFocused  : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile   : state.core.isMobile,
            settings   : state.core.settings,
            loaded     : state.auth.loaded,
            background : state.auth.background,
            isFocused  : state.auth.isFocused,
        };
    }
}

export default connect(AuthContainer.mapStateToProps, {
    fetchData,
})(AuthContainer);
