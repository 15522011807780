import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Url                  from "Utils/App/Url";
import MenuItems            from "Utils/App/MenuItems";

// Components
import CategoryMenu         from "Components/Core/Menu/CategoryMenu";
import SubcategoryMenu      from "Components/Core/Menu/SubcategoryMenu";
import OfferMenu            from "Components/Core/Menu/OfferMenu";
import BrandMenu            from "Components/Core/Menu/BrandMenu";
import ClientMenu           from "Components/Core/Menu/ClientMenu";
import FavoriteMenu         from "Components/Core/Menu/FavoriteMenu";
import NotificationMenu     from "Components/Core/Menu/NotificationMenu";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Core/Menu.css";



/**
 * The Menu Bar
 */
class MenuBar extends React.Component {
    // The Current State
    state = {
        submenu   : "",
        submenuID : 0,
    }

    // Timeouts
    openTimeout  = null;
    closeTimeout = null;
    


    /**
     * Shows a Submenu
     * @param {String}  submenu
     * @param {Number=} submenuID
     * @returns {Function}
     */
    showSubmenu = (submenu, submenuID = 0) => () => {
        if (submenu && !this.openTimeout) {
            this.clearTimeouts();
            this.openTimeout = window.setTimeout(this.openSubmenu(submenu, submenuID), 200);
        }
    }
    
    /**
     * Open a Submenu
     * @param {String}  submenu
     * @param {Number=} submenuID
     * @returns {Function}
     */
    openSubmenu = (submenu, submenuID = 0) => () => {
        this.openTimeout = null;
        this.setState({ submenu, submenuID });
    }

    /**
     * Hides the Submenu
     * @returns {Function}
     */
    hideSubmenu = () => {
        this.clearTimeouts();
        this.closeTimeout = window.setTimeout(this.closeSubmenu, 200);
    }

    /**
     * Closes the Submenu
     * @returns {Void}
     */
    closeSubmenu = () => {
        this.clearTimeouts();
        this.setState({ submenu : "" });
        this.props.toggleMenu(false);
    }

    /**
     * Keeps the Submenu
     * @returns {Void}
     */
    keepSubmenu = () => {
        this.clearTimeouts();
    }

    /**
     * Clears the Timeouts
     * @returns {Void}
     */
    clearTimeouts() {
        if (this.openTimeout) {
            window.clearTimeout(this.openTimeout);
            this.openTimeout = null;
        }
        if (this.closeTimeout) {
            window.clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isAuthenticated, menus, menuData, chats, notifications } = this.props;
        const { submenu, submenuID                                               } = this.state;

        const showLogin      = !isAuthenticated && settings.allowLogin;
        const showRegister   = !isAuthenticated && settings.allowLogin && settings.allowRegister && settings.showRegisterMenu;
        const showFavorites  = isAuthenticated && settings.hasProductFavorites;
        const showChat       = isAuthenticated && settings.hasChat;
        const primaryItems   = MenuItems.getPrimary(menus, settings, menuData);
        const secondaryItems = MenuItems.getSecondary(menus, settings, menuData);
        
        return <section className="menu-container">
            <div className="menu-content">
                <ul className="menu-nav menu-primary no-list">
                    {primaryItems.map(({ key, href, target, message, isBold, submenu, submenuID }) => <li key={key}>
                        <HyperLink
                            variant={isBold ? "menu-bold" : "menu"}
                            href={href}
                            target={target}
                            message={message}
                            onMouseEnter={this.showSubmenu(submenu, submenuID)}
                            onMouseLeave={this.hideSubmenu}
                            onClick={this.closeSubmenu}
                            afterIcon={settings.showMenuArrows && submenu ? "down" : ""}
                            dontStop
                        />
                    </li>)}
                </ul>
                <ul className="menu-nav menu-secondary no-list">
                    {secondaryItems.map(({ key, href, target, message, isBold, submenu, submenuID }) => <li key={key}>
                        <HyperLink
                            variant={isBold ? "menu-bold" : "menu"}
                            href={href}
                            target={target}
                            message={message}
                            onMouseEnter={this.showSubmenu(submenu, submenuID)}
                            onMouseLeave={this.hideSubmenu}
                            onClick={this.closeSubmenu}
                            afterIcon={settings.showMenuArrows && submenu ? "down" : ""}
                            dontStop
                        />
                    </li>)}
                </ul>
                <ul className="menu-nav menu-terciary no-list">
                    {isAuthenticated && <li>
                        <HyperLink
                            variant="menu"
                            href={Url.ACCOUNT_DATA}
                            message="CLIENT_NAME"
                            onMouseEnter={this.showSubmenu("client")}
                            onMouseLeave={this.hideSubmenu}
                            onClick={this.closeSubmenu}
                            icon="client"
                            afterIcon={settings.showMenuArrows ? "down" : ""}
                        />
                    </li>}
                    {showRegister && <li>
                        <HyperLink
                            variant="menu"
                            href={Url.REGISTER}
                            message="AUTH_REGISTER_LINK"
                        />
                    </li>}
                    {showLogin && <li>
                        <HyperLink
                            variant="menu"
                            href={Url.LOGIN}
                            message="AUTH_LOGIN_ACTION"
                        />
                    </li>}
                    {showFavorites && <li>
                        <HyperLink
                            variant="icon"
                            href={Url.FAVORITES}
                            onMouseEnter={this.showSubmenu("favorite")}
                            onMouseLeave={this.hideSubmenu}
                            onClick={this.closeSubmenu}
                            icon="favorite"
                        />
                    </li>}
                    {showChat && <li>
                        <HyperLink
                            variant="icon"
                            href={Url.CHAT}
                            icon="chat"
                            badge={chats}
                        />
                    </li>}
                    {isAuthenticated && <li>
                        <HyperLink
                            variant="icon"
                            href={Url.NOTIFICATIONS}
                            onMouseEnter={this.showSubmenu("notification")}
                            onMouseLeave={this.hideSubmenu}
                            onClick={this.closeSubmenu}
                            icon="notification"
                            badge={notifications}
                        />
                    </li>}
                    {settings.allowCart && <li>
                        <HyperLink
                            variant="icon"
                            href={Url.CART}
                            icon="cart"
                            badge={menuData.cart}
                        />
                    </li>}
                </ul>
            </div>

            <CategoryMenu
                open={submenu === "category"}
                onEnter={this.keepSubmenu}
                onLeave={this.hideSubmenu}
                onClose={this.closeSubmenu}
            />
            <SubcategoryMenu
                open={submenu === "subcategory"}
                categoryID={submenuID}
                onEnter={this.keepSubmenu}
                onLeave={this.hideSubmenu}
                onClose={this.closeSubmenu}
            />
            <OfferMenu
                open={submenu === "offer"}
                onEnter={this.keepSubmenu}
                onLeave={this.hideSubmenu}
                onClose={this.closeSubmenu}
            />
            <BrandMenu
                open={submenu === "brand"}
                onEnter={this.keepSubmenu}
                onLeave={this.hideSubmenu}
                onClose={this.closeSubmenu}
            />
            <ClientMenu
                open={submenu === "client"}
                onEnter={this.keepSubmenu}
                onLeave={this.hideSubmenu}
                onClose={this.closeSubmenu}
            />
            <FavoriteMenu
                open={submenu === "favorite"}
                onEnter={this.keepSubmenu}
                onLeave={this.hideSubmenu}
                onClose={this.closeSubmenu}
            />
            <NotificationMenu
                open={submenu === "notification"}
                onEnter={this.keepSubmenu}
                onLeave={this.hideSubmenu}
                onClose={this.closeSubmenu}
            />
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu      : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        menus           : PropTypes.object.isRequired,
        menuData        : PropTypes.object.isRequired,
        chats           : PropTypes.number.isRequired,
        notifications   : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            menus           : state.store.menus,
            menuData        : state.store.menuData,
            chats           : state.chat.elem.unreadClient,
            notifications   : state.notification.list.length,
        };
    }
}

export default connect(MenuBar.mapStateToProps, {
    toggleMenu,
})(MenuBar);
