import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchNewests }     from "Actions/Store/ProductActions";
import Url                  from "Utils/App/Url";
import Sort                 from "Utils/App/Sort";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";

// Components
import Title                from "Components/Utils/Common/Title";
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";



/**
 * The Newest Page
 */
class NewestPage extends React.Component {
    // The Production
    production = new Production(this.props.fetchNewests, "", Sort.NEWER_PRODUCT);

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Newest changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params);
            const oldParams = this.production.getParams(prevProps.match.params);
            const newParams = this.production.getParams(match.params);

            if (!prevProps.loaded || !Utils.areObjectsEqual(oldParams, newParams)) {
                this.production.fetch();
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, error, elem                     } = this.props;
        const { category, subcategory, tercategory, page } = this.production.getParams();

        this.production.startRender(elem, Url.NEWESTS);
        this.production.addCrumb(Url.NEWESTS, "NEWESTS_NAME");
        this.production.addCategory(category);
        this.production.addSubcategory(subcategory);
        this.production.addTercategory(tercategory);

        return <ProductsContainer
            getUrl={this.production.createUrl}
            onChange={this.production.fetch}
            hasFilters={this.production.hasFilters()}
            loading={loading}
            error={error}
        >
            <Title message="NEWESTS_TITLE" />
            <ProductsOptions crumbs={this.production.crumbs} hideSort />
            <ProductsContent>
                <ProductsAside
                    categories={this.production.categories}
                    category={this.production.category}
                    subcategories={this.production.subcategories}
                    subcategory={this.production.subcategory}
                    tercategories={this.production.tercategories}
                    tercategory={this.production.tercategory}
                    prices={elem.prices}
                />
                <ProductsList
                    products={elem.products}
                    pages={elem.pages}
                    page={page}
                    url={this.production.baseUrl}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchNewests : PropTypes.func.isRequired,
        settings     : PropTypes.object.isRequired,
        loaded       : PropTypes.bool.isRequired,
        preferences  : PropTypes.object.isRequired,
        loading      : PropTypes.bool.isRequired,
        error        : PropTypes.bool.isRequired,
        elem         : PropTypes.object.isRequired,
        match        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings    : state.core.settings,
            loaded      : state.store.loaded,
            preferences : state.store.preferences,
            loading     : state.product.loading,
            error       : state.product.error,
            elem        : state.product.newests,
        };
    }
}

export default connect(NewestPage.mapStateToProps, {
    fetchNewests,
})(NewestPage);
