import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import ProductPrice         from "Components/Product/Item/ProductPrice";

// Styles
import "Styles/Components/Product/Item/ProductVariants.css";



/**
 * The Product Variants
 */
class ProductVariants extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, className, data } = this.props;
        const { hasVariants, variants     } = data;

        const hasPrice = settings.showPrice;

        if (!hasVariants) {
            return <React.Fragment />;
        }
        return <ul className={`no-list product-variants ${className}`}>
            {variants.map((subelem) => <li key={subelem.variantID}>
                <h4>{subelem.name}</h4>
                {hasPrice && <ProductPrice
                    className="product-card-price"
                    data={subelem}
                />}
            </li>)}
        </ul>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings  : PropTypes.object.isRequired,
        data      : PropTypes.object.isRequired,
        className : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(ProductVariants.mapStateToProps)(ProductVariants);
