import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPage }          from "Actions/Client/ClientActions";
import { fetchOrders }      from "Actions/Client/OrderActions";
import NLS                  from "Utils/App/NLS";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import SubTitle             from "Components/Utils/Common/SubTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";

// Styles
import "Styles/Components/Client/Orders.css";



/**
 * The Order List
 */
class OrderList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchOrders(params);
        this.props.setPage(params.page);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);
        
        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchOrders(newParams);
            this.props.setPage(newParams.page);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return { page : Number(params.page) || 1 };
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, loading, list, total, currencies } = this.props;
        const { page                                       } = this.getParams();
        
        const multiCoins = currencies.length > 1;

        return <>
            <SubTitle show={!isMobile} message="ORDERS_NAME" icon="cart" />
            <Table
                none="ORDERS_NONE_AVAILABLE"
                loading={loading}
                hasContent={!!list.length}
                hasSpacing
            >
                <TableHead>
                    <TableCell message="ORDERS_DATE"          />
                    <TableCell message="GENERAL_STATUS"       />
                    <TableCell message="ORDERS_SHIPPING_TYPE" />
                    <TableCell message="ORDERS_PAYMENT_TYPE"  />
                    <TableCell message="ORDERS_AMOUNT"        align="center" />
                    {multiCoins ? currencies.map((currency) => <TableCell
                        key={currency.currencyID}
                        message={NLS.format("ORDERS_TOTAL_FOR", currency.symbol)}
                        align="right"
                        nowrap
                    />) : <TableCell message="ORDERS_TOTAL" align="right" />}
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow
                        key={elem.orderID}
                        href={`${Url.ORDERS_VIEW}/${elem.orderID}`}
                    >
                        <TableCell message={elem.orderDate}    nowrap breakAfter />
                        <TableCell message={elem.statusName}   color={elem.statusColor} breakAfter />
                        <TableCell message={elem.shipmentName} breakAfter />
                        <TableCell message={elem.paymentName}  breakAfter />
                        <TableCell message={elem.items.length} align="center" hide />
                        {currencies.map((currency) => {
                            const total = elem.total[currency.currencyID];
                            return <TableCell
                                key={currency.currencyID}
                                message={total ? `${total.currencySymbol} ${total.finalFormat}` : ""}
                                align="right"
                                nowrap
                            />;
                        })}
                    </TableRow>)}
                </TableBody>
            </Table>
            <Pagination
                current={page}
                total={total}
                url={Url.ORDERS}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOrders : PropTypes.func.isRequired,
        setPage     : PropTypes.func.isRequired,
        match       : PropTypes.object.isRequired,
        isMobile    : PropTypes.bool.isRequired,
        loading     : PropTypes.bool.isRequired,
        list        : PropTypes.array.isRequired,
        total       : PropTypes.number.isRequired,
        currencies  : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile   : state.core.isMobile,
            loading    : state.order.loading,
            list       : state.order.list,
            total      : state.order.total,
            currencies : state.order.currencies,
        };
    }
}

export default connect(OrderList.mapStateToProps, {
    fetchOrders, setPage,
})(OrderList);
