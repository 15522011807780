import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import DateTime             from "Utils/Common/DateTime";
import Utils                from "Utils/Common/Utils";

// Components
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Price                from "Components/Utils/Common/Price";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    unconfirmToProducts,
} from "Actions/Store/CartActions";



/**
 * The Cart Summary
 */
class CartSummary extends React.Component {
    // The Current State
    state = {
        reservedDate : 0,
        reservedMins : 0,
        reservedSecs : 0,
        reservedOver : false,
    }

    /**
     * Set the Last Save and Interval
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.elem.reservedTime) {
            const reservedDate = new DateTime(this.props.elem.reservedTime);
            const reservedMins = reservedDate.getMinutesDiff();
            const reservedSecs = reservedDate.getSecondsDiff();
            const reservedOver = !reservedDate.isGreaterThan();
            this.setState({ reservedDate, reservedMins, reservedSecs, reservedOver });

            if (!reservedOver) {
                this.interval = window.setInterval(this.setReservedTime, 1000);
            } else if (!this.props.elem.isProducts) {
                this.unconfirmCart();
            }
        }
    }

    /**
     * Remove the Interval
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.interval) {
            window.clearInterval(this.interval);
        }
    }

    /**
     * Set the Last Save
     * @returns {Void}
     */
    setReservedTime = () => {
        const reservedMins = this.state.reservedDate.getMinutesDiff();
        const reservedSecs = this.state.reservedDate.getSecondsDiff();
        const reservedOver = !this.state.reservedDate.isGreaterThan();
        this.setState({ reservedMins, reservedSecs, reservedOver });
        
        if (reservedOver && !this.props.elem.isProducts) {
            this.unconfirmCart();
        }
    }

    /**
     * Unconfirms the Cart
     * @returns {Void}
     */
    async unconfirmCart() {
        const { elem, openAlert, unconfirmToProducts } = this.props;
        try {
            openAlert("", "CART_RESERVED_NONE");
            await unconfirmToProducts(elem.orderHash);
        } catch (errors) {
            openAlert("", errors.form);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, elem, submit, isDisabled, onSubmit, cancel, onCancel, cancelUrl } = this.props;
        const { reservedMins, reservedSecs, reservedOver                                  } = this.state;
        const {
            hasDiscount, totals,
            shipments, shipmentType,
            subsidiaries, subsidiaryID,
            addresses, addressID,
            payments, paymentType,
            reservedTime,
        } = elem;
        
        const multiCoins    = totals.length > 1;
        const shipment      = shipmentType ? Utils.getData(shipments,    "type",         shipmentType) : {};
        const subsidiary    = subsidiaryID ? Utils.getData(subsidiaries, "subsidiaryID", subsidiaryID) : {};
        const address       = addressID    ? Utils.getData(addresses,    "addressID",    addressID)    : {};
        const payment       = paymentType  ? Utils.getData(payments,     "type",         paymentType)  : {};

        const hasShipment   = !Utils.isEmpty(shipment);
        const hasSubsidiary = !Utils.isEmpty(subsidiary) && shipment.reqSubsidiary;
        const hasAddress    = !Utils.isEmpty(address)    && shipment.reqAddress;
        const hasPayment    = !Utils.isEmpty(payment);
        const showFinal     = shipment.reqPricing || hasDiscount;
        const hasTimeMins   = Boolean(reservedTime && !reservedOver && reservedMins > 1);
        const hasTimeSecs   = Boolean(reservedTime && !reservedOver && reservedMins <= 1);
        const showInfo      = Boolean(hasTimeMins || hasTimeSecs || !settings.hasProductIVA);
        
        return <section className="cart-summary">
            <Card withBorder>
                <SubTitle message="CART_SUMMARY" icon="cart" smallPadding />
                {totals.map((elem) => <div key={elem.id} className="cart-summary-items">
                    <section className="cart-summary-item">
                        {multiCoins ?
                            <h4>{NLS.format("CART_TOTAL", elem.name)}</h4> :
                            <h4>{NLS.pluralize("CART_UNITS", elem.amount)}</h4>}
                        <ProductPrice data={elem.totalPrice} />
                    </section>
                    {elem.hasDiscount && <section className="cart-summary-item cart-summary-small">
                        <h4>{NLS.format("CART_DISCOUNT", elem.discountPercent)}</h4>
                        <Price symbol={elem.currencySymbol} price={`-${elem.discountPrice}`} />
                    </section>}
                    {shipment.reqPricing && elem.hasShipment && <section className="cart-summary-item cart-summary-small">
                        <h4>{NLS.get("CART_SHIPMENT_PRICE")}</h4>
                        <Price symbol={elem.currencySymbol} price={elem.shipmentFormat} />
                    </section>}
                    {showFinal && elem.hasFinal && <section className="cart-summary-item">
                        <h4>{NLS.get("CART_FINAL_PRICE")}</h4>
                        <Price symbol={elem.currencySymbol} price={elem.finalFormat} />
                    </section>}
                </div>)}

                {hasShipment && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_SHIPMENT_SELECTED")}</h4>
                    <p>
                        {shipment.name}
                    </p>
                </section>}

                {hasSubsidiary && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_SUBSIDIARY_SELECTED")}</h4>
                    <p>{subsidiary.name}</p>
                </section>}

                {hasAddress && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_ADDRESS_SELECTED")}</h4>
                    <p>{address.name}</p>
                </section>}

                {hasPayment && <section className="cart-summary-item cart-summary-type">
                    <h4>{NLS.get("CART_PAYMENT_SELECTED")}</h4>
                    <p>{payment.name}</p>
                </section>}

                <footer className="cart-summary-footer">
                    <Button
                        variant="primary"
                        message={submit}
                        isDisabled={isDisabled}
                        onClick={onSubmit}
                        fullWidth
                    />
                    <Button
                        variant="cancel"
                        message={cancel}
                        onClick={onCancel}
                        href={cancelUrl}
                        fullWidth
                    />
                </footer>
            </Card>

            {showInfo && <Card className="cart-summary-info" withBorder>
                {hasTimeMins && <p className="cart-summary-time">
                    {NLS.pluralize("CART_RESERVED_MINS", reservedMins)}
                </p>}
                {hasTimeSecs && <p className="cart-summary-time">
                    {NLS.pluralize("CART_RESERVED_SECS", reservedSecs)}
                </p>}
                {!settings.hasProductIVA && <p>{NLS.get("PRODUCTS_NO_IVA")}</p>}
            </Card>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmToProducts : PropTypes.func.isRequired,
        openAlert           : PropTypes.func.isRequired,
        settings            : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
        submit              : PropTypes.string.isRequired,
        cancel              : PropTypes.string.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        onCancel            : PropTypes.func,
        cancelUrl           : PropTypes.string,
        isDisabled          : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            elem     : state.cart.elem,
        };
    }
}

export default connect(CartSummary.mapStateToProps, {
    unconfirmToProducts,
})(CartSummary);
