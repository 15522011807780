import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";



/**
 * The Status
 */
class Status extends React.Component {
    // The Styles
    success = { color : "green" }
    error   = { color : "var(--red-color)" }
    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { condition, success, error } = this.props;
        
        if (condition) {
            return <span style={this.success}>{NLS.get(success)}</span>;
        }
        return <span style={this.error}>{NLS.get(error)}</span>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        condition : PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
        success   : PropTypes.string.isRequired,
        error     : PropTypes.string.isRequired,
    }
}

export default Status;
