import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import Alert                from "Components/Utils/Form/Alert";
import TextField            from "Components/Utils/Form/TextField";
import Columns              from "Components/Utils/Form/Columns";



/**
 * The Form
 */
class Form extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, className, columns, success, error, onSubmit, onClose, children } = this.props;
        let isFirst = true;
        const childrenWithProps = React.Children.map(children, (child) => {
            if (child) {
                const canFocus  = !isApp && (child.type === Columns || (child.type === TextField && child.props.show && !child.props.isDisabled));
                const autoFocus = canFocus && isFirst;
                const result    = React.cloneElement(child, { onSubmit, autoFocus });
                if (canFocus) {
                    isFirst = false;
                }
                return result;
            }
            return null;
        });

        return <form className={className} onSubmit={onSubmit} noValidate>
            <Alert variant="success" message={success} onClose={onClose} />
            <Alert variant="error"   message={error}   onClose={onClose} />

            {columns ? <Columns amount={columns}>
                {childrenWithProps}
            </Columns> : childrenWithProps}
        </form>;
    }



    /**
     * The Property Types
     * @type {Object} propTypes
     */
    static propTypes = {
        isApp     : PropTypes.bool.isRequired,
        className : PropTypes.string,
        columns   : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        success   : PropTypes.string,
        error     : PropTypes.string,
        onSubmit  : PropTypes.func,
        onClose   : PropTypes.func,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp : state.core.isApp,
        };
    }
}

export default connect(Form.mapStateToProps)(Form);
