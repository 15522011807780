import API                  from "Utils/App/API";

// Types
import {
    BRAND_LOADING, BRAND_LIST, BRAND_ELEM,
} from "Utils/Types";



/**
 * Fetches the Brand List
 * @returns {Function}
 */
export function fetchBrands() {
    return async (dispatch) => {
        dispatch({ type : BRAND_LOADING });
        const data = await API.Brand.getBrands();
        dispatch({ type : BRAND_LIST, data });
    };
}

/**
 * Fetches a Brand Elem
 * @param {Object} params
 * @returns {Function}
 */
export function fetchBrand(params) {
    return async (dispatch) => {
        dispatch({ type : BRAND_LOADING });
        const data = await API.Brand.getBrand(params);
        dispatch({ type : BRAND_ELEM, data });
    };
}
