import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setProduct }       from "Actions/Store/ProductActions";
import NLS                  from "Utils/App/NLS";

// Components
import ProductCard          from "Components/Product/Item/ProductCard";
import Button               from "Components/Utils/Form/Button";



/**
 * The Home Products
 */
class HomeProducts extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, variant, message, href, list, setProduct } = this.props;

        return <div className={`home-products home-${variant}`}>
            <header className="home-header">
                <h2>{NLS.get(message)}</h2>
                <Button variant="primary" href={href} message="HEADER_MORE" />
            </header>
            <div className={"home-products-list" + (settings.lessProductsMobile ? " home-products-less" : "")}>
                {list.map((elem) => <ProductCard
                    key={elem.productID}
                    data={elem}
                    onClick={() => setProduct(elem)}
                />)}
            </div>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setProduct : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        variant    : PropTypes.string.isRequired,
        message    : PropTypes.string.isRequired,
        href       : PropTypes.string.isRequired,
        list       : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(HomeProducts.mapStateToProps, {
    setProduct,
})(HomeProducts);
