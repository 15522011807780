import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";

// Components
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Actions
import {
    recover, setFocused,
} from "Actions/Core/AuthActions";



/**
 * The Recover Page
 */
class RecoverPage extends React.Component {
    // The Current State
    state = {
        data    : { identifier : "" },
        loading : false,
        errors  : {},
        error   : "",
    }
    


    /**
     * Handles the Focus Change
     * @param {Boolean} isFocused
     * @returns {Void}
     */
    handleFocus = (isFocused) => {
        if (this.props.isApp) {
            this.props.setFocused(isFocused);
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Does a Submit on Touch
     * @param {Event} e
     * @returns {Void}
     */
    handleTouch = (e) => {
        if (this.props.isFocused && !this.state.loading) {
            document.activeElement.blur();
            this.handleSubmit(e);
        }
    }
    
    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { history, recover } = this.props;
        const { data, loading    } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, errors : {}, error : "" });
        try {
            await recover(data);
            history.push(Url.RESET);
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }
    


    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors, error } = this.state;
        const { settings } = this.props;
        
        const helper          = settings.hasClientCodes ? "AUTH_RECOVER_CODE" : "AUTH_RECOVER_EMAIL";
        const identifierLabel = settings.hasClientCodes ? "AUTH_LOGIN_CODE"   : "AUTH_LOGIN_EMAIL";
        const identifierError = errors.identifier ? (settings.hasClientCodes ? "AUTH_ERROR_CODE" : "AUTH_ERROR_EMAIL") : "";

        return <div className="auth-main auth-recover">
            <h2>{NLS.get("AUTH_RECOVER_NAME")}</h2>
            <p>{NLS.get(helper)}</p>

            <Form
                className="auth-form"
                error={error}
                onSubmit={this.handleSubmit}
                onClose={this.closeAlert}
            >
                <TextField
                    name="identifier"
                    autoComplete="new-password"
                    label={identifierLabel}
                    value={data.identifier}
                    error={identifierError}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    isRequired
                    withMargin
                    shrink
                />
                <Button
                    variant="primary"
                    message="AUTH_RECOVER_ACTION"
                    onTouchEnd={this.handleTouch}
                    isDisabled={loading}
                    fullWidth
                />
            </Form>

            <p className="auth-link-route">
                <HyperLink
                    className="auth-link-anchor"
                    href={Url.LOGIN}
                    variant="black"
                    message="AUTH_LOGIN_LINK"
                />
            </p>
        </div>;
    }


    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history    : PropTypes.object.isRequired,
        recover    : PropTypes.func.isRequired,
        setFocused : PropTypes.func.isRequired,
        isApp      : PropTypes.bool.isRequired,
        settings   : PropTypes.object.isRequired,
        isFocused  : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp     : state.core.isApp,
            settings  : state.core.settings,
            isFocused : state.auth.isFocused,
        };
    }
}

export default withRouter(connect(RecoverPage.mapStateToProps, {
    recover, setFocused,
})(RecoverPage));
