import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchQueries }     from "Actions/Client/QueryActions";
import { setPage }          from "Actions/Client/ClientActions";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import SubTitle             from "Components/Utils/Common/SubTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Client/Queries.css";



/**
 * The Query List
 */
class QueryList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const params = this.getParams();
        this.props.fetchQueries(params);
        this.props.setPage(params.page, params.type);
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);
        
        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.props.fetchQueries(newParams);
            this.props.setPage(newParams.page, newParams.type);
        }
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return {
            type : params.type         || "abiertas",
            page : Number(params.page) || 1,
        };
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, loading, list, total, hasTypes } = this.props;
        const { type, page                               } = this.getParams();
        
        const openType  = type === "abiertas" ? "border" : "cancel";
        const closeType = type !== "abiertas" ? "border" : "cancel";

        return <>
            <SubTitle show={!isMobile} message="QUERIES_TITLE" icon="query" />
            <header className="queries-header">
                <div className="queries-tabs">
                    <Button
                        variant={openType}
                        className="queries-open"
                        message="QUERIES_OPEN"
                        href={Url.QUERIES_OPEN}
                    />
                    <Button
                        variant={closeType}
                        className="queries-closed"
                        message="QUERIES_CLOSED"
                        href={Url.QUERIES_CLOSED}
                    />
                </div>
                <div className="queries-create">
                    <Button
                        variant="primary"
                        message="QUERIES_CREATE"
                        href={Url.QUERIES_CREATE}
                    />
                </div>
            </header>
            
            <Table
                none="QUERIES_NONE_AVAILABLE"
                loading={loading}
                hasContent={!!list.length}
                hasSpacing
            >
                <TableHead>
                    <TableCell message="QUERIES_SUBJECT"    />
                    <TableCell message="QUERIES_TYPE"       isHidden={!hasTypes} />
                    <TableCell message="QUERIES_LAST_REPLY" />
                    <TableCell message="GENERAL_STATUS"     />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow
                        key={elem.queryID}
                        href={`${Url.QUERIES_VIEW}/${elem.queryID}`}
                    >
                        <TableCell message={elem.subject} isBold breakAfter />
                        <TableCell message={elem.queryTypeName} isHidden={!hasTypes} />
                        <TableCell
                            className="table-terciary table-nowrap"
                            message={elem.lastReplyDate}
                        />
                        <TableCell
                            isStatus
                            condition={!elem.unread}
                            success="QUERIES_READ"
                            error="QUERIES_UNREAD"
                        />
                    </TableRow>)}
                </TableBody>
            </Table>
            <Pagination
                current={page}
                total={total}
                url={`${Url.QUERIES}/${type}`}
            />

            <Button
                variant="floating"
                className="queries-floating"
                message="+"
                href={Url.QUERIES_CREATE}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchQueries : PropTypes.func.isRequired,
        setPage      : PropTypes.func.isRequired,
        match        : PropTypes.object.isRequired,
        isMobile     : PropTypes.bool.isRequired,
        loading      : PropTypes.bool.isRequired,
        list         : PropTypes.array.isRequired,
        total        : PropTypes.number.isRequired,
        hasTypes     : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile : state.core.isMobile,
            loading  : state.query.loading,
            list     : state.query.list,
            total    : state.query.total,
            hasTypes : state.query.hasTypes,
        };
    }
}

export default connect(QueryList.mapStateToProps, {
    fetchQueries, setPage,
})(QueryList);
