import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Dialog Footer
 */
class DialogFooter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, children } = this.props;

        return <footer className={`dialog-footer ${className}`}>
            {children}
        </footer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className : PropTypes.string,
        children  : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default DialogFooter;
