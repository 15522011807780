import ReactGA              from "react-ga";
import TagManager           from "react-gtm-module";
import ReactPixel           from "react-facebook-pixel";

// Variables
let initialized   = false;
let hasAnalitics  = false;
let hasEC         = false;
let hasTagManager = false;
let hasPixel      = false;



/**
 * Initializes the Metrics
 * @param {Object} settings
 * @returns {Void}
 */
function init(settings) {
    if (initialized) {
        return;
    }

    if (settings.gaTrackingID) {
        ReactGA.initialize(settings.gaTrackingID);
        ReactGA.pageview(window.location.pathname);
        ReactGA.plugin.require("ec");
        hasAnalitics = true;
        hasEC        = true;
    }
    if (settings.gtmTrackingID) {
        TagManager.initialize({ gtmId : settings.gtmTrackingID });
        hasTagManager = true;
    }
    if (settings.pixelTrackingID) {
        ReactPixel.init(settings.pixelTrackingID);
        hasPixel = false;
    }

    initialized = true;
}

/**
 * Sends a Page View
 * @param {String} path
 * @returns {Void}
 */
function pageView(path) {
    if (hasAnalitics) {
        ReactGA.pageview(path);
    }
    if (hasPixel) {
        ReactPixel.pageView();
    }
}



/**
 * Sends a Product
 * @param {String} action
 * @param {String} event
 * @param {Object} productData
 * @param {Number} quantity
 * @returns {Void}
 */
function sendProduct(action, event, productData, quantity) {
    const product = getProduct(productData, quantity);

    if (hasEC) {
        ReactGA.plugin.execute("ec", "addProduct", product);
        ReactGA.plugin.execute("ec", "setAction", action);
        ReactGA.ga("send", "event", "Ecommerce", event);
    }
    if (hasTagManager) {
        TagManager.dataLayer({
            dataLayer : {
                event     : event,
                ecommerce : {
                    [action] : { products : [ product ] },
                },
            },
        });
    }
}

/**
 * Gets the Product Data to send
 * @param {Object} data
 * @param {Number} quantity
 * @returns {Object}
 */
function getProduct(data, quantity) {
    const categories = [];
    if (data.categoryID) {
        categories.push(data.categoryName);
    }
    if (data.subcategoryID) {
        categories.push(data.subcategoryName);
    }
    if (data.tercategoryID) {
        categories.push(data.tercategoryName);
    }

    let price = "0";
    if (data.priceFormat) {
        price = data.priceFormat.replace(",", ".");
    }
    if (data.price.priceFormat) {
        price = data.price.priceFormat.replace(",", ".");
    }

    return {
        id       : String(data.productCode || data.productID),
        name     : data.name,
        price    : price,
        category : categories.join("/"),
        brand    : data.brandName || "",
        quantity : quantity,
    }
}



/**
 * Clicks on a Product
 * @param {Object} productData
 * @returns {Void}
 */
function productClick(productData) {
    sendProduct("click", "productClick", productData, 0);
}

/**
 * Adds a Product to the Cart
 * @param {Object} productData
 * @param {Number} quantity
 * @returns {Void}
 */
function addToCart(productData, quantity) {
    sendProduct("add", "addToCart", productData, quantity);
}

/**
 * Removes a Product from the Cart
 * @param {Object} productData
 * @param {Number} quantity
 * @returns {Void}
 */
function removeFromCart(productData, quantity) {
    sendProduct("remove", "removeFromCart", productData, quantity);
}

/**
 * Adds or Removes a Product from the Cart
 * @param {Object} product
 * @param {Number} quantity
 * @returns {Void}
 */
function editInCart(product, oldQuantity, newQuantity) {
    const quantity = Math.abs(newQuantity - oldQuantity);
    if (oldQuantity < newQuantity) {
        addToCart(product, quantity);
    } else {
        removeFromCart(product, quantity);
    }
}



/**
 * Checkout Steps
 * @param {Number}   step
 * @param {Object[]} productData
 * @returns {Void}
 */
function checkout(step, productData) {
    const products = productData.map((elem) => getProduct(elem, elem.amount));

    if (hasEC) {
        for (const product of products) {
            ReactGA.plugin.execute("ec", "addProduct", product);
        }
        ReactGA.plugin.execute("ec", "setAction", "checkout", { step });
        ReactGA.ga("send", "event", "Ecommerce", "checkout");
    }
    if (hasTagManager) {
        TagManager.dataLayer({
            dataLayer : {
                event     : "checkout",
                ecommerce : {
                    checkout : {
                        actionField : { step },
                        products,
                    },
                },
            },
        });
    }
}

/**
 * Purchase
 * @param {Number}   orderID
 * @param {Object[]} productData
 * @returns {Void}
 */
function purchase(orderID, productData) {
    const products = productData.map((elem) => getProduct(elem, elem.amount));

    if (hasEC) {
        for (const product of products) {
            ReactGA.plugin.execute("ec", "addProduct", product);
        }
        ReactGA.plugin.execute("ec", "setAction", "purchase", { id : orderID });
        ReactGA.ga("send", "event", "Ecommerce", "purchase");
    }
}




// The Public API
export default {
    stepProducts : 1,
    stepShipment : 2,
    stepPayment  : 3,

    init,
    pageView,
    productClick,
    addToCart,
    removeFromCart,
    editInCart,
    checkout,
    purchase,
};
