import React                from "react";
import PropTypes            from "prop-types";

// Styles
import "Styles/Components/Utils/Common/Price.css";



/**
 * The Price
 */
class Price extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, symbol, price } = this.props;

        return <div className={`price ${className}`}>
            <span className="price-symbol">{symbol}</span>
            {price}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className : PropTypes.string,
        symbol    : PropTypes.string.isRequired,
        price     : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }
}

export default Price;
