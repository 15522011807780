import Utils                from "Utils/Common/Utils";

// Types
import {
    BRAND_LOADING, BRAND_LIST, BRAND_ELEM,
    STORE_FAVORITE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading : true,
    error   : false,
    list    : [],
    elem    : {
        name          : "GENERAL_LOADING",
        url           : "",
        categories    : [],
        subcategories : [],
        products      : [],
        results       : 0,
        total         : 0,
        pages         : 1,
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, BRAND_LIST, BRAND_ELEM)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case BRAND_LOADING:
        return {
            ...state,
            loading : true,
        };
    
    case BRAND_LIST:
        return {
            ...state,
            loading : false,
            error   : false,
            list    : action.data.list,
        };
    case BRAND_ELEM:
        return {
            ...state,
            loading : false,
            error   : false,
            elem    : action.data,
        };

    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error   : false,
            elem    : Utils.setFavorite(state.elem, action.data.productID),
        };
    
    default:
        return state;
    }
};
