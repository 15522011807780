import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { fetchHome }        from "Actions/Content/HomeActions";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import HomeProducts         from "./HomeProducts";
import ProductBanner        from "Components/Product/Item/ProductBanner";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Slider               from "Components/Utils/Common/Slider";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Store/Home.css";



/**
 * The Home Page
 */
class HomePage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchHome();
    }

    /**
     * Goes to the Url
     * @param {Object} elem
     * @returns {Void}
     */
    handleClick = (elem) => {
        if (elem.url) {
            Utils.handleHref(elem.url, this.props.history);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMicro, settings, isAuthenticated, data } = this.props;
        const {
            productLink, chatLink,
            slides, lightnings, promotions, news,
            categories, newests, importants, purchases, favorites, history,
        } = data;
        
        const showSlider     = Boolean(slides.length);
        const showProducts   = Boolean(productLink && (settings.isOpen || isAuthenticated) && settings.allowCart);
        const showChat       = Boolean(chatLink && settings.hasChat && isAuthenticated);
        const showButtons    = Boolean(showProducts || showChat);
        const showLightnings = Boolean(lightnings.length);
        const showPromotions = Boolean(promotions.length);
        const showNews       = Boolean(news.length);
        const showCategories = Boolean(categories.length);
        const showNewests    = Boolean(newests.length    >= 2);
        const showImportants = Boolean(importants.length >= 1);
        const showPurchases  = Boolean(purchases.length  >= 1);
        const showFavorites  = Boolean(favorites.length  >= 2);
        const showHistory    = Boolean(history.length    >= 2);

        for (const elem of slides) {
            elem.image   = isMicro ? elem.mobileFile    : elem.desktopFile;
            elem.isVideo = isMicro ? elem.isMobileVideo : elem.isDesktopVideo;
        }
        for (const elem of news) {
            elem.image   = isMicro ? elem.mobileImageUrl : elem.desktopImageUrl;
        }

        return <div className="home-container">
            {showSlider && <Slider
                className="home-slider"
                data={slides}
                heights={[ settings.slidesHeight1, settings.slidesHeight2, settings.slidesHeight3 ]}
                onClick={this.handleClick}
                autoSlide
                withDots
            />}
            <Wrapper className="home-content">
                {showButtons && <div className="home-buttons">
                    {showProducts && <Button
                        variant="primary"
                        href={Url.PRODUCTS}
                        message={productLink}
                    />}
                    {!!chatLink && <Button
                        variant="primary"
                        href={Url.CHAT}
                        message={chatLink}
                    />}
                </div>}

                {showLightnings && lightnings.map((elem) => <ProductBanner
                    key={elem.id}
                    className="home-banner"
                    data={elem}
                />)}

                {showPromotions && <section className="home-promotions">
                    {promotions.map((elem) => <HyperLink
                        key={elem.id}
                        variant="none"
                        href={`${Url.OFFERS}/${elem.id}`}
                    >
                        <img src={elem.image} alt={elem.name} />
                    </HyperLink>)}
                </section>}

                {showNews && <section className="home-news">
                    {news.map((elem) => <HyperLink
                        key={elem.newsID}
                        variant="none"
                        href={elem.link || `${Url.NEWS_VIEW}/${elem.newsID}`}
                    >
                        <img src={elem.image} alt={elem.title} />
                    </HyperLink>)}
                </section>}
                
                {showNewests && <HomeProducts
                    variant="newests"
                    message="NEWESTS_TITLE"
                    href={Url.NEWESTS}
                    list={newests}
                />}
                {showImportants && <HomeProducts
                    variant="importants"
                    message="IMPORTANTS_TITLE"
                    href={Url.IMPORTANTS}
                    list={importants}
                />}
                {showPurchases && <HomeProducts
                    variant="purchases"
                    message="PURCHASES_TITLE"
                    href={Url.PURCHASES}
                    list={purchases}
                />}
                {showFavorites && <HomeProducts
                    variant="favorites"
                    message="FAVORITES_TITLE"
                    href={Url.FAVORITES}
                    list={favorites}
                />}
                {showCategories && categories.map((elem) => <HomeProducts
                    key={elem.id}
                    variant="categories"
                    message={elem.name}
                    href={`${Url.PRODUCTS}/${elem.url}`}
                    list={elem.products}
                />)}
                {showHistory && <HomeProducts
                    variant="history"
                    message="HISTORY_NAME"
                    href={Url.HISTORY}
                    list={history}
                />}
            </Wrapper>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history         : PropTypes.object.isRequired,
        fetchHome       : PropTypes.func.isRequired,
        isMicro         : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        data            : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMicro         : state.core.isMicro,
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
            data            : state.home,
        };
    }
}

export default withRouter(connect(HomePage.mapStateToProps, {
    fetchHome,
})(HomePage));
