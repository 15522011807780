import {
    CORE_DATA, CORE_CONFIG, CORE_PLATFORM, CORE_RESPONSIVE,
    CORE_LOADING, CORE_REDIRECT, CORE_SECTION, CORE_RESULT,
} from "Utils/Types";



// The initial State
const initialState = {
    isApp         : false,
    isCordova     : false,
    isDeviceReady : false,
    isMicro       : false,
    isMobile      : false,
    isTablet      : false,
    isLaptop      : false,
    platform      : "browser",

    loaded        : false,
    terms         : "",
    styles        : {},
    settings      : {},
    entities      : {
        ivaCategories : [],
        withCUIT      : [],
        provinces     : [],
    },

    loading       : true,
    redirect      : "/",
    section       : "",
    result        : {
        open    : false,
        type    : "",
        message : "",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case CORE_DATA:
        return {
            ...state,
            loaded        : true,
            terms         : action.data.terms  || "",
            styles        : action.data.styles || {},
            settings      : action.data.settings,
            entities      : action.data.entities,
        };
    case CORE_CONFIG:
        return {
            ...state,
            isApp         : action.data.isApp || action.data.isCordova,
            isCordova     : action.data.isCordova,
        };
    case CORE_PLATFORM:
        return {
            ...state,
            isDeviceReady : true,
            platform      : action.data,
        };
    case CORE_RESPONSIVE:
        return {
            ...state,
            isMicro       : action.data.isMicro,
            isMobile      : action.data.isMobile,
            isTablet      : action.data.isTablet,
            isLaptop      : action.data.isLaptop,
        };
    
    case CORE_LOADING:
        return {
            ...state,
            loading : action.data,
        };
    case CORE_REDIRECT:
        return {
            ...state,
            redirect : action.data,
        };
    case CORE_SECTION:
        return {
            ...state,
            section : action.data,
        };
    case CORE_RESULT:
        return {
            ...state,
            result : action.data,
        };
    default:
        return state;
    }
};
