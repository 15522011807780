import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Url                  from "Utils/App/Url";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import Card                 from "Components/Utils/Common/Card";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Terms/Terms.css";



/**
 * The User Terms Page
 */
class TermsUser extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, terms } = this.props;
        const backUrl = isApp ? Url.ACCOUNT : "";

        return <>
            <Title message="TERMS_NAME" href={backUrl} />
            <Wrapper withSpacing>
                <Card className="terms-content" withSpacing withBorder>
                    <Html content={terms} />
                </Card>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isApp : PropTypes.bool.isRequired,
        terms : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp : state.core.isApp,
            terms : state.core.terms,
        };
    }
}

export default connect(TermsUser.mapStateToProps)(TermsUser);
