import ReactDOM             from "react-dom";



/**
 * Clamps a Value between the given Min and Max
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 * @returns {Number}
 */
function clamp(value, min, max) {
    return Math.max(min, Math.min(max, value));
}

/**
 * Returns true if the Object is empty
 * @param {Object} object
 * @returns {Boolean}
 */
function isEmpty(object) {
    return !object || Object.keys(object).length === 0;
}

/**
 * Clones an Object
 * @param {Object} object
 * @returns {Object}
 */
function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Returns true if both given objects are the same at 1 level deep
 * @param {Object} a
 * @param {Object} b
 * @returns {Boolean}
 */
function areObjectsEqual(a, b) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }
    for (let i = 0; i < aProps.length; i += 1) {
        if (a[aProps[i]] !== b[aProps[i]]) {
            return false;
        }
    }
    return true;
}

/**
 * Creates an Array of N numbers
 * @param {Number} amount
 * @returns {Array.<Number>}
 */
function createArrayOf(amount) {
    const result = [];
    for (let i = 1; i <= amount; i++) {
        result.push(i);
    }
    return result;
}

/**
 * Creates a Map from an Array
 * @param {Array.<Object>} request
 * @param {String}         id
 * @returns {Object.<Number, Object>}
 */
function createMap(request, id) {
    const result = {};
    for (const elem of request) {
        result[elem[id]] = elem;
    }
    return result;
}

/**
 * Returns the Data at the given id with the given value
 * @param {Array.<Object>} data
 * @param {String}         idKey
 * @param {Number}         idValue
 * @returns {Object}
 */
function getData(data, idKey, idValue) {
    for (const elem of data) {
        if (elem[idKey] === idValue) {
            return elem;
        }
    }
    return {};
}

/**
 * Returns the Index at the given id with the given value
 * @param {Array.<Object>} data
 * @param {String}         idKey
 * @param {Number}         idValue
 * @returns {Number}
 */
function getIndex(data, idKey, idValue) {
    for (const [ index, elem ] of data.entries()) {
        if (elem[idKey] === idValue) {
            return index;
        }
    }
    return 0;
}

/**
 * Returns the Value at the given id with the given key
 * @param {Array.<Object>} data
 * @param {String}         idKey
 * @param {Number}         idValue
 * @param {String}         key
 * @returns {String}
 */
function getValue(data, idKey, idValue, key) {
    const elem = getData(data, idKey, idValue);
    return elem[key];
}

/**
 * Uses the keys from primary and sets the secondary values if are set
 * @param {Object} primary
 * @param {Object} secondary
 * @returns {Object}
 */
function extend(primary, secondary) {
    const result = {};
    for (const [ key, value ] of Object.entries(primary)) {
        result[key] = secondary[key] !== undefined ? secondary[key] : value;
    }
    return result;
}



/**
 * Returns an ID depeding on the type
 * @param {Object}    action
 * @param {...String} types
 * @returns {Boolean}
 */
function hasError(action, ...types) {
    return types.indexOf(action.type) > -1 && (!action.data || action.data.error);
}

/**
 * Merges the View, Create and Edit into an Edit type
 * @param {String} type
 * @returns {String}
 */
function mergeVCE(type) {
    if (type === "view" || type === "create") {
        return "edit";
    }
    return type;
}

/**
 * Returns true if the given href is from the App
 * @param {String} href
 * @returns {Boolean}
 */
function hrefInApp(href) {
    return href && href.startsWith(process.env.REACT_APP_URL) && !href.startsWith(process.env.REACT_APP_VOUCHER);
}

/**
 * Returns the url without the complete path
 * @param {String} href
 * @returns {Boolean}
 */
function getUrl(href) {
    return href.replace(process.env.REACT_APP_URL, "");
}

/**
 * Handles the Href
 * @param {String} href
 * @param {Object} history
 * @returns {Void}
 */
function handleHref(href, history) {
    if (hrefInApp(href)) {
        history.push(getUrl(href));
    } else if (!href.startsWith("http")) {
        history.push(href);
    } else if (href) {
        window.location.href = href;
    }
}



/**
 * Returns the Bounds of the given Ref
 * @param {ReactRef} ref
 * @returns {Object}
 */
function getNode(ref) {
    if (ref && ref.current) {
        return ReactDOM.findDOMNode(ref.current);
    }
    return null;
}

/**
 * Returns true if the Target has the class
 * @param {(HTMLElement|EventTarget)} target
 * @param {String...}                 classes
 * @returns {Boolean}
 */
function hasClass(target, ...classes) {
    let elem = target;
    while (elem && elem.parentElement) {
        for (const className of classes) {
            if (elem.classList.contains(className)) {
                return true;
            }
        }
        elem = elem.parentElement;
    }
    return false;
}

/**
 * Returns the Current Top
 * @returns {Number}
 */
function getScrollTop() {
    const doc = document.documentElement;
    return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
}

/**
 * Clears the Selection
 * @returns {Void}
 */
function clearSelection() {
    if (window.getSelection) {
        if (window.getSelection().empty) {  // Chrome
            window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {  // Firefox
            window.getSelection().removeAllRanges();
        }
    } else if (document.selection) {  // IE?
        document.selection.empty();
    }
}



/**
 * Returns a CSS propert Name from the given String
 * @param {String} name
 * @returns {Boolean}
 */
function getPropertyName(name) {
    return "--" + name.replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
        return "-" + y.toLowerCase();
    }).replace(/^_/, "");
}

/**
 * Returns a CSS propert Name from the given String
 * @param {String} value
 * @returns {Boolean}
 */
function getPropertyValue(value) {
    if (Number.isInteger(value)) {
        return `${value}px`;
    }
    if (value.length === 6 && !value.startsWith("#") && !value.startsWith("rgb")) {
        return `#${value}`;
    }
    return value;
}

/**
 * Linkifies a Text
 * @param {String} text
 * @returns {String}
 */
function linkify(text) {
    // http://, https://, ftp://
    const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
    // www. sans http:// or https://
    const pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    // Email addresses
    const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

    return text
        .replace(urlPattern, '<a href="$&">$&</a>')
        .replace(pseudoUrlPattern, '$1<a href="http://$2">$2</a>')
        .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
}

/**
 * Updates the Favorite State of the Product
 * @param {Object} data
 * @param {Number} productID
 * @returns {Object}
 */
function setFavorite(data, productID) {
    if (data.products.length) {
        const products = setFavoriteList(data.products, productID);
        return { ...data, products };
    }
    return { ...data };
}

/**
 * Updates the Favorite State of the Product List
 * @param {Array.<Object>} list
 * @param {Number}         productID
 * @returns {Object}
 */
function setFavoriteList(list, productID) {
    if (list.length) {
        const result = [ ...list ];
        for (const product of list) {
            if (product.productID === productID) {
                product.isFavorite = !product.isFavorite;
            }
        }
        return result;
    }
    return list;
}




// The Public API
export default {
    clamp,
    isEmpty,
    clone,
    areObjectsEqual,
    createArrayOf,
    createMap,
    getData,
    getIndex,
    getValue,
    extend,
    hasError,
    mergeVCE,
    
    hrefInApp,
    getUrl,
    handleHref,

    getNode,
    hasClass,
    getScrollTop,
    clearSelection,

    getPropertyName,
    getPropertyValue,
    linkify,
    setFavorite,
    setFavoriteList,
};
