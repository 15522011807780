import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOrder }       from "Actions/Client/OrderActions";
import API                  from "Utils/App/API";
import NLS                  from "Utils/App/NLS";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableFoot            from "Components/Utils/Table/TableFoot";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Content              from "Components/Utils/Common/Content";
import Price                from "Components/Utils/Common/Price";
import HyperLink            from "Components/Utils/Common/HyperLink";
import MultiLine            from "Components/Utils/Common/MultiLine";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Client/Order.css";



/**
 * The Order Elem
 */
class OrderElem extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchOrder(this.props.match.params.order);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, loading, elem } = this.props;

        const message     = loading ? "ORDERS_SINGULAR" : NLS.format("ORDERS_TITLE", elem.orderID);
        const totals      = elem.totals.filter((elem) => elem.amount);
        const multiCoins  = elem.totals.length > 1;
        const statusStyle = elem.statusColor ? { color : elem.statusColor } : {};
        const boxes       = elem.hasBoxes ? elem.boxes.filter((elem) => !!elem.trackingID) : [];

        return <>
            <SubTitle message={message} icon="cart" />
            <Content loading={loading} hasContent withCard>
                <header className="order-header">
                    <div>
                        <b>{NLS.get("ORDERS_DATE")}</b>
                        {elem.orderDate}
                    </div>
                    <div>
                        <b>{NLS.get("GENERAL_STATUS")}</b>
                        <span style={statusStyle}>{elem.statusName}</span>
                    </div>
                </header>

                <main className="order-summary">
                    <section className="order-summary-order">
                        <SubTitle message="ORDERS_SUMMARY" icon="order" smallPadding />
                        {totals.map((elem) => <ul key={elem.currencySymbol} className="no-list">
                            <li className="order-summary-price">
                                {multiCoins ?
                                    <h4>{NLS.format("CART_TOTAL", elem.name)}</h4> :
                                    <h4>{NLS.pluralize("CART_UNITS", elem.amount)}</h4>}
                                <ProductPrice data={elem} />
                            </li>
                            {elem.hasClientDiscount && <li className="order-summary-discount">
                                <h4>{NLS.format("CART_DISCOUNT", elem.discountPercent)}</h4>
                                <Price symbol={elem.currencySymbol} price={`-${elem.discountAmount}`} />
                            </li>}
                            {elem.hasShipment && <li className="order-summary-discount">
                                <h4>{NLS.format("CART_SHIPMENT_PRICE", elem.discountPercent)}</h4>
                                <Price symbol={elem.currencySymbol} price={elem.shipmentFormat} />
                            </li>}
                            {elem.hasFinal && <li className="order-summary-price">
                                <h4>{NLS.get("CART_FINAL_PRICE")}</h4>
                                <Price symbol={elem.currencySymbol} price={elem.finalFormat} />
                            </li>}
                        </ul>)}
                    </section>

                    <section className="order-summary-shipping">
                        <SubTitle message="ORDERS_SHIPPING" icon="shipping" smallPadding />
                        <ul className="no-list">
                            <li>
                                <h4>{NLS.get("ORDERS_SHIPPING_TYPE")}</h4>
                                <p>{elem.shipmentName}</p>
                            </li>
                            {boxes.map((elem, index) => <li key={index}>
                                <h4>{NLS.format("ORDERS_SHIPPING_BOX", elem.boxName)}</h4>
                                <p>
                                    <HyperLink
                                        variant="black"
                                        href={`https://seguimiento.andreani.com/envio/${elem.trackingID}`}
                                        message={elem.trackingID}
                                        target="_blank"
                                    />
                                </p>
                            </li>)}
                            {elem.hasSubsidiary && <li className="order-summary-col">
                                <h4>{NLS.get("ORDERS_SUBSIDIARY")}</h4>
                                <p>
                                    <b>{elem.subsidiaryName}:</b>
                                    {`
                                        ${elem.subsidiaryAddress} -
                                        ${elem.subsidiaryLocality} -
                                        ${elem.subsidiaryProvince} -
                                        ${elem.subsidiaryZipCode}
                                    `}
                                </p>
                            </li>}
                            {elem.hasAddress && <li className="order-summary-col">
                                <h4>{NLS.get("ORDERS_ADDRESS")}</h4>
                                <p>
                                    <b>{elem.addressName}:</b>
                                    {`
                                        ${elem.street} ${elem.streetNumber} -
                                        ${elem.floor ? `Piso ${elem.floor} -` : ""}
                                        ${elem.appartment ? `Departamento ${elem.appartment} -` : ""}
                                        ${elem.locality} - ${elem.province} - ${elem.zipCode}
                                        ${elem.schedule ? `- Horario ${elem.schedule}` : ""}
                                    `}
                                </p>
                            </li>}
                        </ul>
                    </section>

                    <section className="order-summary-payment">
                        <SubTitle message="ORDERS_PAYMENT" icon="payment" smallPadding />
                        <ul className="no-list">
                            <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_TYPE")}</h4>
                                <p>{elem.paymentName}</p>
                            </li>
                            {!!elem.paymentMethod && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_METHOD")}</h4>
                                <p>{elem.paymentMethod}</p>
                            </li>}
                            {!!elem.paymentStatus && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_STATUS")}</h4>
                                <p>{elem.paymentStatusName}</p>
                            </li>}
                            {!!elem.paymentHours && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_TIME")}</h4>
                                <p>{NLS.pluralize("ORDERS_PAYMENT_HOURS", elem.paymentHours)}</p>
                            </li>}
                            {!!elem.paymentData && <li className="order-summary-col">
                                <h4>{NLS.get("ORDERS_PAYMENT_DATA")}</h4>
                                <Html content={elem.paymentData} />
                            </li>}
                            {!!elem.paymentTicket && <li>
                                <h4>{NLS.get("ORDERS_PAYMENT_TICKET")}</h4>
                                <p>
                                    <HyperLink
                                        variant="black"
                                        href={API.Order.getTicket({ orderID : elem.orderID })}
                                        message="ORDERS_PAYMENT_TICKET_VIEW"
                                        target="_blank"
                                    />
                                </p>
                            </li>}
                        </ul>
                    </section>
                </main>

                {elem.products.map(({ list, total }, index) => <div
                    key={index}
                    className="order-products"
                >
                    <SubTitle
                        message={multiCoins ? NLS.format("ORDERS_PRODUCTS", total.currencySymbol) : "PRODUCTS_NAME"}
                        icon="cart"
                        withTop
                    />
                    <Table
                        hasContent={!!list.length}
                        className="order-table"
                        none="ORDERS_NONE_AVAILABLE"
                        noHover
                    >
                        <TableHead>
                            <TableCell message="ORDERS_PRODUCT" />
                            <TableCell message="" />
                            <TableCell message="ORDERS_AMOUNT"   align="center" />
                            <TableCell message="ORDERS_PRICE"    align="right"  />
                            <TableCell message="ORDERS_SUBTOTAL" align="right"  />
                        </TableHead>
                        <TableBody>
                            {list.map((elem, index) => <TableRow key={index}>
                                <TableCell className="order-table-image">
                                    <ProductImage
                                        className="order-image"
                                        variant="small"
                                        data={elem}
                                        clickPreview
                                    />
                                </TableCell>
                                <TableCell className="order-table-info">
                                    <header>
                                        {settings.showCode && !!elem.productCode && <h4>{elem.productCode}</h4>}
                                        <h3>{elem.name}</h3>
                                        {!!elem.variantName && <p>{elem.variantName}</p>}
                                    </header>
                                    {!!elem.campaignID && <p className="order-campaign">
                                        {elem.campaignName}
                                    </p>}
                                </TableCell>
                                <TableCell message={elem.amount} className="order-big" align="center" />
                                <TableCell className="order-big order-subtotal" align="right" nowrap>
                                    <Price symbol={elem.currencySymbol} price={elem.realPrice} />
                                </TableCell>
                                <TableCell className="order-big order-total" align="right" nowrap>
                                    <Price symbol={elem.currencySymbol} price={elem.subtotal} />
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                        <TableFoot>
                            <TableCell />
                            <TableCell message="ORDERS_TOTAL" align="right" />
                            <TableCell message={total.amount} className="order-big" align="center" />
                            <TableCell />
                            <TableCell className="order-big" align="right" nowrap>
                                <Price symbol={total.currencySymbol} price={total.priceFormat} />
                            </TableCell>
                        </TableFoot>
                    </Table>
                </div>)}
                {!settings.hasProductIVA && <div className="order-legals">
                    {NLS.get("PRODUCTS_NO_IVA")}
                </div>}

                {!!elem.message && <div className="order-message">
                    <SubTitle message="ORDERS_MESSAGE" icon="query" withTop />
                    <MultiLine>{elem.message}</MultiLine>
                </div>}
            </Content>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOrder : PropTypes.func.isRequired,
        match      : PropTypes.object.isRequired,
        settings   : PropTypes.object.isRequired,
        loading    : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            loading  : state.order.loading,
            elem     : state.order.elem,
        };
    }
}

export default connect(OrderElem.mapStateToProps, {
    fetchOrder,
})(OrderElem);
