import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Styles
import "Styles/Components/Utils/Common/Title.css";



/**
 * The Title
 */
class Title extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, className, message, submessage, href                        } = this.props;
        const { titleImage, titleCenter, titleBottomSpace, titleShadows, titleOverlay } = settings;

        const headerStyle = {};
        if (titleImage) {
            headerStyle.backgroundImage = `url(${titleImage})`;
        }

        const classes = new ClassList("title-container", className);
        classes.addIf("title-space",   titleBottomSpace);
        classes.addIf("title-shadows", titleShadows);
        classes.addIf("title-center",  titleCenter);

        return <header className={classes.get()} style={headerStyle}>
            {titleOverlay && <div className="title-overlay" />}
            <Wrapper className="title-wrapper">
                {!!href && <HyperLink variant="icon" icon="back" href={href} />}
                <div className="title-content">
                    <h2>{NLS.get(message)}</h2>
                    {submessage && <h3>{submessage}</h3>}
                </div>
            </Wrapper>
        </header>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings   : PropTypes.object.isRequired,
        className  : PropTypes.string,
        message    : PropTypes.string.isRequired,
        submessage : PropTypes.string,
        href       : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
        };
    }
}

export default connect(Title.mapStateToProps)(Title);
