import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setProduct }       from "Actions/Store/ProductActions";
import Url                  from "Utils/App/Url";

// Components
import ProductImage         from "Components/Product/Item/ProductImage";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    toggleFavorite, toggleMenu,
} from "Actions/Store/StoreActions";



/**
 * The Favorite Menu
 */
class FavoriteMenu extends React.Component {
    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, isAuthenticated, favorites, toggleMenu } = this.props;
        if (isAuthenticated && favorites.length && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Opens the Product Dialog
     * @param {Object} product
     * @returns {Function}
     */
    openProduct = (product) => () => {
        this.props.setProduct(product);
        this.props.onClose();
    }

    /**
     * Handles the Favorite Enabled
     * @param {Object} elem
     * @returns {Function}
     */
    handleDelete = (elem) => () => {
        const { favorites, toggleFavorite, onClose } = this.props;
        toggleFavorite(elem.productID);
        if (favorites.length <= 1) {
            onClose();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, isAuthenticated, settings, favorites, onEnter, onLeave, onClose } = this.props;

        if (!open || !isAuthenticated || !favorites.length) {
            return <React.Fragment />;
        }
        return <nav
            className={`submenu-favorite submenu-secondary ${open && "submenu-open"}`}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="no-list">
                {favorites.map((elem) => <li key={elem.productID} onClick={this.openProduct(elem)}>
                    <HyperLink
                        variant="secondary"
                        className="submenu-favorite-delete"
                        message="GENERAL_DELETE"
                        onClick={this.handleDelete(elem)}
                    />
                    <ProductImage
                        className="submenu-favorite-image"
                        variant="small"
                        data={elem}
                    />
                    <div className="submenu-favorite-product">
                        {settings.showCode && !!elem.productCode && <h4>{elem.productCode}</h4>}
                        <h3>{elem.name}</h3>
                    </div>
                </li>)}
                <li className="submenu-button">
                    <Button
                        variant="primary"
                        message="FAVORITES_TITLE"
                        href={Url.FAVORITES}
                        onClick={onClose}
                        fullWidth
                    />
                </li>
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setProduct      : PropTypes.func.isRequired,
        toggleMenu      : PropTypes.func.isRequired,
        toggleFavorite  : PropTypes.func.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        settings        : PropTypes.object.isRequired,
        favorites       : PropTypes.array.isRequired,
        open            : PropTypes.bool.isRequired,
        onEnter         : PropTypes.func.isRequired,
        onLeave         : PropTypes.func.isRequired,
        onClose         : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            settings        : state.core.settings,
            favorites       : state.store.favorites,
        };
    }
}

export default connect(FavoriteMenu.mapStateToProps, {
    setProduct, toggleFavorite, toggleMenu,
})(FavoriteMenu);
