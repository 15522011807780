import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import EditDialog           from "Components/Utils/Dialog/EditDialog";



/**
 * The Payment Dialog
 */
class PaymentDialog extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, checkout, onClose } = this.props;

        return <EditDialog
            open={open}
            className="cart-payment-dialog"
            message="CART_PAYMENT_DIALOG"
            icon="payment"
            onClose={onClose}
        >
            <iframe src={checkout} title={NLS.get("CART_PAYMENT_DIALOG")} />
        </EditDialog>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        open     : PropTypes.bool.isRequired,
        checkout : PropTypes.string.isRequired,
        onClose  : PropTypes.func.isRequired,
    }
}

export default PaymentDialog;
