import API                  from "Utils/App/API";

// Types
import {
    HOME_LOADING, HOME_ELEM,
} from "Utils/Types";



/**
 * Fetches the Home
 * @returns {Function}
 */
export function fetchHome() {
    return async (dispatch) => {
        dispatch({ type : HOME_LOADING });
        const data = await API.Store.getHome();
        dispatch({ type : HOME_ELEM, data });
    };
}
