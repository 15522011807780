import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { setNotiRedirect }  from "Actions/Content/NotificationActions";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";
import Utils                from "Utils/Common/Utils";

// Components
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Form/Button.css";



/**
 * The Button
 */
class Button extends React.Component {
    /**
     * Handles the Click
     * @param {Event} e
     * @returns {Void}
     */
    handleClick = (e) => {
        const { onClick, href, history, setNotiRedirect } = this.props;
        let handled = false;
        
        if (onClick) {
            onClick(e);
            handled = true;
        }
        if (href) {
            if (href === Url.LOGIN) {
                setNotiRedirect(history.location.pathname);
            }
            Utils.handleHref(href, history);
            handled = true;
        }
        if (handled) {
            e.preventDefault();
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            variant, className, message, icon, afterIcon,
            isDisabled, fullWidth, onTouchEnd, children,
        } = this.props;
        const content = children || NLS.get(message);

        return <button
            className={`btn btn-${variant} ${fullWidth ? "btn-full" : ""} ${className}`}
            disabled={isDisabled}
            onClick={this.handleClick}
            onTouchEnd={onTouchEnd}
        >
            {!!icon      && <Icon className="btn-preicon" variant={icon} />}
            {!!content   && <span className="btn-content">{content}</span>}
            {!!afterIcon && <Icon className="btn-aftericon" variant={afterIcon} />}
        </button>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history         : PropTypes.object.isRequired,
        setNotiRedirect : PropTypes.func.isRequired,
        variant         : PropTypes.string.isRequired,
        className       : PropTypes.string,
        message         : PropTypes.string,
        icon            : PropTypes.string,
        afterIcon       : PropTypes.string,
        isDisabled      : PropTypes.bool,
        fullWidth       : PropTypes.bool,
        href            : PropTypes.string,
        onClick         : PropTypes.func,
        onTouchEnd      : PropTypes.func,
        children        : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        fullWidth : false,
    }
}

export default withRouter(connect(null, {
    setNotiRedirect,
})(Button));
