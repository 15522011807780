import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import ProductPrice         from "Components/Product/Item/ProductPrice";
import Html                 from "Components/Utils/Common/Html";

// Styles
import "Styles/Components/Product/Item/ProductInfo.css";



/**
 * The Product Info
 */
class ProductInfo extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isMobile, className, withCode, withPrice, data } = this.props;
        const { productCode, name, brandName, description, hasVariants   } = data;

        const title    = name.replace(/\//g, " / ");
        const hasPrice = Boolean(withPrice && settings.showPrice && !hasVariants);
        const hasCode  = Boolean(withCode && settings.showCode && productCode);
        const hasBrand = Boolean(settings.showBrand && brandName);
        const hasDesc  = Boolean(description && ((isMobile && settings.showMobileDesc) || (!isMobile && settings.showDesktopDesc)));

        return <div className={`product-info ${className}`}>
            {hasPrice && <ProductPrice
                className="product-card-price"
                data={data}
                withNew={settings.showNewTag}
                withPercent
            />}
            {hasCode && <div className="product-info-code">{productCode}</div>}
            {hasBrand && <div className="product-info-brand">{brandName}</div>}
            <h3>{title}</h3>
            {hasDesc && <Html
                className="product-info-desc"
                content={description}
                maxLength={250}
            />}
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        settings  : PropTypes.object.isRequired,
        isMobile  : PropTypes.bool.isRequired,
        data      : PropTypes.object.isRequired,
        className : PropTypes.string,
        withCode  : PropTypes.bool,
        withPrice : PropTypes.bool,
        withDesc  : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className : "",
        withCode  : false,
        withPrice : false,
        withDesc  : false,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings : state.core.settings,
            isMobile : state.core.isMobile,
        };
    }
}

export default connect(ProductInfo.mapStateToProps)(ProductInfo);
