import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Url                  from "Utils/App/Url";
import ClassList            from "Utils/Common/ClassList";

// Components
import HeaderLogo           from "Components/Core/Header/HeaderLogo";
import SearchBar            from "Components/Core/Header/SearchBar";
import HyperLink            from "Components/Utils/Common/HyperLink";

// Actions
import {
    toggleNav, toggleSearch,
} from "Actions/Store/StoreActions";



/**
 * The Header Bar
 */
class HeaderBar extends React.Component {
    /**
     * Opens the Search
     * @returns {Void}
     */
    openSearch = () => {
        this.props.toggleSearch(true);
    }

    /**
     * Closes the Search
     * @returns {Void}
     */
    closeSearch = () => {
        this.props.toggleSearch(false);
    }

    /**
     * Toggles the Navigation
     * @returns {Void}
     */
    toggleNav = () => {
        this.props.toggleNav(!this.props.navOpen);
    }
    
    /**
     * Closes the Navigation
     * @returns {Void}
     */
    closeNav = () => {
        this.props.toggleNav(false);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isLaptop, isMicro, settings, cart, navOpen, searchOpen                 } = this.props;
        const { phone, whatsapp, headerPhone, headerWhatsapp, headerContact, onlyIcons } = settings;
        
        const smallLogo  = !settings.bigLogo    || isLaptop;
        const leftMenu   = settings.menuLeft    && isLaptop;
        const rightMenu  = !settings.menuLeft   && isLaptop;
        const cartIcon   = settings.allowCart   && isLaptop;
        const searchIcon = (settings.searchIcon && isLaptop) || isMicro;

        const classes = new ClassList("header-content");
        classes.addIf("header-search-show",  searchOpen);
        classes.addIf("header-search-right", settings.searchRight);
        classes.addIf("header-big-icons",    onlyIcons);
        classes.addIf("header-centered",     settings.centerLogo && isLaptop);

        return <section className={classes.get()}>
            {leftMenu && <div className="header-menu-left">
                <HyperLink
                    className="header-icon"
                    variant="icon"
                    onClick={this.toggleNav}
                    icon={navOpen ? "close" : "menu"}
                />
            </div>}
            {smallLogo && <HeaderLogo />}

            <div className="header-secondary">
                <SearchBar />
                <div className="header-contact">
                    {headerWhatsapp && <HyperLink
                        variant="menu"
                        message={onlyIcons ? "" : whatsapp}
                        href={whatsapp}
                        icon="whatsapp"
                        whatsapp
                    />}
                    {headerPhone && <HyperLink
                        variant="menu"
                        message={onlyIcons ? "" : phone}
                        href={phone}
                        icon="phone"
                        tel
                    />}
                    {headerContact && <HyperLink
                        variant="menu"
                        message={onlyIcons ? "" : "CONTACT_NAME"}
                        href={Url.CONTACT}
                        icon="email"
                    />}
                </div>
                
                {searchIcon && <div className="header-search">
                    <HyperLink
                        className="header-icon"
                        variant="icon"
                        onClick={this.openSearch}
                        icon="search"
                    />
                </div>}
                {cartIcon && <div className="header-cart">
                    <HyperLink
                        className="header-icon"
                        variant="icon"
                        href={Url.CART}
                        onClick={this.closeNav}
                        icon="cart"
                        badge={cart}
                    />
                </div>}
                {rightMenu && <div className="header-menu-right">
                    <HyperLink
                        className="header-icon"
                        variant="icon"
                        onClick={this.toggleNav}
                        icon={navOpen ? "close" : "menu"}
                    />
                </div>}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleNav    : PropTypes.func.isRequired,
        toggleSearch : PropTypes.func.isRequired,
        settings     : PropTypes.object.isRequired,
        isLaptop     : PropTypes.bool.isRequired,
        isMicro      : PropTypes.bool.isRequired,
        cart         : PropTypes.number.isRequired,
        navOpen      : PropTypes.bool.isRequired,
        searchOpen   : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings   : state.core.settings,
            isLaptop   : state.core.isLaptop,
            isMicro    : state.core.isMicro,
            navOpen    : state.store.navOpen,
            searchOpen : state.store.searchOpen,
            cart       : state.store.menuData.cart,
        };
    }
}

export default connect(HeaderBar.mapStateToProps, {
    toggleNav, toggleSearch,
})(HeaderBar);
