import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Notifications        from "Utils/Common/Notifications";

// Components
import NotificationItem     from "./NotificationItem";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import Content              from "Components/Utils/Common/Content";
import Button               from "Components/Utils/Form/Button";



/**
 * The Notification Page
 */
class NotificationPage extends React.Component {
    // The Current State
    state = {
        show : true,
    }

    /**
     * Handles the Notification Enabled
     * @returns {Void}
     */
    handleNotifications = async () => {
        const response = await Notifications.requestPermission();
        if (response) {
            this.setState({ show : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { list } = this.props;
        const { show } = this.state;
        const button = Notifications.canGrant() && show;

        return <>
            <Title message="NOTIFICATIONS_NAME" />
            <Wrapper className="notification-container" withSpacing>
                <Content
                    none="NOTIFICATIONS_NONE_AVAILABLE"
                    hasContent={!!list.length}
                    withCard
                    withBorder
                >
                    <ul className="no-list">
                        {list.map((elem, index) => <li key={elem.key}>
                            <NotificationItem data={elem} index={index} />
                        </li>)}
                    </ul>
                </Content>
                {button && <div className="notification-enable">
                    <Button
                        variant="primary"
                        message="NOTIFICATIONS_ENABLE"
                        onClick={this.handleNotifications}
                        fullWidth
                    />
                </div>}
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        list : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            list : state.notification.list,
        };
    }
}

export default connect(NotificationPage.mapStateToProps, null)(NotificationPage);
