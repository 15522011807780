import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import Utils                from "Utils/Common/Utils";

// Components
import SubTitle             from "Components/Utils/Common/SubTitle";
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    fetchAccount, editAccount,
} from "Actions/Client/ClientActions";



/**
 * The Account Page
 */
class AccountPage extends React.Component {
    // The Initial Data
    static initialData = {
        socialReason    : "",
        ivaCategory     : 0,
        cuit            : "",
        dni             : "",
        fantasyName     : "",
        firstName       : "",
        lastName        : "",
        email           : "",
        phone           : "",
        cellphone       : "",
        address         : "",
        zipCode         : "",
        locality        : "",
        province        : "",
        newPassword     : "",
        confirmPassword : "",
    }
    
    // The Current State
    state = {
        data    : { ...AccountPage.initialData },
        loaded  : false,
        loading : false,
        success : "",
        errors  : {},
        error   : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchAccount();
    }

    /**
     * Updates the State
     * @returns {Void}
     */
    componentDidUpdate() {
        let data = null;
        if (!Utils.isEmpty(this.props.elem) && this.state.loaded === false) {
            data = Utils.extend(AccountPage.initialData, this.props.elem);
        }
        if (data) {
            this.setState({ data, loading : false, errors : {}, loaded : true });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, success : "", errors : {}, error : "" });
        try {
            const success = await this.props.editAccount(data);
            this.setState({ loading : false, success });
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, settings, entities          } = this.props;
        const { ivaCategories, withCUIT, provinces    } = entities;
        const { data, loading, success, errors, error } = this.state;

        const fantasyName  = settings.fantasyName;
        const fantasyLabel = fantasyName || "ACCOUNT_FANTASY_NAME";
        const fantasyError = fantasyName && errors.fantasyName ? NLS.format("ACCOUNT_ERROR_FANTASY", fantasyName) : errors.fantasyName;
        const reqCUIT      = withCUIT.indexOf(Number(data.ivaCategory)) > -1 || !data.ivaCategory;

        return <>
            <SubTitle show={!isMobile} message="ACCOUNT_NAME" icon="client" />
            <Form
                className="client-form spacing"
                columns="3"
                success={success}
                error={error}
                onSubmit={this.handleSubmit}
                onClose={this.closeAlert}
            >
                <TextField
                    name="socialReason"
                    label="ACCOUNT_SOCIAL_REASON"
                    value={data.socialReason}
                    error={errors.socialReason}
                    onChange={this.handleChange}
                    isDisabled
                />
                <TextField
                    type="select"
                    name="ivaCategory"
                    label="ACCOUNT_IVA_CATEGORY"
                    options={ivaCategories}
                    value={data.ivaCategory}
                    error={errors.ivaCategory}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    withNone
                    isDisabled
                />
                <TextField
                    show={reqCUIT}
                    name="cuit"
                    label="ACCOUNT_CUIT"
                    value={data.cuit}
                    error={errors.cuit}
                    onChange={this.handleChange}
                    isDisabled
                />
                <TextField
                    show={!reqCUIT}
                    name="dni"
                    label="ACCOUNT_DNI"
                    value={data.dni}
                    error={errors.dni}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    isRequired
                />

                <TextField
                    name="fantasyName"
                    label={fantasyLabel}
                    value={data.fantasyName}
                    error={fantasyError}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    name="firstName"
                    label="ACCOUNT_FIRST_NAME"
                    value={data.firstName}
                    error={errors.firstName}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    name="lastName"
                    label="ACCOUNT_LAST_NAME"
                    value={data.lastName}
                    error={errors.lastName}
                    onChange={this.handleChange}
                    isRequired
                />

                <TextField
                    type="email"
                    name="email"
                    label="GENERAL_EMAIL"
                    value={data.email}
                    error={errors.email}
                    onChange={this.handleChange}
                    isRequired
                />
                <TextField
                    type="tel"
                    name="phone"
                    label="ACCOUNT_PHONE"
                    value={data.phone}
                    error={errors.phone}
                    onChange={this.handleChange}
                />
                <TextField
                    type="tel"
                    name="cellphone"
                    label="ACCOUNT_CELLPHONE"
                    value={data.cellphone}
                    error={errors.cellphone}
                    onChange={this.handleChange}
                />

                <TextField
                    name="address"
                    label="ACCOUNT_ADDRESS"
                    value={data.address}
                    error={errors.address}
                    onChange={this.handleChange}
                    isDisabled
                />
                <TextField
                    name="locality"
                    label="ACCOUNT_LOCALITY"
                    value={data.locality}
                    error={errors.locality}
                    onChange={this.handleChange}
                    isDisabled
                />
                <TextField
                    type="select"
                    name="province"
                    label="ACCOUNT_PROVINCE"
                    options={provinces}
                    value={data.province}
                    error={errors.province}
                    onChange={this.handleChange}
                    withNone
                    isDisabled
                />
                <TextField
                    name="zipCode"
                    label="ACCOUNT_ZIP_CODE"
                    value={data.zipCode}
                    error={errors.zipCode}
                    onChange={this.handleChange}
                    isDisabled
                />

                <TextField
                    className="columns-first"
                    type="password"
                    name="newPassword"
                    label="ACCOUNT_NEW_PASSWORD"
                    value={data.newPassword}
                    error={errors.newPassword}
                    onChange={this.handleChange}
                    autoComplete="new-password"
                />
                <TextField
                    className="columns-second"
                    type="password"
                    name="confirmPassword"
                    label="ACCOUNT_PASSWORD_CONFIRM"
                    value={data.confirmPassword}
                    error={errors.confirmPassword}
                    onChange={this.handleChange}
                    autoComplete="new-password"
                />

                <div className="columns-btn">
                    <Button
                        variant="primary"
                        message="GENERAL_SAVE"
                        isDisabled={loading}
                        fullWidth
                    />
                </div>
            </Form>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAccount : PropTypes.func.isRequired,
        editAccount  : PropTypes.func.isRequired,
        isMobile     : PropTypes.bool.isRequired,
        settings     : PropTypes.object.isRequired,
        entities     : PropTypes.object.isRequired,
        elem         : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile : state.core.isMobile,
            settings : state.core.settings,
            entities : state.core.entities,
            elem     : state.client.elem,
        };
    }
}

export default connect(AccountPage.mapStateToProps, {
    fetchAccount, editAccount,
})(AccountPage);
