import Url                  from "Utils/App/Url";



/** All the Menu Items */
const MENU_ITEMS = {
    home         : { icon : "home",         href : Url.HOME,           message : "GENERAL_HOME",          submenu : ""            },
    products     : { icon : "product",      href : Url.PRODUCTS,       message : "PRODUCTS_NAME",         submenu : "category"    },
    categories   : { icon : "categories",   href : Url.CATEGORIES,     message : "CATEGORIES_NAME",       submenu : "category"    },
    category     : { icon : "category",     href : Url.CATEGORIES,     message : "CATEGORIES_SINGULAR",   submenu : "subcategory" },
    brands       : { icon : "brand",        href : Url.BRANDS,         message : "BRANDS_NAME",           submenu : "brand"       },
    brand        : { icon : "brand",        href : Url.BRANDS,         message : "BRANDS_SINGULAR",       submenu : ""            },
    offers       : { icon : "offer",        href : Url.OFFERS,         message : "OFFERS_NAME",           submenu : "offer"       },

    news         : { icon : "news",         href : Url.NEWS,           message : "NEWS_NAME",             submenu : "" },
    catalogue    : { icon : "catalog",      href : Url.CATALOG,        message : "CATALOG_NAME",          submenu : "" },
    contact      : { icon : "email",        href : Url.CONTACT,        message : "CONTACT_NAME",          submenu : "" },
    chat         : { icon : "chat",         href : Url.CHAT,           message : "CHAT_NAME",             submenu : "" },
    notification : { icon : "notification", href : Url.NOTIFICATIONS,  message : "NOTIFICATIONS_ALERTS",  submenu : "" },
    terms        : { icon : "terms",        href : Url.TERMS,          message : "TERMS_NAME",            submenu : "" },
    
    login        : { icon : "client",       href : Url.LOGIN,          message : "AUTH_LOGIN_ACTION",     submenu : "" },
    client       : { icon : "client",       href : Url.ACCOUNT,        message : "CLIENT_NAME",           submenu : "" },
    clientData   : { icon : "client",       href : Url.ACCOUNT_DATA,   message : "CLIENT_NAME",           submenu : "" },
    account      : { icon : "account",      href : Url.ACCOUNT_DATA,   message : "ACCOUNT_NAME",          submenu : "" },
    address      : { icon : "address",      href : Url.ADDRESSES,      message : "ADDRESSES_NAME",        submenu : "" },
    voucher      : { icon : "voucher",      href : Url.VOUCHERS,       message : "VOUCHERS_NAME",         submenu : "" },
    invoice      : { icon : "voucher",      href : Url.INVOICES,       message : "VOUCHERS_INVOICES",     submenu : "" },
    receipt      : { icon : "voucher",      href : Url.RECEIPTS,       message : "VOUCHERS_RECEIPTS",     submenu : "" },
    creditNote   : { icon : "voucher",      href : Url.CREDIT_NOTES,   message : "VOUCHERS_CREDIT_NOTES", submenu : "" },
    debitNote    : { icon : "voucher",      href : Url.DEBIT_NOTES,    message : "VOUCHERS_DEBIT_NOTES",  submenu : "" },
    cart         : { icon : "cart",         href : Url.ORDERS,         message : "ORDERS_NAME",           submenu : "" },
    query        : { icon : "query",        href : Url.QUERIES,        message : "QUERIES_NAME",          submenu : "" },
};



/**
 * The Menu Items Class
 */
class MenuItems {
    /**
     * Creates a Menu Instance
     * @constructor
     */
    constructor() {
        this.data = [];
    }

    /**
     * Returns the Menu Items
     * @returns {Array.<Object>}
     */
    get() {
        return this.data;
    }

    /**
     * Adds a Menu Item
     * @param {String}  type
     * @param {String}  submenu
     * @param {Number}  badge
     * @param {String=} url
     * @returns {Void}
     */
    add(type, submenu, badge, url) {
        if (MENU_ITEMS[type]) {
            const { icon, href, message } = MENU_ITEMS[type];
            this.data.push({ key : type, type, icon, href : url || href, message, submenu, badge });
        }
    }

    /**
     * Adds a Menu Item If
     * @param {Boolean} condition
     * @param {String}  type
     * @param {String}  submenu
     * @param {Number}  badge
     * @param {String=} url
     * @returns {Void}
     */
    addIf(condition, type, submenu, badge, url) {
        if (condition) {
            this.add(type, submenu, badge, url);
        }
    }

    /**
     * Adds an Item
     * @param {Object} item
     * @param {Object} settings
     * @param {Object} content
     * @returns {Void}
     */
    addItem(item, settings, content) {
        const menu      = MENU_ITEMS[item.type];
        const key       = item.id;
        const type      = item.type;
        const icon      = menu ? menu.icon : "";
        let   submenu   = menu && item.showSubmenu ? menu.submenu : "";
        let   submenuID = 0;
        let   href      = menu ? menu.href : "";
        let   message   = item.name || (menu ? menu.message : "");
        let   target    = "_self";
        let   isBold    = !!item.showBold;
        let   isEmpty   = false;
        let   canAdd    = true;

        switch (type) {
        case "category":
            href      = `${Url.PRODUCTS}/${item.categorySlug}`;
            message   = item.name || item.categoryName;
            submenuID = item.categoryID;
            break;
        case "brands":
            canAdd  = settings.hasBrands;
            break;
        case "brand":
            href    = `${Url.BRANDS}/${item.brandSlug}`;
            message = item.name || item.brandName;
            canAdd  = settings.hasBrands;
            break;
        case "offers":
            isEmpty = !content.hasOffers;
            submenu = isEmpty ? "" : submenu;
            break;
        case "news":
            isEmpty = !content.hasNews;
            break;
        case "catalogue":
            isEmpty = !content.hasCatalogue;
            break;
        case "contact":
            canAdd  = settings.hasContact;
            break;
        case "link":
            href    = item.href;
            message = item.name;
            target  = item.targetBlank ? "_blank" : "_self";
            break;
        default:
        }
        
        if (canAdd && (!item.hideEmpty || !isEmpty)) {
            this.data.push({ key, type, icon, href, target, message, isBold, submenu, submenuID });
        }
    }
}



/**
 * The Links for the Primary Menu Items
 * @param {Object} menus
 * @param {Object} settings
 * @param {Object} content
 * @returns {Array.<Object>}
 */
function getPrimary(menus, settings, content) {
    const items = new MenuItems();
    if (menus.primary) {
        for (const menuItem of menus.primary) {
            items.addItem(menuItem, settings, content);
        }
    } else {
        items.add("products", "category");
        items.add("offers",   content.hasOffers ? "offer" : "");
        items.addIf(settings.hasBrands, "brands");
    }
    return items.get();
}

/**
 * The Links for the Secondary Menu Items
 * @param {Object} menus
 * @param {Object} settings
 * @param {Object} content
 * @returns {Array.<Object>}
 */
function getSecondary(menus, settings, content) {
    const items = new MenuItems();
    if (menus.secondary) {
        for (const menuItem of menus.secondary) {
            items.addItem(menuItem, settings, content);
        }
    }
    return items.get();
}

/**
 * The Links for the Navigation Menu Items
 * @param {Object} menus
 * @param {Object} settings
 * @param {Object} content
 * @returns {Array.<Object>}
 */
function getNavigation(menus, settings, content) {
    const items = new MenuItems();
    if (menus.navigation) {
        for (const menuItem of menus.navigation) {
            items.addItem(menuItem, settings, content);
        }
    } else {
        items.add("home");
        items.add("products");
        
        items.addIf(content.hasOffers,    "offers");
        items.addIf(settings.hasBrands,   "brands");
        items.addIf(content.hasNews,      "news");
        items.addIf(content.hasCatalogue, "catalogue");
        items.addIf(settings.hasContact,  "contact");
    }
    return items.get();
}

/**
 * The Links for the Bar Menu Items
 * @param {Object}  settings
 * @param {Object}  content
 * @param {Number}  chats
 * @param {Number}  notifications
 * @param {Boolean} isAuthenticated
 * @param {Boolean} isTablet
 * @returns {Array.<Object>}
 */
function getBar(settings, content, chats, notifications, isAuthenticated, isTablet) {
    const items    = new MenuItems();
    const showChat = isAuthenticated && settings.hasChat;

    items.add("products");
    items.addIf(content.hasOffers, "offers");
    items.addIf(content.hasBrands, "brands");
    items.addIf(showChat,          "chat",         null, chats);
    items.addIf(isAuthenticated,   "notification", null, notifications);
    items.addIf(isAuthenticated,   isTablet ? "clientData" : "client");
    items.addIf(!isAuthenticated,  "login");
    
    return items.get();
}



/**
 * The Links for the About Us Menu Items
 * @param {Object}  settings
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getAboutUs(settings, isAuthenticated) {
    const items = new MenuItems();
    items.addIf(settings.hasContact, "contact");
    items.addIf(isAuthenticated,     "query");
    items.add("terms");
    return items.get();
}

/**
 * The Links for the Connected Menu Items
 * @returns {Array.<Object>}
 */
function getConnected() {
    const items = new MenuItems();
    items.add("news");
    return items.get();
}

/**
 * The Links for the Site Menu Items
 * @param {Object}  settings
 * @param {Object}  content
 * @param {Boolean} isAuthenticated
 * @returns {Array.<Object>}
 */
function getSite(settings, content, isAuthenticated) {
    const items = new MenuItems();
    items.add("products");
    items.addIf(content.hasOffers,    "offers");
    items.addIf(settings.hasBrands,   "brands");
    items.addIf(content.hasCatalogue, "catalogue");
    items.addIf(isAuthenticated,      "client");
    return items.get();
}



/**
 * The Links for the Client Menu Items
 * @param {Object}  settings
 * @param {Boolean} withVoucherLink
 * @returns {Array.<Object>}
 */
function getClient(settings, withVoucherLink) {
    const items = new MenuItems();
    items.add("account");
    items.add("address");

    if (settings.hasVouchers) {
        const subItems = settings.onlyVoucher ? items : new MenuItems();
        subItems.addIf(settings.hasInvoices,    "invoice");
        subItems.addIf(settings.hasReceipts,    "receipt");
        subItems.addIf(settings.hasCreditNotes, "creditNote");
        subItems.addIf(settings.hasDebitNotes,  "debitNote");
        
        const subMenu = subItems.get();
        const subUrl  = withVoucherLink ? subMenu[0].href : "";
        items.addIf(!settings.onlyVoucher, "voucher", subMenu, 0, subUrl);
    }
    items.add("cart");
    items.add("query");
    return items.get();
}

/**
 * The Links for the Client Sub Menu Items
 * @param {Object} content
 * @returns {Array.<Object>}
 */
function getClientSecondary(content) {
    const items = new MenuItems();
    items.add("news");
    items.addIf(content.hasCatalogue, "catalog");
    items.add("terms");
    return items.get();
}




// The Public API
export default {
    getPrimary,
    getSecondary,
    getNavigation,
    getBar,

    getAboutUs,
    getConnected,
    getSite,

    getClient,
    getClientSecondary,
};
