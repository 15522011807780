import Utils                from "Utils/Common/Utils";

// Types
import {
    CONTACT_LOADING, CONTACT_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading : false,
    error   : false,
    elem    : {
        title : "CONTACT_NAME",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, CONTACT_ELEM)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case CONTACT_LOADING:
        return {
            ...state,
            loading    : true,
        };
    
    case CONTACT_ELEM:
        return {
            ...state,
            loading : false,
            error   : false,
            elem    : action.data,
        };
    default:
        return state;
    }
};
