import React                from "react";
import PropTypes            from "prop-types";
import { withRouter }       from "react-router";
import Utils                from "Utils/Common/Utils";



/**
 * The Table Row
 */
class TableRow extends React.Component {
    /**
     * Handles the Click
     * @param {String}   href
     * @param {Function} onClick
     * @returns {Function}
     */
    handleClick = (href, onClick) => (e) => {
        let handled = false;
        if (onClick) {
            onClick(e);
            handled = true;
        }
        if (href) {
            Utils.handleHref(href, this.props.history);
            handled = true;
        }
        if (handled) {
            e.preventDefault();
        }
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { href, onClick, children } = this.props;

        return <tr onClick={this.handleClick(href, onClick)}>
            {children}
        </tr>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history  : PropTypes.object.isRequired,
        href     : PropTypes.string,
        onClick  : PropTypes.func,
        children : PropTypes.any,
    }
}

export default withRouter(TableRow);
