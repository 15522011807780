import React                from "react";
import PropTypes            from "prop-types";



/**
 * The Table Body
 */
class TableBody extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { children } = this.props;

        return <tbody>
            {children}
        </tbody>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        children : PropTypes.any,
    }
}

export default TableBody;
