import React                from "react";
import PropTypes            from "prop-types";
import ClassList            from "Utils/Common/ClassList";

// Styles
import "Styles/Components/Utils/Common/Card.css";



/**
 * The Card
 */
class Card extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, withSpacing, withBorder, onTouchEnd, onScroll, children } = this.props;
        const classes = new ClassList("card", className);
        classes.addIf("card-border", withBorder);
        classes.addIf("spacing",     withSpacing);

        return <section className={classes.get()} onTouchEnd={onTouchEnd} onScroll={onScroll}>
            {children}
        </section>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        className   : PropTypes.string,
        withSpacing : PropTypes.bool,
        withBorder  : PropTypes.bool,
        onTouchEnd  : PropTypes.func,
        onScroll    : PropTypes.func,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withSpacing : false,
        withBorder  : false,
    }
}

export default Card;
