import React                from "react";
import PropTypes            from "prop-types";

// Components
import ErrorPage            from "Components/Content/ErrorPage";



/**
 * The Products Container
 */
class ProductsContainer extends React.Component {
    // The Current State
    state = {
        openFilter : false,
    }

    /**
     * Handles the Filter
     * @param {Boolean=} value
     * @returns {Void}
     */
    setFilter = (value = null) => {
        if (value !== null) {
            this.setState({ openFilter : value });
        } else {
            this.setState({ openFilter : !this.state.openFilter });
        }
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { getUrl, onChange, hasFilters, loading, error, children } = this.props;
        
        if (error) {
            return <ErrorPage />;
        }

        return React.Children.map(children, (child) => (
            React.cloneElement(child, {
                getUrl, onChange, hasFilters, loading,
                setFilter  : this.setFilter,
                openFilter : this.state.openFilter,
            })
        ));
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        getUrl     : PropTypes.func.isRequired,
        onChange   : PropTypes.func.isRequired,
        hasFilters : PropTypes.bool.isRequired,
        loading    : PropTypes.bool.isRequired,
        error      : PropTypes.bool.isRequired,
        children   : PropTypes.any,
    }
}

export default ProductsContainer;
