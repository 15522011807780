export default {
    
    // General
    GENERAL_LANGUAGES : { "es" : "Español", "en" : "English" },


    // Replazable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    // DATE_DAY_NAMES    : [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
    // DATE_MONTH_NAMES  : [ "January", "February", "March", "April", "May", "June", "July", "Agust", "September", "October", "November", "December" ],

};
