import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { ackAddress }       from "Actions/Client/AddressActions";
import NLS                  from "Utils/App/NLS";
import Metrics              from "Utils/Common/Metrics";
import Utils                from "Utils/Common/Utils";

// Components
import CartOption           from "Components/Cart/Utils/CartOption";
import CartSummary          from "Components/Cart/Utils/CartSummary";
import AddressEdit          from "Components/Cart/Shipment/AddressEdit";
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Price                from "Components/Utils/Common/Price";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    unconfirmToProducts, confirmShipment,
    setShipment, priceShipment, tryShipment,
} from "Actions/Store/CartActions";



/**
 * The Shipment Content
 */
class ShipmentContent extends React.Component {
    // The Current State
    state = {
        showDialog : false,
        elemID     : 0,
        loading    : false,
        isValid    : false,
        hasPrices  : false,
        data       : {
            shipmentMode : 0,
            shipmentType : 0,
            subsidiaryID : 0,
            addressID    : 0,
        },
    }



    /**
     * Set the Data When Loading
     * @returns {Void}
     */
    componentDidMount() {
        const { settings, elem                                      } = this.props;
        const { shipmentMode, shipmentType, subsidiaryID, addressID } = elem;

        if (shipmentMode) {
            this.updateState(shipmentMode, shipmentType, subsidiaryID, addressID);
            if (addressID) {
                this.priceShipment(shipmentMode, addressID);
            }
            if (shipmentType) {
                this.setState({ isValid : true });
            }
        } else if (settings.hideShipments) {
            this.initShipment();
        }
    }

    /**
     * Close the Address Alert
     * @returns {Void}
     */
    componentWillUnmount() {
        this.props.ackAddress();
    }

    /**
     * Initializes the Shipment
     * @returns {Object}
     */
    initShipment() {
        const { shipments, subsidiaries, addresses                  } = this.props.elem;
        let   { shipmentType, shipmentMode, subsidiaryID, addressID } = this.props.elem;

        let   shipment   = Utils.getData(shipments,    "type",         shipmentType);
        const subsidiary = Utils.getData(subsidiaries, "subsidiaryID", subsidiaryID);
        const address    = Utils.getData(addresses,    "addressID",    addressID);

        if (Utils.isEmpty(shipment)) {
            shipmentType = shipments[0].type;
            shipmentMode = shipments[0].mode;
            shipment     = shipments[0];
        }
        if (Utils.isEmpty(subsidiary)) {
            subsidiaryID = 0;
        }
        if (Utils.isEmpty(address)) {
            addressID = 0;
        }

        if (shipment.reqSubsidiary && !subsidiaryID && subsidiaries.length > 0) {
            subsidiaryID = subsidiaries[0].subsidiaryID;
        }
        if (shipment.reqAddress && !addressID) {
            const mainAddress = Utils.getData(addresses, "isDefault", 1);
            addressID = mainAddress.addressID || 0;
        }
        this.updateState(shipmentMode, shipmentType, subsidiaryID, addressID);

        if (addressID) {
            this.priceShipment(shipmentMode, addressID);
        }
        if (shipmentType) {
            this.setState({ isValid : true });
        }
    }



    /**
     * Updates the Shipment Type
     * @param {Number}   newShipmentMode
     * @param {Number}   newSubsidiaryID
     * @param {Number}   newAddressID
     * @param {Number}   newShipmentType
     * @param {Boolean=} isValid
     * @returns {Void}
     */
    updateState(newShipmentMode, newShipmentType, newSubsidiaryID, newAddressID, isValid = false) {
        const oldData      = this.state.data;
        const shipmentMode = newShipmentMode === undefined ? oldData.shipmentMode : newShipmentMode;
        const shipmentType = newShipmentType === undefined ? oldData.shipmentType : newShipmentType;
        const subsidiaryID = newSubsidiaryID === undefined ? oldData.subsidiaryID : newSubsidiaryID;
        const addressID    = newAddressID    === undefined ? oldData.addressID    : newAddressID;

        this.setState({ data : { shipmentMode, shipmentType, subsidiaryID, addressID }, isValid });
        this.props.setShipment(shipmentMode, shipmentType, subsidiaryID, addressID);
    }

    /**
     * Selects the Shipment Type
     * @param {Number} shipmentMode
     * @returns {Promise}
     */
    selectShipment = async (shipmentMode) => {
        if (shipmentMode !== this.state.data.shipmentMode) {
            const shipment = Utils.getData(this.props.elem.modes, "type", shipmentMode);
            if (shipment.reqSubsidiary && !this.props.elem.subsidiaries.length) {
                this.updateState(shipmentMode, shipment.defaultType, 0, 0, true);
            } else {
                this.updateState(shipmentMode, shipment.defaultType, 0, 0, false);
            }
        }
    }

    /**
     * Selects the Subsidiary
     * @param {Number} subsidiaryID
     * @returns {Promise}
     */
    selectSubsidiary = (subsidiaryID) => {
        if (subsidiaryID !== this.state.data.subsidiaryID) {
            this.updateState(undefined, undefined, subsidiaryID, 0, true);
        }
    }

    /**
     * Selects the Address
     * @param {Number} addressID
     * @returns {Void}
     */
    selectAddress = (addressID) => {
        const data = this.state.data;
        if (addressID !== data.addressID) {
            this.updateState(undefined, 0, 0, addressID);
            this.priceShipment(data.shipmentMode, addressID);
        }
    }

    /**
     * Selects the Shipment Type
     * @param {Number} shipmentType
     * @returns {Void}
     */
    selectType = (shipmentType) => {
        const data = this.state.data;
        if (shipmentType !== data.shipmentType) {
            this.updateState(undefined, shipmentType);
            this.tryShipment(shipmentType, data.addressID);
        }
    }

    

    /**
     * Edits the Address
     * @param {String} success
     * @param {Number} addressID
     * @returns {Void}
     */
    editAddress = (success, addressID) => {
        const data = this.state.data;
        if (addressID === data.addressID) {
            this.priceShipment(data.shipmentMode, data.addressID);
        } else {
            this.selectAddress(addressID);
        }
        this.closeDialog();
        this.props.openAlert(success);
    }

    /**
     * Fetches the Shipment Prices
     * @param {Number} shipmentMode
     * @param {Number} addressID
     * @returns {Promise}
     */
    async priceShipment(shipmentMode, addressID) {
        const { priceShipment, closeAlert, openAlert } = this.props;
        if (this.state.loading) {
            return;
        }

        closeAlert();
        this.setState({ loading : true });
        try {
            await priceShipment(shipmentMode, addressID);
            this.setState({ loading : false, hasPrices : true });
        } catch (response) {
            openAlert("", response.form);
            this.setState({ loading : false, hasPrices : false });
        }
    }

    /**
     * Tryes a Shipment Price
     * @param {Number} shipmentType
     * @param {Number} addressID
     * @returns {Promise}
     */
    async tryShipment(shipmentType, addressID) {
        const { tryShipment, closeAlert, openAlert } = this.props;
        if (this.state.loading) {
            return;
        }

        closeAlert();
        this.setState({ loading : true });
        try {
            await tryShipment(shipmentType, addressID);
            this.setState({ loading : false, isValid : true });
        } catch (response) {
            openAlert("", response.form);
            this.setState({ loading : false, isValid : false });
        }
    }
    
    /**
     * Handles a Cart Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, confirmShipment, openAlert, closeAlert, onSubmit } = this.props;
        const { data, loading, success                                 } = this.state;
        if (loading && success) {
            return;
        }

        closeAlert();
        this.setState({ loading : true });
        try {
            await confirmShipment(data);
            Metrics.checkout(Metrics.stepShipment, elem.products);
            onSubmit();
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }

    /**
     * Handles a Cart Cancel
     * @returns {Promise}
     */
    handleCancel = async () => {
        const { elem, unconfirmToProducts, openAlert, closeAlert, onSubmit } = this.props;
        if (this.state.loading) {
            return;
        }

        this.setState({ loading : true });
        closeAlert();
        try {
            await unconfirmToProducts(elem.orderHash);
            Metrics.checkout(Metrics.stepProducts, elem.products);
            onSubmit();
        } catch (errors) {
            openAlert("", errors.form);
            this.setState({ loading : false });
        }
    }



    /**
     * Opens a Dialog
     * @param {Number} elemID
     * @returns {Void}
     */
    openDialog = (elemID) => {
        this.setState({ showDialog : true, elemID });
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : false, elemID : 0 });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, isMobile, settings, elem, prices, openAlert    } = this.props;
        const { modes, addresses, subsidiaries                        } = elem;
        const { showDialog, elemID, loading, data, isValid, hasPrices } = this.state;

        const showContent     = !(isApp && isMobile && showDialog);
        const showMode        = modes.length > 1;
        const shipment        = Utils.getData(modes, "type", data.shipmentMode);
        const canEdit         = !settings.validateAddress;
        const hasSubsidiaries = Boolean(shipment.reqSubsidiary && subsidiaries.length);
        const hasAddresses    = Boolean(addresses.length);
        const hasTypes        = Boolean(hasPrices && shipment.reqType && prices.length && data.addressID);
        const isDisabled      = loading || !isValid;

        return <>
            {showContent && <section className="cart-content">
                <CartOption
                    show={showMode}
                    data={modes}
                    icon="shipping"
                    message="CART_SHIPMENT_TITLE"
                    help="CART_SHIPMENT_HELP"
                    selected={data.shipmentMode}
                    onClick={this.selectShipment}
                />

                {hasSubsidiaries && <Card className="cart-select-card" withBorder>
                    <SubTitle message="CART_SUBSIDIARY_TITLE" icon="subsidiary" />
                    <ul className="cart-select-list no-list">
                        {subsidiaries.map((elem) => <li key={elem.subsidiaryID}>
                            <TextField
                                className="cart-select-field"
                                type="radio"
                                name="subsidiaryID"
                                value={elem.subsidiaryID}
                                isChecked={Number(elem.subsidiaryID) === Number(data.subsidiaryID)}
                                onChange={() => this.selectSubsidiary(elem.subsidiaryID)}
                            />
                            <div className="cart-select-elem" onClick={() => this.selectSubsidiary(elem.subsidiaryID)}>
                                <h3>{elem.name}</h3>
                                <ul className="no-list">
                                    <li>{elem.address}</li>
                                    <li>{elem.locality}</li>
                                    <li>{elem.provinceName}</li>
                                    <li>{elem.zipCode}</li>
                                </ul>
                            </div>
                        </li>)}
                    </ul>
                </Card>}

                {shipment.reqAddress && <Card className="cart-select-card" withBorder>
                    <SubTitle message="CART_ADDRESS_TITLE" icon="address" />
                    {hasAddresses && <ul className="cart-select-list no-list">
                        {addresses.map((elem) => <li key={elem.addressID}>
                            <TextField
                                className="cart-select-field"
                                type="radio"
                                name="addressID"
                                value={elem.addressID}
                                isChecked={Number(elem.addressID) === Number(data.addressID)}
                                onChange={() => this.selectAddress(elem.addressID)}
                            />
                            <div className="cart-select-elem" onClick={() => this.selectAddress(elem.addressID)}>
                                <h3>{elem.name}</h3>
                                <ul className="no-list">
                                    <li>{elem.street} {elem.streetNumber}</li>
                                    {!!elem.floor && <li>{NLS.get("ADDRESSES_FLOOR")} {elem.floor}</li>}
                                    {!!elem.appartment && <li>{NLS.get("ADDRESSES_APPARTMENT")} {elem.appartment}</li>}
                                    <li>{elem.locality}</li>
                                    <li>{elem.provinceName}</li>
                                    <li>{elem.zipCode}</li>
                                    {!!elem.schedule && <li>{elem.schedule}</li>}
                                </ul>
                            </div>
                            {canEdit && <HyperLink
                                className="cart-select-link"
                                message="CART_ADDRESS_EDIT"
                                onClick={() => this.openDialog(elem.addressID)}
                            />}
                        </li>)}
                        <div className="cart-select-other">
                            <Button
                                variant="primary"
                                message={"CART_ADDRESS_ADD_NEW"}
                                onClick={() => this.openDialog()}
                            />
                        </div>
                    </ul>}
                    {!hasAddresses && <div className="cart-select-empty">
                        <p className="cart-help">{NLS.get("CART_ADDRESS_NONE_HELP")}</p>
                        <Button
                            variant="primary"
                            message={"CART_ADDRESS_ADD_FIRST"}
                            onClick={() => this.openDialog()}
                        />
                    </div>}
                </Card>}

                {hasTypes && <Card className="cart-select-card" withBorder>
                    <SubTitle message="CART_TYPE_TITLE" icon="transport" />
                    <ul className="cart-select-list no-list">
                        {prices.map((elem) => <li key={elem.type}>
                            <TextField
                                className="cart-select-field"
                                type="radio"
                                name="type"
                                value={elem.type}
                                isChecked={Number(elem.type) === Number(data.shipmentType)}
                                onChange={() => this.selectType(elem.type)}
                            />
                            <h3 className="cart-select-elem" onClick={() => this.selectType(elem.type)}>
                                {elem.name}
                            </h3>
                            {elem.cents > 0 && <Price
                                className="cart-select-price"
                                symbol={elem.currencySymbol}
                                price={elem.price}
                            />}
                        </li>)}
                    </ul>
                </Card>}
            </section>}

            <CartSummary
                submit="CART_CONFIRM_SHIPMENT"
                cancel="CART_EDIT_PRODUCTS"
                openAlert={openAlert}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                isDisabled={isDisabled}
            />
            <AddressEdit
                open={showDialog}
                elemID={elemID}
                onSubmit={this.editAddress}
                onClose={this.closeDialog}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        unconfirmToProducts : PropTypes.func.isRequired,
        confirmShipment     : PropTypes.func.isRequired,
        setShipment         : PropTypes.func.isRequired,
        priceShipment       : PropTypes.func.isRequired,
        tryShipment         : PropTypes.func.isRequired,
        ackAddress          : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        isApp               : PropTypes.bool.isRequired,
        isMobile            : PropTypes.bool.isRequired,
        settings            : PropTypes.object.isRequired,
        elem                : PropTypes.object.isRequired,
        prices              : PropTypes.array.isRequired,
        openAlert           : PropTypes.func.isRequired,
        closeAlert          : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp    : state.core.isApp,
            isMobile : state.core.isMobile,
            settings : state.core.settings,
            elem     : state.cart.elem,
            prices   : state.cart.prices,
        };
    }
}

export default connect(ShipmentContent.mapStateToProps, {
    unconfirmToProducts, confirmShipment,
    setShipment, priceShipment, tryShipment, ackAddress,
})(ShipmentContent);
