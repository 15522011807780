import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchNew }         from "Actions/Content/NewsActions";
import Url                  from "Utils/App/Url";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Html                 from "Components/Utils/Common/Html";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Content/News.css";



/**
 * The News Elem
 */
class NewsElem extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchNew(this.props.match.params.news);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, elem } = this.props;
        const image = isMobile ? elem.mobileImageUrl : elem.desktopImageUrl;

        return <>
            <Title message={elem.title} href={Url.NEWS} />
            <Wrapper withSpacing>
                <Card className="news-container" withBorder>
                    <div className="news-image">
                        {elem.link ? <HyperLink variant="none" href={elem.link}>
                            <img src={image} alt={elem.title} />
                        </HyperLink> : <img src={image} alt={elem.title} />}
                    </div>
                    {!!elem.description && <Html
                        className="news-description spacing"
                        content={elem.description}
                    />}
                    {!!elem.link && <Button
                        variant="primary"
                        message="NEWS_VIEW"
                        href={elem.link}
                    />}
                </Card>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchNew : PropTypes.func.isRequired,
        isMobile : PropTypes.bool.isRequired,
        elem     : PropTypes.object.isRequired,
        match    : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile : state.core.isMobile,
            elem     : state.news.elem,
        };
    }
}

export default connect(NewsElem.mapStateToProps, {
    fetchNew,
})(NewsElem);
