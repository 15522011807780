import Utils                from "Utils/Common/Utils";



/**
 * The Production Class
 */
export default class Production {
    /**
     * Creates a new Production Instance
     * @param {Function} fetchFunc
     * @param {String}   paramKey
     * @param {Number}   initialSort
     */
    constructor(fetchFunc, paramKey = null, initialSort = 0) {
        this.fetchFunc   = fetchFunc;
        this.paramKey    = paramKey;
        this.initialSort = initialSort;

        this.settings    = {};
        this.preferences = {};
        this.params      = {};
    }

    /**
     * Sets the Settings
     * @param {Object} settings
     * @param {Object} preferences
     * @param {Object} params
     * @returns {Void}
     */
    setSettings(settings, preferences, params) {
        this.settings    = settings;
        this.preferences = preferences;
        this.params      = params;
    }

    /**
     * Starts the Render
     * @param {Object} data
     * @param {String} url
     * @returns {Void}
     */
    startRender(data, url) {
        this.data          = data;
        this.crumbs        = [];
        this.category      = "";
        this.categories    = [];
        this.subcategory   = "";
        this.subcategories = [];
        this.tercategory   = "";
        this.tercategories = [];
        this.prices        = data.prices  || [];
        this.amounts       = data.amounts || [];

        this.url           = url;
        this.baseUrl       = url;
        this.catUrl        = url;
        this.subUrl        = url;
        this.terUrl        = url;
    }

    /**
     * Fetches the Data
     * @returns {Void}
     */
    fetch = () => {
        this.fetchFunc(this.getParams());
    }

    /**
     * Returns the Url
     * @param {String=} type
     * @param {String=} param
     * @returns {String}
     */
    createUrl = (type, param) => {
        let url = this.baseUrl;
        if (type === "category") {
            url = this.catUrl + (param ? `/${param}` : "");
        } else if (type === "subcategory") {
            url = this.subUrl + (param ? `/${param}` : "");
        } else if (type === "tercategory") {
            url = this.terUrl + (param ? `/${param}` : "");
        }
        return url;
    }



    /**
     * Adds a Crumb
     * @param {String} to
     * @param {String} name
     * @returns {Void}
     */
    addCrumb(to, name) {
        this.crumbs.push({ to, name });
    }

    /**
     * Adds a Crumb if there are Categories
     * @param {String} category
     * @returns {Void}
     */
    addCategory(category) {
        this.catUrl     = this.baseUrl;
        this.category   = category;
        this.categories = this.data.categories;

        if (category && this.data.category) {
            this.baseUrl += `/${this.data.category.slug}`;
            this.crumbs.push({
                to   : this.baseUrl,
                name : this.data.category.name,
            });
        }
    }

    /**
     * Adds a Crumb if there are Subcategories
     * @param {String} subcategory
     * @returns {Void}
     */
    addSubcategory(subcategory) {
        if (this.category && this.data.subcategories && this.data.subcategories.length) {
            this.subUrl       += `/${this.data.category.slug}`;
            this.terUrl       += `/${this.data.category.slug}`;
            this.subcategory   = subcategory;
            this.subcategories = this.data.subcategories;
        }
        if (subcategory && this.data.subcategory) {
            this.baseUrl += `/${this.data.subcategory.slug}`;
            this.crumbs.push({
                to   : this.baseUrl,
                name : this.data.subcategory.name,
            });
        }
    }

    /**
     * Adds a Crumb if there are Tercategories
     * @param {String} tercategory
     * @returns {Void}
     */
    addTercategory(tercategory) {
        if (this.category && this.data.tercategories && this.data.tercategories.length) {
            this.terUrl       += `/${this.data.subcategory.slug}`;
            this.tercategory   = tercategory;
            this.tercategories = this.data.tercategories;
        }
        if (tercategory && this.data.tercategory) {
            this.baseUrl += `/${this.data.tercategory.slug}`;
            this.crumbs.push({
                to   : this.baseUrl,
                name : this.data.tercategory.name,
            });
        }
    }

    /**
     * Returns true if there are Filters
     * @returns {Boolean}
     */
    hasFilters() {
        const { showOfferFilter, showCategoryFilter, showPriceFilter } = this.settings;
        const showAmounts = Boolean(showOfferFilter    && this.amounts       && this.amounts.length);
        const showCats    = Boolean(showCategoryFilter && this.categories    && this.categories.length);
        const showSubs    = Boolean(showCategoryFilter && this.subcategories && this.subcategories.length && this.category);
        const showTers    = Boolean(showCategoryFilter && this.tercategories && this.tercategories.length && this.category && this.subcategory);
        const showPrice   = Boolean(showPriceFilter    && this.prices        && this.prices[1]);
        return showAmounts || showCats || showSubs || showTers || showPrice;
    }



    /**
     * Returns the Params for the Fetch
     * @param {Object=} params
     * @returns {Object}
     */
    getParams(params = this.params) {
        const initials = this.getInitialParams();
        if (params.page && typeof params.page === "string") {
            params.page = Number(params.page);
        }
        return Utils.extend(initials, params);
    }

    /**
     * Returns the Initial Params for the Fetch
     * @returns {Object}
     */
    getInitialParams() {
        const result = {
            category    : "",
            subcategory : "",
            tercategory : "",
            page        : 1,
            sort        : this.initialSort || this.preferences.sort,
            price       : this.preferences.price,
            amount      : this.preferences.amount,
        };
        if (this.paramKey) {
            result[this.paramKey] = "";
        }
        return result;
    }
}
