// Types
import {
    STORE_DATA, STORE_UPDATES,
    NOTIFICATION_DISCARDED, NOTIFICATION_REDIRECT,
} from "Utils/Types";



// The initial State
const initialState = {
    error    : false,
    list     : [],
    redirect : "",
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (action.data && action.data.error) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case STORE_DATA:
        return {
            ...state,
            error : false,
            list  : action.data.notifications,
        };
    case STORE_UPDATES: {
        const notifications = state.list.slice(0);
        for (const newElem of action.data.notifications) {
            let found = false;
            for (const [ index, oldElem ] of notifications.entries()) {
                if (newElem.key === oldElem.key) {
                    notifications[index] = newElem;
                    found = true;
                    break;
                }
            }
            if (!found) {
                notifications.push(newElem);
            }
        }
        notifications.sort((a, b) => {
            if (a.order === b.order) {
                return a.time - b.time;
            }
            return a.order - b.order;
        });

        return {
            ...state,
            error : false,
            list  : notifications,
        };
    }
    
    // Notification Actions
    case NOTIFICATION_DISCARDED: {
        const notifications = state.list.slice(0);
        notifications.splice(action.data, 1);
        return {
            ...state,
            error : false,
            list  : notifications,
        };
    }
    case NOTIFICATION_REDIRECT:
        return {
            ...state,
            error    : false,
            redirect : action.data,
        };
    
    default:
        return state;
    }
};
