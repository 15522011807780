import Utils            from "Utils/Common/Utils";

// Types
import {
    PRODUCT_LOADING, PRODUCT_CATEGORIES, PRODUCT_LIST,
    PRODUCT_SEARCH, PRODUCT_IMPORTANTS, PRODUCT_NEWESTS,
    PRODUCT_FAVORITES, PRODUCT_PURCHASES, PRODUCT_HISTORY,
    PRODUCT_DIALOG, PRODUCT_PREVIEW, STORE_FAVORITE,
} from "Utils/Types";



// The product State
const productState = {
    name          : "GENERAL_LOADING",
    banner        : "",
    logo          : "",
    url           : "",
    category      : {},
    categories    : [],
    subcategory   : {},
    subcategories : [],
    tercategory   : {},
    tercategories : [],
    products      : [],
    prices        : {},
    results       : 0,
    total         : 0,
    pages         : 1,
};

// The initial State
const initialState = {
    loading    : true,
    error      : false,
    categories : [],
    products   : { ...productState },
    search     : { ...productState },
    newests    : { ...productState },
    importants : { ...productState },
    favorites  : { ...productState },
    purchases  : { ...productState },
    history    : { ...productState },
    product    : {},
    preview    : null,
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, PRODUCT_CATEGORIES, PRODUCT_LIST, PRODUCT_SEARCH, PRODUCT_NEWESTS, PRODUCT_FAVORITES, PRODUCT_PURCHASES, PRODUCT_HISTORY)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case PRODUCT_LOADING:
        return {
            ...state,
            loading    : true,
        };

    case PRODUCT_CATEGORIES:
        return {
            ...state,
            loading    : false,
            error      : false,
            categories : action.data,
        };
    
    case PRODUCT_LIST:
        return {
            ...state,
            loading    : false,
            error      : false,
            products   : action.data,
        };
    
    case PRODUCT_SEARCH:
        return {
            ...state,
            loading    : false,
            error      : false,
            search     : action.data,
        };
    
    case PRODUCT_NEWESTS:
        return {
            ...state,
            loading    : false,
            error      : false,
            newests    : action.data,
        };
    
    case PRODUCT_IMPORTANTS:
        return {
            ...state,
            loading    : false,
            error      : false,
            importants : action.data,
        };
    
    case PRODUCT_FAVORITES:
        return {
            ...state,
            loading   : false,
            error     : false,
            favorites : action.data,
        };
    
    case PRODUCT_PURCHASES:
        return {
            ...state,
            loading   : false,
            error     : false,
            purchases : action.data,
        };
    
    case PRODUCT_HISTORY:
        return {
            ...state,
            loading : false,
            error   : false,
            history : action.data,
        };

    // Set the Product Dilaog/Preview
    case PRODUCT_DIALOG:
        return {
            ...state,
            product : action.data || {},
        };
    case PRODUCT_PREVIEW:
        return {
            ...state,
            preview : action.data,
        };
    
    // Update the Favorite
    case STORE_FAVORITE:
        return {
            ...state,
            error      : false,
            products   : Utils.setFavorite(state.products,   action.data.productID),
            search     : Utils.setFavorite(state.search,     action.data.productID),
            newests    : Utils.setFavorite(state.newests,    action.data.productID),
            importants : Utils.setFavorite(state.importants, action.data.productID),
            favorites  : Utils.setFavorite(state.favorites,  action.data.productID),
            purchases  : Utils.setFavorite(state.purchases,  action.data.productID),
            history    : Utils.setFavorite(state.history,    action.data.productID),
        };

    default:
        return state;
    }
};
