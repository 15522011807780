import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Url                  from "Utils/App/Url";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Category Menu
 */
class CategoryMenu extends React.Component {
    // The Current State
    state = {
        showSub     : false,
        categoryID  : 0,
        categoryIdx : 0,
    };

    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, categories, toggleMenu } = this.props;
        if (categories.length > 0 && open && !prevProps.open) {
            toggleMenu(true);
            this.showCategory(categories[0].id, 0);
        }
    }

    /**
     * Shows the Category SubMenu
     * @param {Number} id
     * @param {Number} index
     * @returns {Void}
     */
    showCategory = (id, index) => {
        this.setState({
            showSub     : true,
            categoryID  : id,
            categoryIdx : index,
        });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, settings, categories, onEnter, onLeave, onClose } = this.props;
        const { showSub, categoryID, categoryIdx                      } = this.state;

        if (!open || !categories.length) {
            return <React.Fragment />;
        }

        const hasTer   = settings.hasTercategories;
        const category = showSub ? categories[categoryIdx] : {};
        const showSubs = Boolean(showSub && category && category.childs.length);

        return <nav
            className="submenu-container submenu-category"
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className={`submenu-category-list ${showSub ? "submenu-with-sub" : ""} no-list`}>
                {categories.map((elem, index) => <li
                    key={elem.id}
                    className={categoryID === elem.id ? "submenu-selected" : ""}
                >
                    <HyperLink
                        variant="none"
                        href={`${Url.PRODUCTS}/${elem.url}`}
                        message={elem.name}
                        afterIcon={elem.childs.length > 0 ? "right" : ""}
                        onClick={onClose}
                        onMouseEnter={() => this.showCategory(elem.id, index)}
                    />
                </li>)}
            </ul>
            {showSubs && <div className={"submenu-category-subs" + (hasTer ? " submenu-category-ters" : "")}>
                <h2>{category.name}</h2>
                <ul className="no-list">
                    {category.childs.map((elem) => <li key={elem.id}>
                        <HyperLink
                            variant="opacity"
                            href={`${Url.PRODUCTS}/${category.url}/${elem.url}`}
                            message={elem.name}
                            onClick={onClose}
                        />
                        {elem.childs.length > 0 && <ul className="no-list">
                            {elem.childs.map((subelem) => <li key={subelem.id}>
                                <HyperLink
                                    variant="opacity"
                                    href={`${Url.PRODUCTS}/${category.url}/${elem.url}/${subelem.url}`}
                                    message={subelem.name}
                                    onClick={onClose}
                                />
                            </li>)}
                        </ul>}
                    </li>)}
                </ul>
            </div>}
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu : PropTypes.func.isRequired,
        settings   : PropTypes.object.isRequired,
        categories : PropTypes.array.isRequired,
        open       : PropTypes.bool.isRequired,
        onEnter    : PropTypes.func.isRequired,
        onLeave    : PropTypes.func.isRequired,
        onClose    : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings   : state.core.settings,
            categories : state.store.categories,
        };
    }
}

export default connect(CategoryMenu.mapStateToProps, {
    toggleMenu,
})(CategoryMenu);
