import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPage }          from "Actions/Client/ClientActions";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import AddressDelete        from "Components/Client/Address/AddressDelete";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Pagination           from "Components/Utils/Common/Pagination";
import Table                from "Components/Utils/Table/Table";
import TableHead            from "Components/Utils/Table/TableHead";
import TableBody            from "Components/Utils/Table/TableBody";
import TableRow             from "Components/Utils/Table/TableRow";
import TableCell            from "Components/Utils/Table/TableCell";
import Alert                from "Components/Utils/Form/Alert";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    fetchAddresses, ackAddress,
} from "Actions/Client/AddressActions";

// Styles
import "Styles/Components/Client/Address.css";



/**
 * The Address List
 */
class AddressList extends React.Component {
    // The Current State
    state = {
        showDialog : "",
        elemID     : 0,
        success    : "",
        error      : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetchAddresses();
    }

    /**
     * Close the Alerts
     * @returns {Void}
     */
    componentWillUnmount() {
        this.closeAlert();
    }

    /**
     * Load the Data if the Props change
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const oldParams = this.getParams(prevProps.match.params);
        const newParams = this.getParams(this.props.match.params);
        
        if (!Utils.areObjectsEqual(oldParams, newParams)) {
            this.fetchAddresses();
        }
    }

    /**
     * Fetches the Addresses
     * @returns {Void}
     */
    fetchAddresses() {
        const params = this.getParams();
        this.props.fetchAddresses(params);
        this.props.setPage(params.page);
    }

    /**
     * Returns the Params
     * @param {Object} params
     * @returns {Object}
     */
    getParams(params = this.props.match.params) {
        return { page : Number(params.page) || 1 };
    }



    /**
     * Opens a Dialog
     * @param {String} type
     * @param {Number} elemID
     * @returns {Function}
     */
    openDialog = (type, elemID) => (e) => {
        this.setState({ showDialog : Utils.mergeVCE(type), elemID });
        e.preventDefault();
    }

    /**
     * Closes the Dialog
     * @returns {Void}
     */
    closeDialog = () => {
        this.setState({ showDialog : "", elemID : 0 });
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
        this.props.ackAddress();
    }

    /**
     * Called after submitting the Dialog
     * @param {String=} success
     * @param {String=} error
     * @returns {Void}
     */
    onSubmit = (success = "", error = "") => {
        this.setState({ success, error });
        this.closeDialog();
        this.fetchAddresses();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, loading, list, total, edited } = this.props;
        const { showDialog, elemID, success, error     } = this.state;
        const { page                                   } = this.getParams();

        const successMsg = edited || success;
        const alertClass = successMsg || error ? "client-alerts" : "";

        return <>
            <SubTitle show={!isMobile} message="ADDRESSES_NAME" icon="address">
                <Button
                    variant="primary"
                    className="address-create"
                    message="ADDRESSES_CREATE"
                    href={Url.ADDRESSES_CREATE}
                />
            </SubTitle>
            <header className={alertClass}>
                <Alert variant="success" message={successMsg} onClose={this.closeAlert} />
                <Alert variant="error"   message={error}      onClose={this.closeAlert} />
            </header>

            <Table
                none="ADDRESSES_NONE_AVAILABLE"
                loading={loading}
                hasContent={!!list.length}
                hasSpacing
                hasActions
            >
                <TableHead>
                    <TableCell message="GENERAL_NAME"         />
                    <TableCell message="ADDRESSES_ADDRESS"    />
                    <TableCell message="ADDRESSES_LOCALITY"   />
                    <TableCell message="ADDRESSES_PROVINCE"   />
                    <TableCell message="ADDRESSES_ZIP_CODE"   />
                    <TableCell message="ADDRESSES_IS_DEFAULT" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow
                        key={elem.addressID}
                        href={`${Url.ADDRESSES_VIEW}/${elem.addressID}`}
                    >
                        <TableCell message={elem.name}         breakAfter isBold />
                        <TableCell message={elem.address}      breakAfter />
                        <TableCell message={elem.locality}     />
                        <TableCell message={elem.provinceName} />
                        <TableCell message={elem.zipCode}      breakAfter nowrap />
                        <TableCell
                            condition={elem.isDefault}
                            success="GENERAL_YES"
                            error="GENERAL_NO"
                            isStatus
                            hide
                        />
                        <TableCell
                            variant="red"
                            message="GENERAL_DELETE"
                            onClick={this.openDialog("delete", elem.addressID)}
                            isLink
                        />
                    </TableRow>)}
                </TableBody>
            </Table>
            <Pagination
                current={page}
                total={total}
                url={Url.ADDRESSES}
            />
            
            <Button
                variant="floating"
                className="address-floating"
                message="+"
                href={Url.ADDRESSES_CREATE}
            />
            <AddressDelete
                open={showDialog === "delete"}
                data={list}
                elemID={elemID}
                onSubmit={this.onSubmit}
                onClose={this.closeDialog}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAddresses : PropTypes.func.isRequired,
        ackAddress     : PropTypes.func.isRequired,
        setPage        : PropTypes.func.isRequired,
        match          : PropTypes.object.isRequired,
        isMobile       : PropTypes.bool.isRequired,
        loading        : PropTypes.bool.isRequired,
        list           : PropTypes.array.isRequired,
        total          : PropTypes.number.isRequired,
        edited         : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile : state.core.isMobile,
            loading  : state.address.loading,
            list     : state.address.list,
            total    : state.address.total,
            edited   : state.address.edited,
        };
    }
}

export default connect(AddressList.mapStateToProps, {
    fetchAddresses, ackAddress, setPage,
})(AddressList);
