import API                  from "Utils/App/API";

// Types
import {
    CONTACT_LOADING, CONTACT_ELEM,
} from "Utils/Types";



/**
 * Fetches the Brand List
 * @returns {Function}
 */
export function fetchContact() {
    return async (dispatch) => {
        dispatch({ type : CONTACT_LOADING });
        const data = await API.Contact.getContact();
        dispatch({ type : CONTACT_ELEM, data });
    };
}

/**
 * Sends a Contact
 * @param {Object} params
 * @returns {Function}
 */
export function sendContact(params) {
    return async () => {
        const data = await API.Contact.sendContact(params);
        return data.success;
    };
}
