import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "Utils/App/NLS";
import Url                  from "Utils/App/Url";

// Components
import ErrorPage            from "Components/Content/ErrorPage";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Content              from "Components/Utils/Common/Content";
import Card                 from "Components/Utils/Common/Card";
import Title                from "Components/Utils/Common/Title";
import SubTitle             from "Components/Utils/Common/SubTitle";
import HyperLink            from "Components/Utils/Common/HyperLink";
import MultiLine            from "Components/Utils/Common/MultiLine";
import Html                 from "Components/Utils/Common/Html";
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";

// Actions
import {
    fetchContact, sendContact,
} from "Actions/Content/ContactActions";

// Styles
import "Styles/Components/Content/Contact.css";



/**
 * The Contact Page
 */
class ContactPage extends React.Component {
    // The Initial Data
    static initialData = {
        name    : "",
        phone   : "",
        email   : "",
        subject : "",
        message : "",
    }
    
    // The Current State
    state = {
        data    : { ...ContactPage.initialData },
        loading : false,
        success : "",
        errors  : {},
        error   : "",
    }

    
    
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.settings.hasContact) {
            this.props.fetchContact();
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, errors : {}, error : "" });
        try {
            const success = await this.props.sendContact(data);
            this.setState({ success, loading : false, data : { ...ContactPage.initialData } });
        } catch (errors) {
            this.setState({ loading : false, success : "", errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ success : "", error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { charging, elem, isApp, isAuthenticated, settings } = this.props;
        const { data, loading, success, errors, error            } = this.state;
        
        if (!settings.hasContact) {
            return <ErrorPage />;
        }
        const backUrl      = isApp ? Url.ACCOUNT : "";
        const requestName  = !isAuthenticated && elem.requestName;
        const requestEmail = !isAuthenticated && elem.requestEmail;
        const requestPhone = !isAuthenticated && elem.requestPhone;

        const showContact  = Boolean(elem.showContact && (elem.phone || elem.whatsapp || elem.email || elem.address));
        const showMap      = Boolean(elem.showMap && elem.map);
        const showSocial   = Boolean(elem.showSocial && (elem.facebook || elem.twitter || elem.instagram || elem.youtube))

        return <>
            <Title message={elem.title || "CONTACT_NAME"} href={backUrl} />
            <Wrapper className="contact-container" withSpacing>
                <Content loading={charging} withBorder>
                    {!!elem.content && <Html
                        className="contact-content"
                        content={elem.content}
                    />}

                    <Card className="contact-message" withBorder>
                        <SubTitle message="CONTACT_MESSAGE" icon="email" smallPadding />
                        <Form
                            className="contact-form spacing"
                            columns="1"
                            success={success}
                            error={error}
                            onSubmit={this.handleSubmit}
                            onClose={this.closeAlert}
                        >
                            {requestName && <TextField
                                name="name"
                                label="CONTACT_YOUR_NAME"
                                value={data.name}
                                error={errors.name}
                                onChange={this.handleChange}
                                isRequired
                            />}
                            {requestEmail && <TextField
                                name="phone"
                                label="CONTACT_YOUR_PHONE"
                                value={data.phone}
                                error={errors.phone}
                                onChange={this.handleChange}
                                isRequired
                            />}
                            {requestPhone && <TextField
                                name="email"
                                label="CONTACT_YOUR_EMAIL"
                                value={data.email}
                                error={errors.email}
                                onChange={this.handleChange}
                                isRequired
                            />}
                            {elem.requestSubject && <TextField
                                name="subject"
                                label="CONTACT_YOUR_SUBJECT"
                                value={data.subject}
                                error={errors.subject}
                                onChange={this.handleChange}
                                isRequired
                            />}
                            <TextField
                                type="textarea"
                                name="message"
                                label="CONTACT_YOUR_MESSAGE"
                                value={data.message}
                                error={errors.message}
                                onChange={this.handleChange}
                                isRequired
                                autoGrow
                            />
                            <Button
                                variant="primary"
                                message="CONTACT_SEND"
                                isDisabled={loading}
                                fullWidth
                            />
                        </Form>
                    </Card>

                    <Card className="contact-data" withBorder>
                        <SubTitle message="CONTACT_DATA" icon="phone" smallPadding />
                        {showContact && <>
                            {!!elem.phone && <div className="contact-info">
                                <label>{NLS.get("CONTACT_PHONE")}</label>
                                <HyperLink
                                    variant="black"
                                    message={elem.phone}
                                    href={elem.phone}
                                    tel
                                />
                            </div>}
                            {!!elem.whatsapp && <div className="contact-info">
                                <label>{NLS.get("CONTACT_WHATSAPP")}</label>
                                <HyperLink
                                    variant="black"
                                    message={elem.whatsapp}
                                    href={elem.whatsapp}
                                    whatsapp
                                />
                            </div>}
                            {!!elem.email && <div className="contact-info">
                                <label>{NLS.get("CONTACT_EMAIL")}</label>
                                <HyperLink
                                    variant="black"
                                    message={elem.email}
                                    href={elem.email}
                                    email
                                />
                            </div>}
                            {!!elem.address && <div className="contact-info">
                                <label>{NLS.get("CONTACT_ADDRESS")}</label>
                                <MultiLine>{elem.address}</MultiLine>
                            </div>}
                        </>}

                        {showSocial && <div className="contact-info">
                            <label>{NLS.get("CONTACT_SOCIAL")}</label>
                            <ul className="contact-social no-list">
                                {!!elem.facebook && <li>
                                    <HyperLink
                                        className="facebook"
                                        variant="none"
                                        href={elem.facebook}
                                        icon="facebook"
                                    />
                                </li>}
                                {!!elem.twitter && <li>
                                    <HyperLink
                                        className="twitter"
                                        variant="none"
                                        href={elem.twitter}
                                        icon="twitter"
                                    />
                                </li>}
                                {!!elem.instagram && <li>
                                    <HyperLink
                                        className="instagram"
                                        variant="none"
                                        href={elem.instagram}
                                        icon="instagram"
                                    />
                                </li>}
                                {!!elem.youtube && <li>
                                    <HyperLink
                                        className="youtube"
                                        variant="none"
                                        href={elem.youtube}
                                        icon="youtube"
                                    />
                                </li>}
                            </ul>
                        </div>}
                    </Card>

                    {showMap && <Card className="contact-map" withBorder>
                        <div><iframe
                            title={NLS.get("CONTACT_MAP")}
                            src={elem.map}
                            width="500"
                            height="400"
                            frameBorder="0"
                            allowFullScreen=""
                        ></iframe></div>
                    </Card>}
                </Content>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchContact    : PropTypes.func.isRequired,
        sendContact     : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        isApp           : PropTypes.bool.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        charging        : PropTypes.bool.isRequired,
        elem            : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isApp           : state.core.isApp,
            isAuthenticated : state.auth.isAuthenticated,
            charging        : state.contact.loading,
            elem            : state.contact.elem,
        };
    }
}

export default connect(ContactPage.mapStateToProps, {
    fetchContact, sendContact,
})(ContactPage);
