import DateTime             from "Utils/Common/DateTime";
import Utils                from "Utils/Common/Utils";

// Types
import {
    STORE_DATA, STORE_UPDATES,
    CHAT_ELEM, CHAT_MESSAGES, CHAT_NEW_MESSAGES,
    CHAT_VIEWING,
} from "Utils/Types";



// The initial State
const initialState = {
    isViewing : false,
    error     : false,
    loaded    : false,
    hasMore   : false,
    elem      : {
        clientID               : 0,
        unreadClient           : 0,
        lastViewCredentialDate : "",
    },
    list     : [],
    messages : [],
};

/**
 * Adds new Messages to the old ones
 * @param {Array.<Object>} oldMessages
 * @param {Array.<Object>} newMessages
 * @returns {Array.<Object>}
 */
function addMessages(oldMessages, newMessages) {
    const messages = oldMessages.slice(0);
    if (newMessages && newMessages.length) {
        for (const newElem of newMessages) {
            let found = false;
            for (const [ index, oldElem ] of messages.entries()) {
                if (newElem.messageID === oldElem.messageID) {
                    messages[index] = newElem;
                    found = true;
                    break;
                }
            }
            if (!found) {
                messages.push(newElem);
            }
        }
    }
    return messages;
}

/**
 * Parses the Messages
 * @param {Object}         state
 * @param {Object}         chat
 * @param {Array.<Object>} messages
 * @returns {Array.<Object>}
 */
function parseMessages(state, chat, messages) {
    const result   = [];
    let showUnread = !state.isViewing;
    let lastDay    = "";
    let lastType   = "";
    let lastID     = 0;
    let index      = -1;

    for (const message of messages) {
        const thisDay    = DateTime.formatDate(message.createdTime, "dashes");
        const thisType   = message.credentialID ? "admin" : "client";
        const thisID     = message.credentialID ? message.credentialID : message.clientID;
        const isMine     = message.clientID && message.clientID === chat.clientID;
        const isNewDay   = thisDay !== lastDay;
        const isNewUser  = thisType !== lastType || thisID !== lastID;
        const newMessage = showUnread && message.createdTime > chat.lastViewClient && chat.unreadClient > 1;
        
        if (isNewDay || isNewUser || newMessage) {
            index        += 1;
            result[index] = {
                day       : isNewDay   ? DateTime.formatDay(message.createdTime) : "",
                unread    : newMessage ? chat.unreadClient : 0,
                className : isMine     ? "mine" : "yours",
                userName  : !isMine    ? message.credential : "",
                list      : [],
            };
            if (newMessage) {
                showUnread = false;
            }
        }
        result[index].list.push(message);

        lastDay  = thisDay;
        lastType = thisType;
        lastID   = thisID;
    }
    return result;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, CHAT_ELEM, CHAT_MESSAGES, CHAT_NEW_MESSAGES)) {
        return { ...state, error : true };
    }
    
    switch (action.type) {
    case STORE_DATA:
        return {
            ...state,
            error     : false,
            elem      : action.data.chat,
        };
    case STORE_UPDATES: {
        if (action.data.chat) {
            const messages = addMessages(state.messages, action.data.messages);
            return {
                ...state,
                error    : false,
                elem     : action.data.chat,
                list     : parseMessages(state, action.data.chat, messages),
                messages : messages,
            };
        }
        return { ...state };
    }
    
    // Chat Actions
    case CHAT_VIEWING:
        return {
            ...state,
            isViewing : action.isViewing,
        };
    case CHAT_ELEM:
        return {
            ...state,
            error     : false,
            elem      : action.data.chat,
            list      : parseMessages(state, action.data.chat, state.messages),
        };
    case CHAT_MESSAGES: {
        let messages = action.data.messages;
        if (state.loaded) {
            messages = messages.concat(state.messages);
        }
        return {
            ...state,
            error     : false,
            loaded    : true,
            hasMore   : action.data.hasMore,
            elem      : action.data.chat,
            list      : parseMessages(state, action.data.chat, messages),
            messages  : messages,
        };
    }
    case CHAT_NEW_MESSAGES: {
        const messages = addMessages(state.messages, action.data.messages);
        return {
            ...state,
            error     : false,
            elem      : action.data.chat,
            list      : parseMessages(state, action.data.chat, messages),
            messages  : messages,
        };
    }
    
    default:
        return state;
    }
};
