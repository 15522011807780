export default {
    
    // General
    GENERAL_LANGUAGES               : { "es" : "Español", "en" : "English" },
    GENERAL_LOADING                 : "Cargando ...",
    GENERAL_HOME                    : "Inicio",
    GENERAL_PREV                    : "Anterior",
    GENERAL_NEXT                    : "Siguiente",
    GENERAL_BACK                    : "Volver",
    GENERAL_NO                      : "No",
    GENERAL_YES                     : "Si",
    GENERAL_CREATE                  : "Crear",
    GENERAL_DAYS                    : "Días",
    GENERAL_DOWNLOAD                : "Descargar",
    GENERAL_EDIT                    : "Editar",
    GENERAL_EMAIL                   : "Email",
    GENERAL_EMAIL_CONFIRM           : "Confirmar email",
    GENERAL_NAME                    : "Nombre",
    GENERAL_SAVE                    : "Guardar",
    GENERAL_DELETE                  : "Eliminar",
    GENERAL_CANCEL                  : "Cancelar",
    GENERAL_HOURS                   : "Horas",
    GENERAL_MINUTES                 : "Minutos",
    GENERAL_MINUTES_SHORT           : "Min",
    GENERAL_PASSWORD                : "Contraseña",
    GENERAL_PASSWORD_CONFIRM        : "Confirmar contraseña",
    GENERAL_SECONDS                 : "Segundos",
    GENERAL_SECONDS_SHORT           : "Seg",
    GENERAL_SELECT_FILE             : "Seleccionar archivo",
    GENERAL_STATUS                  : "Estado",
    GENERAL_ERROR                   : "Ocurrió un error inesperado. Estamos trabajando en resolverlo.",
    

    // Auth
    AUTH_LOGIN_NAME                 : "Ingreso",
    AUTH_LOGIN_CODE                 : "Código de cliente / Email",
    AUTH_LOGIN_EMAIL                : "Email",
    AUTH_LOGIN_ACTION               : "Ingresar",
    AUTH_LOGIN_LINK                 : "Ingresar con mi cuenta",
    AUTH_LOGIN_RESETED              : "Ya puede ingresar con su nueva contraseña",
    AUTH_LOGIN_CREATED              : "Su cuenta fue creada y ya puede ingresar",
    AUTH_LOGIN_VALIDATE             : "Su cuenta fue creada, pero debe ser validadad. Le enviaremos un email cuando pueda ingresar.",
    AUTH_LOGIN_ENTER                : "Ingresar a la tienda",
    AUTH_RECOVER_NAME               : "Recuperar Contraseña",
    AUTH_RECOVER_CODE               : "Por favor, ingrese su número de cliente o email, y le enviaremos un email con un código y vínculo para que pueda generar su nueva contraseña.",
    AUTH_RECOVER_EMAIL              : "Por favor, ingrese su email, y le enviaremos un email con un código y vínculo para que pueda generar su nueva contraseña.",
    AUTH_RECOVER_ACTION             : "Recuperar",
    AUTH_RECOVER_LINK               : "Recuperar mi Contraseña",
    AUTH_RESET_NAME                 : "Resetear Contraseña",
    AUTH_RESET_TEXT                 : "Hemos enviado un mail con el código de reseteo al mail indicado. Por favor use ese código y complete una nueva contraseña para resetear su contraseña.",
    AUTH_RESET_CODE                 : "Código",
    AUTH_RESET_ACTION               : "Resetear",
    AUTH_REGISTER_NAME              : "Crear Usuario",
    AUTH_REGISTER_ACTION            : "Crear",
    AUTH_REGISTER_TITLE             : "¿Primera vez que ingresa?",
    AUTH_REGISTER_LINK              : "Crear mi Usuario",
    AUTH_TERMS1                     : "Operar en {0} implica aceptar los ",
    AUTH_TERMS2                     : "términos y condiciones",
    AUTH_TERMS3                     : " en los que se ofrece el servicio.",
    AUTH_ERROR_LOGIN                : "No tiene permiso para ingresar.",
    AUTH_ERROR_REGISTER             : "No tiene permiso para registrarse.",
    AUTH_ERROR_CODE                 : "Por favor, complete su código de cliente, CUIT o email.",
    AUTH_ERROR_EMAIL                : "Por favor, complete su email.",
    AUTH_ERROR_PASSWORD_EMPTY       : "Por favor, complete la contraseña.",
    AUTH_ERROR_PASSWORD_INVALID     : "La contraseña debe contener al menos 6 caracteres, una letra y un número.",
    AUTH_ERROR_PASSWORD_CONFIRM     : "Por favor, complete la confirmación de la contraseña.",
    AUTH_ERROR_PASSWORD_MATCH       : "Las contraseñas no coinciden.",
    AUTH_ERROR_CODE_EMPTY           : "Por favor, complete el código.",
    AUTH_ERROR_CODE_INVALID         : "El código indicado no existe.",
    AUTH_ERROR_SPAM                 : "Ha intentado demasiadas veces.",
    AUTH_ERROR_CREDENTIALS_CODE     : "El código/email o la contraseña es incorrecta.",
    AUTH_ERROR_CREDENTIALS_EMAIL    : "El email o la contraseña es incorrecta.",
    AUTH_ERROR_RECOVER              : "No fue posible encontrar un cliente con los datos provistos.",
    AUTH_SUCCESS_LOGIN              : "Bienvenido a la Tienda",
    AUTH_SUCCESS_RECOVER            : "Se le enviará un mail con su contraseña, en las próximas horas.",


    // Header
    HEADER_PHONE                    : "Venta telefónica",
    HEADER_PHONE_NUMBER             : "Venta telefónica: {0}",
    HEADER_WHATSAPP_NUMBER          : "Venta por whatsapp: {0}",
    HEADER_SEARCH                   : "¿Qué esta buscando?",
    HEADER_MORE                     : "Ver más",
    HEADER_WELCOME_CLIENT           : "¡Hola {0}!",
    HEADER_WELCOME_TITLE            : "¡Hola!",
    HEADER_WELCOME_LOGIN            : "Le recomendamos ingresar para usar todas las funcionalidades",
    HEADER_NOTIFICATIONS_SINGULAR   : "{0} notificación",
    HEADER_NOTIFICATIONS_PLURAL     : "{0} notificaciones",

    // Footer
    FOOTER_ABOUT_US                 : "Sobre Nosotros",
    FOOTER_STAY_CONNECTED           : "Seguí conectado",
    FOOTER_SITE_MAP                 : "Mapa del Sitio",
    FOOTER_COPYRIGHT                : "Copyright © {1} {0}. Todos los derechos reservados. Accesibilidad, Condiciones de uso, Aviso de privacidad, cookies y AdChoice.",



    // Categories
    CATEGORIES_NAME                 : "Categorías",
    CATEGORIES_SINGULAR             : "Categoría",
    CATEGORIES_TITLE                : "Listado de Categorías",
    CATEGORIES_ALL                  : "Todas",

    // Subcategories
    SUBCATEGORIES_NAME              : "Subcategorías",
    SUBCATEGORIES_SINGULAR          : "Subcategoría",
    SUBCATEGORIES_TITLE             : "Listado de Subcategorías",

    // Tercategories
    TERCATEGORIES_NAME              : "Tercategorías",
    TERCATEGORIES_SINGULAR          : "Tercategoría",
    TERCATEGORIES_TITLE             : "Listado de Tercategorías",
    
    // Offers
    OFFERS_NAME                     : "Ofertas",
    OFFERS_TITLE                    : "Nuestras Ofertas",
    OFFERS_FILTER                   : "Ofertas",
    OFFERS_NONE_AVAILABLE           : "No hay ofertas disponibles",
    OFFERS_BUY_MORE                 : "Mira nuestro listado de productos",
    
    // Brands
    BRANDS_NAME                     : "Marcas",
    BRANDS_SINGULAR                 : "Marca",
    BRANDS_TITLE                    : "Nuestras Marcas",
    BRANDS_NONE_AVAILABLE           : "No hay marcas disponibles",

    // Search
    SEARCH_RESULTS                  : "Resultados",
    SEARCH_TITLE                    : "Búsqueda \"{0}\"",

    // Newests
    NEWESTS_NAME                    : "Nuevos",
    NEWESTS_TITLE                   : "Productos nuevos",

    // Importants
    IMPORTANTS_NAME                 : "Destacados",
    IMPORTANTS_TITLE                : "Productos destacados",

    // Favorites
    FAVORITES_NAME                  : "Favoritos",
    FAVORITES_SINGULAR              : "Favorito",
    FAVORITES_TITLE                 : "Productos Favoritos",
    FAVORITES_ERROR_PRODUCT         : "El producto indicado no existe.",
    FAVORITES_SUCCESS_ADD           : "El producto se agregó correctamente.",
    FAVORITES_SUCCESS_REMOVE        : "El producto se quitó correctamente.",

    // Purchases
    PURCHASES_NAME                  : "Comprados",
    PURCHASES_TITLE                 : "Productos Comprados",

    // History
    HISTORY_NAME                    : "Historial",
    HISTORY_TITLE                   : "Productos Visitados",
    HISTORY_ERROR_PRODUCT           : "El producto indicado no existe.",
    HISTORY_SUCCESS_ADD             : "El producto se agregó correctamente.",
    


    // Products
    PRODUCTS_NAME                   : "Productos",
    PRODUCTS_TITLE                  : "Listado de productos",
    PRODUCTS_VIEW_TITLE             : "Ver producto",
    PRODUCTS_RESULTS_SINGULAR       : "1 producto",
    PRODUCTS_RESULTS_PLURAL         : "{0} productos",
    PRODUCTS_FILTER                 : "Filtrar",
    PRODUCTS_FILTER_TITLE           : "Filtrar por",
    PRODUCTS_SORT                   : "Ordenar por",
    PRODUCTS_SORT_POSITION          : "Posición",
    PRODUCTS_SORT_NAME_AZ           : "Nombre A-Z",
    PRODUCTS_SORT_NAME_ZA           : "Nombre Z-A",
    PRODUCTS_SORT_CODE_AZ           : "Código A-Z",
    PRODUCTS_SORT_CODE_ZA           : "Código Z-A",
    PRODUCTS_SORT_LOWER_PRICE       : "Menor precio",
    PRODUCTS_SORT_GREATER_PRICE     : "Mayor precio",
    PRODUCTS_SORT_NEWER_PRODUCT     : "Nuevo a viejo",
    PRODUCTS_SORT_OLDER_PRODUCT     : "Viejo a nuevo",
    PRODUCTS_TYPE                   : "Tipo de producto",
    PRODUCTS_TYPE_ALL               : "Todos",
    PRODUCTS_TYPE_NATIONAL          : "Nacional",
    PRODUCTS_TYPE_IMPORTED          : "Importado",
    PRODUCTS_PRICE                  : "Precio",
    PRODUCTS_PRICE_ALL              : "Todos",
    PRODUCTS_PRICE_TO               : "Hasta {0}",
    PRODUCTS_PRICE_RANGE            : "De {0} a {1}",
    PRODUCTS_PRICE_FROM             : "Desde {0}",
    PRODUCTS_IS_NEW                 : "Nuevo",
    PRODUCTS_DISCOUNT               : "{0}% OFF",
    PRODUCTS_NO_DISCOUNT            : "Sin descuento",
    PRODUCTS_NO_STOCK               : "No hay stock disponible",
    PRODUCTS_UNAVAILABLE            : "Agotado",
    PRODUCTS_ADD_AMOUNT             : "Cantidad",
    PRODUCTS_ADD_TO_CART            : "Agregar al carrito",
    PRODUCTS_SHOW_TO_CART           : "Ver carrito de compras",
    PRODUCTS_BUY_MORE               : "Seguir comprando",
    PRODUCTS_SHOW_MORE              : "Seguir leyendo",
    PRODUCTS_SHOW_LESS              : "Ocultar descripción",
    PRODUCTS_STOCK_SINGULAR         : "Tenemos {0} unidad de este producto.",
    PRODUCTS_STOCK_PLURAL           : "Tenemos {0} unidades de este producto.",
    PRODUCTS_NO_IVA                 : "Los precios de los productos no incluyen IVA.",
    PRODUCTS_NONE_AVAILABLE         : "No hay productos disponibles",

    // Banner
    BANNER_ONLY                     : "Solo X",
    BANNER_HOURS_SINGULAR           : "{0} Hora",
    BANNER_HOURS_PLURAL             : "{0} Horas",
    BANNER_DAYS_SINGULAR            : "{0} Día",
    BANNER_DAYS_PLURAL              : "{0} Días",
    BANNER_BEFORE                   : "Antes",
    BANNER_SHOW                     : "Ver Oferta",



    // Cart
    CART_TITLE                      : "Carrito de compras",
    CART_TITLE_FULL                 : "Carrito de compras ({0})",
    CART_PRODUCTS_SINGULAR          : "{0} producto",
    CART_PRODUCTS_PLURAL            : "{0} productos",
    CART_PURCHASES                  : "Mis compras",
    CART_PURCHASES_IN               : "Mis compras en: {0}",
    CART_AMOUNT                     : "Cantidad",
    CART_DELETE                     : "Eliminar",
    CART_PRICE                      : "Precio unitario",
    CART_SUBTOTAL                   : "Sub-total",
    CART_DISCOUNT_UNITS             : "Obtiene un descuento por comprar {0} o más unidades",
    CART_DISCOUNT_TOTAL             : "Obtiene un descuento por comprar por un total de {1}{0} o más",
    CART_DISCOUNT_NEXT_UNITS        : "Puede obtener un descuento por comprar {0} o más unidades",
    CART_DISCOUNT_NEXT_TOTAL        : "Puede obtener un descuento por comprar por un total de {1}{0} o más",
    CART_STOCK                      : "Solo quedan {0} unidades en promoción. El resto tienen el precio de lista",
    CART_SUMMARY                    : "Resumen de la orden",
    CART_TOTAL                      : "Total en {0}",
    CART_UNITS_SINGULAR             : "Total por {0} unidad",
    CART_UNITS_PLURAL               : "Total por {0} unidades",
    CART_DISCOUNT                   : "Descuento de {0}%",
    CART_FINAL_PRICE                : "Precio final",
    CART_SHIPMENT_TITLE             : "¿Qué tipo de envío prefiere?",
    CART_SHIPMENT_VARIANT           : "¿Qué variante de envío prefiere?",
    CART_SHIPMENT_HELP              : "Haga click en un tipo de envío",
    CART_SHIPMENT_SELECTED          : "Tipo de envio",
    CART_SHIPMENT_PRICE             : "Precio del envio",
    CART_SUBSIDIARY_TITLE           : "¿Dónde quiere buscar el pedido?",
    CART_SUBSIDIARY_SELECTED        : "Sucursal",
    CART_ADDRESS_TITLE              : "¿Dónde quiere recibir el pedido?",
    CART_ADDRESS_EDIT               : "Editar domicilio",
    CART_ADDRESS_ADD_FIRST          : "Agregue un domicilio de entrega",
    CART_ADDRESS_ADD_NEW            : "Agregar otro domicilio",
    CART_ADDRESS_NONE_HELP          : "Por favor, agregue un domicilio de entrega",
    CART_ADDRESS_SELECTED           : "Domicilio",
    CART_TYPE_TITLE                 : "¿Que envío desea utilizar?",
    CART_PAYMENT_TITLE              : "¿Qué tipo de pago prefiere?",
    CART_PAYMENT_HELP               : "Haga click en un tipo de pago",
    CART_PAYMENT_DATA               : "Datos sobre el pago",
    CART_PAYMENT_SELECTED           : "Tipo de pago",
    CART_PAYMENT_DIALOG             : "Complete el pago",
    CART_MESSAGE                    : "Puede dejarnos un mensaje",
    CART_WRITE_MESSAGE              : "Mi mensaje",
    CART_CONFIRM_PRODUCTS           : "Confirmar productos",
    CART_CONFIRM_SHIPMENT           : "Confirmar envío",
    CART_CONFIRM_PAYMENT            : "Confirmar compra",
    CART_ADD_PRODUCTS               : "Agregar más productos",
    CART_EDIT_PRODUCTS              : "Modificar productos",
    CART_EDIT_SHIPMENT              : "Modificar envío",
    CART_BUY_MORE                   : "Agregar productos ahora",
    CART_RESERVED_MINS_SINGULAR     : "Tiene {0} minuto para terminar la compra.",
    CART_RESERVED_MINS_PLURAL       : "Tiene {0} minutos para terminar la compra.",
    CART_RESERVED_SECS_SINGULAR     : "Tiene {0} segundo para terminar la compra.",
    CART_RESERVED_SECS_PLURAL       : "Tiene {0} segundos para terminar la compra.",
    CART_RESERVED_NONE              : "Se le acabo el tiempo para terminar la compra. Puede volver a confirmar los productos y repetiro los pasos.",
    CART_NONE_AVAILABLE             : "No hay productos en el carrito",
    CART_AUTH_HELP                  : "Debe tener una cuenta para agregar continuar con la compra.",
    CART_COMPLETED_TITLE            : "¡Muchas gracias por su compra!",
    CART_COMPLETED_CONTACT          : "Será contactado en la brevedad por alguno de nuestros representantes.",
    CART_COMPLETED_NEXT             : "Ahora puede:",
    CART_COMPLETED_PENDING          : "Recuerde completar el pago del pedido.",
    CART_COMPLETED_HOURS            : "Tiene {0} horas para completar el pago del pedido.",
    CART_COMPLETED_METHOD           : "Tiene {0} horas para completar el pago por {1}.",
    CART_COMPLETED_NOTICE           : "Tenga en cuenta que su pedido se enviará luego de que se confirme su pago.",
    CART_COMPLETED_ORDER            : "Ver mi pedido",
    CART_COMPLETED_MORE             : "Seguir comprando",
    CART_ERROR_EXISTS               : "No hay un carrito creado.",
    CART_ERROR_EMPTY                : "No hay productos en el carrito creado.",
    CART_ERROR_STOCK                : "No hay suficiente stock para los productos indicados.",
    CART_ERROR_STOCK_ONE            : "No hay suficiente stock para este producto.",
    CART_ERROR_STOCK_LEFT           : "Solo quedan {0} unidades de este producto.",
    CART_ERROR_RESERVED             : "Se acabo el tiempo de reserva, debe volver a confirmar los productos.",
    CART_ERROR_PRODUCT_ALLOW        : "No tiene permiso para comprar.",
    CART_ERROR_PRODUCT_EMPTY        : "Por favor, complete el producto.",
    CART_ERROR_PRODUCT_EXISTS       : "El producto indicado no existe.",
    CART_ERROR_VARIANT_EXISTS       : "La variante indicada no existe.",
    CART_ERROR_AMOUNT               : "Por favor, indique la cantidad como un número mayor a cero.",
    CART_ERROR_VARIANT_AMOUNT       : "Por favor, indique la cantidad de al menos una variante como un número mayor a cero.",
    CART_ERROR_SHIPMENT             : "Por favor, indique el tipo de envío.",
    CART_ERROR_SHIPMENT_SUBSIDIARY  : "Por favor, indique en que sucursal desea buscar los productos.",
    CART_ERROR_SHIPMENT_TRANSPORT   : "No tiene permiso para indicar el tipo de transporte.",
    CART_ERROR_SHIPMENT_TYPE        : "El tipo de envío indicado no existe.",
    CART_ERROR_SHIPMENT_VARIANT     : "La variante de envío indicado no existe.",
    CART_ERROR_SHIPMENT_ADDRESS     : "Por favor, indique un domicilio de envío.",
    CART_ERROR_SHIPMENT_PRICE       : "Lamentablemente no tenemos envíos disponibles para el Código Postal indicado. Le recomendamos que verifique que el código postal sea el correcto, o utilice otra dirección de entrega.",
    CART_ERROR_PAYMENT              : "Por favor, indique el tipo de pago.",
    CART_ERROR_CHECKOUT             : "No fue posible procesar el medio de pago.",
    CART_SUCCESS_ADD                : "¡Se agrego a tu carrito de compras!",
    CART_SUCCESS_EDIT               : "Se modificó la cantidad del producto.",
    CART_SUCCESS_REMOVE             : "Se quitó el producto del carrito.",
    CART_SUCCESS_PRODUCTS           : "Se confirmaron los products del carrito correctamente.",
    CART_SUCCESS_SHIPMENT           : "Se confirmo el envío del carrito correctamente.",
    CART_SUCCESS_PAYMENT            : "Se confirmo el pago del carrito correctamente.",
    CART_SUCCESS_UNCONFIRM          : "Se desconfirmó el carrito correctamente.",



    // Catalog
    CATALOG_NAME                    : "Catálogo",
    CATALOG_PAGE                    : "Página {0} de {1}",
    
    // News
    NEWS_NAME                       : "Novedades",
    NEWS_VIEW                       : "Ver novedad",
    NEWS_NONE_AVAILABLE             : "No hay novedades disponibles",

    // Contact
    CONTACT_NAME                    : "Contactanos",
    CONTACT_MESSAGE                 : "Déjanos tu mensaje",
    CONTACT_YOUR_NAME               : "Su nombre y apellido",
    CONTACT_YOUR_PHONE              : "Su teléfono/celular",
    CONTACT_YOUR_EMAIL              : "Su email",
    CONTACT_YOUR_SUBJECT            : "Asunto del mensaje",
    CONTACT_YOUR_MESSAGE            : "Mensaje",
    CONTACT_SEND                    : "Enviar",
    CONTACT_DATA                    : "Datos de contacto",
    CONTACT_PHONE                   : "Teléfono",
    CONTACT_WHATSAPP                : "WhatsApp",
    CONTACT_EMAIL                   : "Email",
    CONTACT_ADDRESS                 : "Dirección",
    CONTACT_SOCIAL                  : "Redes sociales",
    CONTACT_MAP                     : "Mapa",
    CONTACT_ERROR_ACTIVE            : "El contacto no esa acitvado y no puede ser usado.",
    CONTACT_ERROR_NAME              : "Debe indicar su nombre y apellido.",
    CONTACT_ERROR_PHONE             : "Debe indicar su teléfono/cerlular.",
    CONTACT_ERROR_EMAIL_EMPTY       : "Debe indicar su email.",
    CONTACT_ERROR_EMAIL_INVALID     : "El email indicado es incorrecto.",
    CONTACT_ERROR_SUBJECT           : "Debe indicar el asunto.",
    CONTACT_ERROR_MESSAGE           : "Debe indicar el mensaje.",
    CONTACT_SUCCESS_SEND            : "Gracias por su consulta. Nos prondremos en contacto a la brevedad.",

    // Error
    ERROR_TITLE                     : "404",
    ERROR_MESSAGE                   : "Parece que la página no existe",
    ERROR_LINK                      : "Ir a la página principal",
    

    
    // Client
    CLIENT_NAME                     : "Mi Cuenta",
    CLIENT_LOGOUT                   : "Salir",
    
    // Client: Account
    ACCOUNT_NAME                    : "Datos Personales",
    ACCOUNT_SOCIAL_REASON           : "Razón social",
    ACCOUNT_IVA_CATEGORY            : "Categoría de IVA",
    ACCOUNT_CUIT                    : "CUIT",
    ACCOUNT_DNI                     : "DNI",
    ACCOUNT_FANTASY_NAME            : "Nombre de fantasía",
    ACCOUNT_FIRST_NAME              : "Nombre",
    ACCOUNT_LAST_NAME               : "Apellido",
    ACCOUNT_PHONE                   : "Teléfono",
    ACCOUNT_CELLPHONE               : "Celular / WhatsApp",
    ACCOUNT_ADDRESS                 : "Dirección",
    ACCOUNT_ZIP_CODE                : "Código Postal",
    ACCOUNT_PROVINCE                : "Provincia",
    ACCOUNT_LOCALITY                : "Localidad / Barrio",
    ACCOUNT_NEW_PASSWORD            : "Nueva contraseña",
    ACCOUNT_PASSWORD_CONFIRM        : "Confirmar contraseña",
    ACCOUNT_ERROR_SOCIAL_REASON     : "Por favor, complete la razón social.",
    ACCOUNT_ERROR_IVA_EMPTY         : "Por favor, complete la categoría de IVA.",
    ACCOUNT_ERROR_IVA_INVALID       : "La categoría de IVA indicada es inválida.",
    ACCOUNT_ERROR_CUIT_EMPTY        : "Por favor, complete el CUIT.",
    ACCOUNT_ERROR_CUIT_INVALID      : "El CUIT indicado es inválido.",
    ACCOUNT_ERROR_DNI_EMPTY         : "Por favor, complete el DNI.",
    ACCOUNT_ERROR_DNI_INVALID       : "El DNI indicado es inválido.",
    ACCOUNT_ERROR_FANTASY           : "Por favor, complete el {0}.",
    ACCOUNT_ERROR_FANTASY_NAME      : "Por favor, complete el nombre de fantasía.",
    ACCOUNT_ERROR_FIRST_NAME        : "Por favor, complete el nombre del responsable.",
    ACCOUNT_ERROR_LAST_NAME         : "Por favor, complete el apellido del responsable.",
    ACCOUNT_ERROR_EMAIL_EMPTY       : "Por favor, complete el email.",
    ACCOUNT_ERROR_EMAIL_INVALID     : "El email indicado es inválido.",
    ACCOUNT_ERROR_EMAIL_CONFIRM     : "Por favor, complete la confirmación del email.",
    ACCOUNT_ERROR_EMAIL_MATCH       : "Los emails no coinciden.",
    ACCOUNT_ERROR_PHONE             : "Por favor, complete el teléfono o el celular.",
    ACCOUNT_ERROR_ADDRESS           : "Por favor, complete el domicilio.",
    ACCOUNT_ERROR_ZIP_CODE          : "Por favor, complete el código postal.",
    ACCOUNT_ERROR_LOCALITY          : "Por favor, complete la localidad / barrio.",
    ACCOUNT_ERROR_PROVINCE_EMPTY    : "Por favor, complete la provincia.",
    ACCOUNT_ERROR_PROVINCE_EXISTS   : "La provincia indicada no existe.",
    ACCOUNT_ERROR_PASSWORD_INVALID  : "La contraseña debe ser entre 6 y 20 caracteres y contener al menos un número y una letra.",
    ACCOUNT_ERROR_PASSWORD_CONFIRM  : "Debe confirmar la nueva contraseña.",
    ACCOUNT_ERROR_PASSWORD_MATCH    : "Las contraseñas son diferentes.",
    ACCOUNT_SUCCESS_EDIT            : "Los datos de la cuenta se editaron correctamente.",

    // Client: Addresses
    ADDRESSES_NAME                  : "Domicilios de Entrega",
    ADDRESSES_SINGULAR              : "Domicilio de Entrega",
    ADDRESSES_CREATE                : "Agregar domicilio de entrega",
    ADDRESSES_EDIT                  : "Editar domicilio de entrega",
    ADDRESSES_DELETE                : "Eliminar domicilio de entrega",
    ADDRESSES_ALIAS                 : "Alias",
    ADDRESSES_ADDRESS               : "Dirección",
    ADDRESSES_STREET                : "Calle",
    ADDRESSES_STREET_NUMBER         : "Número",
    ADDRESSES_BETWEEN_STREETS       : "Entre calles",
    ADDRESSES_FLOOR                 : "Piso",
    ADDRESSES_APPARTMENT            : "Departamento",
    ADDRESSES_LOCALITY              : "Localidad / Barrio",
    ADDRESSES_PROVINCE              : "Provincia",
    ADDRESSES_ZIP_CODE              : "Código Postal",
    ADDRESSES_CONTACT_NAME          : "Nombre del destinatario",
    ADDRESSES_CONTACT_HELP          : "Puede indicar quien recibe el envío",
    ADDRESSES_DNI                   : "DNI del destinatario",
    ADDRESSES_EMAIL                 : "Email del destinatario",
    ADDRESSES_PHONE                 : "Celular del destinatario",
    ADDRESSES_SCHEDULE              : "Horario de entrega",
    ADDRESSES_IS_DEFAULT            : "Es Principal",
    ADDRESSES_NO_DEFAULT            : "Secundaria",
    ADDRESSES_PENDING               : "El domicilio de entrega tiene una modificación pendiente.",
    ADDRESSES_DELETE_TEXT           : "¿Realmente desea eliminar el domicilio de entrega \"{0}\"?",
    ADDRESSES_NONE_AVAILABLE        : "No hay domicilios de entrega disponibles",
    ADDRESSES_ERROR_EXISTS          : "No existe el domicilio de entrega indicada.",
    ADDRESSES_ERROR_NAME            : "Por favor, complete el nombre.",
    ADDRESSES_ERROR_STREET          : "Por favor, complete la calle.",
    ADDRESSES_ERROR_STREET_NUMBER   : "Por favor, complete el número de calle.",
    ADDRESSES_ERROR_STREET_INVALID  : "El número de calle debe ser un número.",
    ADDRESSES_ERROR_FLOOR           : "El piso debe ser un número.",
    ADDRESSES_ERROR_LOCALITY        : "Por favor, complete la localidad  / barrio.",
    ADDRESSES_ERROR_PROVINCE_EMPTY  : "Por favor, complete la provincia.",
    ADDRESSES_ERROR_PROVINCE_EXISTS : "La provincia indicada no existe.",
    ADDRESSES_ERROR_ZIP_CODE        : "Por favor, complete el código postal.",
    ADDRESSES_ERROR_DNI_EMPTY       : "Por favor, complete el dni.",
    ADDRESSES_ERROR_DNI_INVALID     : "El DNI indicado es inválido.",
    ADDRESSES_ERROR_PHONE           : "Por favor, complete el celular.",
    ADDRESSES_ERROR_EMAIL_EMPTY     : "Por favor, complete el email.",
    ADDRESSES_ERROR_EMAIL_INVALID   : "El email indicado es inválido.",
    ADDRESSES_ERROR_IS_DEFAULT      : "Debe tener un domicilio de entrega principal.",
    ADDRESSES_ERROR_DELETE          : "No puede eliminar el domicilio de entrega principal.",
    ADDRESSES_SUCCESS_CREATE        : "El domicilio de entrega se creó correctamente.",
    ADDRESSES_SUCCESS_EDIT          : "El domicilio de entrega se editó correctamente.",
    ADDRESSES_SUCCESS_DELETE        : "El domicilio de entrega se eliminó correctamente.",

    // Client: Vouchers
    VOUCHERS_NAME                   : "Mis Comprobantes",
    VOUCHERS_INVOICES               : "Mis Facturas",
    VOUCHERS_RECEIPTS               : "Mis Recibos",
    VOUCHERS_CREDIT_NOTES           : "Mis Notas de Crédito",
    VOUCHERS_DEBIT_NOTES            : "Mis Notas de Débito",
    VOUCHERS_DESCRIPTION            : "Descripción",
    VOUCHERS_DATE                   : "Fecha",
    VOUCHERS_AMOUNT                 : "Importe",
    VOUCHERS_NONE_INVOICES          : "No hay facturas disponibles.",
    VOUCHERS_NONE_RECEIPTS          : "No hay recibos disponibles.",
    VOUCHERS_NONE_CREDIT_NOTES      : "No hay notas de crédito disponibles.",
    VOUCHERS_NONE_DEBIT_NOTES       : "No hay notas de débito disponibles.",

    // Client: Orders
    ORDERS_NAME                     : "Mis Pedidos",
    ORDERS_SINGULAR                 : "Pedido",
    ORDERS_TITLE                    : "Pedido: #{0}",
    ORDERS_ID                       : "ID",
    ORDERS_DATE                     : "Creado el",
    ORDERS_AMOUNT                   : "Cantidad",
    ORDERS_TOTAL                    : "Total",
    ORDERS_TOTALS                   : "Totales",
    ORDERS_TOTAL_FOR                : "Total ({0})",
    ORDERS_PRODUCT                  : "Producto",
    ORDERS_PRICE                    : "Precio",
    ORDERS_SUBTOTAL                 : "Subtotal",
    ORDERS_SUMMARY                  : "Resumen del pedido",
    ORDERS_SHIPPING                 : "Envío del pedido",
    ORDERS_SHIPPING_TYPE            : "Tipo de envío",
    ORDERS_SHIPPING_BOX             : "Caja {0}",
    ORDERS_SUBSIDIARY               : "Sucursal de entrega",
    ORDERS_ADDRESS                  : "Domicilio de envío",
    ORDERS_PAYMENT                  : "Pago del pedido",
    ORDERS_PAYMENT_TYPE             : "Tipo de pago",
    ORDERS_PAYMENT_METHOD           : "Forma de pago",
    ORDERS_PAYMENT_STATUS           : "Estado del pago",
    ORDERS_PAYMENT_DATA             : "Datos del pago",
    ORDERS_PAYMENT_TIME             : "Tiempo restante para pagar",
    ORDERS_PAYMENT_TICKET           : "Ticket para pagar",
    ORDERS_PAYMENT_TICKET_VIEW      : "Ver Ticket",
    ORDERS_PAYMENT_HOURS_SINGULAR   : "{0} hora",
    ORDERS_PAYMENT_HOURS_PLURAL     : "{0} horas",
    ORDERS_MESSAGE                  : "Mensaje",
    ORDERS_PRODUCTS                 : "Productos en: {0}",
    ORDERS_NONE_AVAILABLE           : "No hay pedidos disponibles.",

    // Client: Queries
    QUERIES_NAME                    : "Consultas",
    QUERIES_TITLE                   : "Mis Consultas",
    QUERIES_OPEN                    : "Consultas abiertas",
    QUERIES_CLOSED                  : "Consultas cerradas",
    QUERIES_CREATE                  : "Crear consulta",
    QUERIES_SINGULAR                : "Consulta",
    QUERIES_SINGULAR_TITLE          : "Consulta: {0}",
    QUERIES_TYPE                    : "Tipo",
    QUERIES_SUBJECT                 : "Asunto",
    QUERIES_MESSAGE                 : "Mensaje",
    QUERIES_IMAGE                   : "Imagen",
    QUERIES_ASSIGNED                : "Asignada a",
    QUERIES_LAST_REPLY              : "Última respuesta",
    QUERIES_STATUS_OPEN             : "Abierta",
    QUERIES_STATUS_CLOSE            : "Cerrada",
    QUERIES_READING                 : "Lectura",
    QUERIES_READ                    : "Leida",
    QUERIES_UNREAD                  : "No leida",
    QUERIES_PUBLISH                 : "Publicada el {0}",
    QUERIES_REPLY                   : "Responder",
    QUERIES_NONE_AVAILABLE          : "No hay consultas disponibles.",
    QUERIES_ERROR_EXISTS            : "No existe la consulta indicada.",
    QUERIES_ERROR_TYPE_EMPTY        : "Por favor, complete el tipo.",
    QUERIES_ERROR_TYPE_INVALID      : "El tipo indicado no existe",
    QUERIES_ERROR_SUBJECT           : "Por favor, complete el asunto.",
    QUERIES_ERROR_MESSAGE           : "Por favor, complete el mensaje.",
    QUERIES_ERROR_IMAGE_SIZE        : "El archivo indicado es muy grande.",
    QUERIES_ERROR_IMAGE_TYPE        : "El archivo debe ser una imágen.",
    QUERIES_SUCCESS_CREATE          : "La consulta se creó correctamente.",
    QUERIES_SUCCESS_REPLY           : "La respuesta se creó correctamente.",


    // Chat
    CHAT_NAME                       : "Chat",
    CHAT_TITLE_FULL                 : "Mensajes ({0})",
    CHAT_LAST_CONECTION             : "Última vez. {0}",
    CHAT_UNREAD                     : "{0} mensajes no leídos",
    CHAT_WRITE_MESSAGE              : "Escriba un mensaje aquí",
    CHAT_NONE_AVAILABLE             : "No hay mensajes disponibles",
    CHAT_ERROR_MESSAGE              : "Debe completar el mensaje.",


    // Notifications
    NOTIFICATIONS_NAME              : "Notificaciones",
    NOTIFICATIONS_ALERTS            : "Alertas",
    NOTIFICATIONS_NONE_AVAILABLE    : "No tiene notificaciones",
    NOTIFICATIONS_ENABLE            : "Habilitar notificaciones",
    NOTIFICATIONS_ENABLED           : "¡Las notificaciones estan habilitadas!",
    NOTIFICATIONS_TITLE_OFFER       : "¡Tenemos una nueva oferta para vos!",
    NOTIFICATIONS_TITLE_ORDER       : "¡Su pedido ha sido actualizado!",
    NOTIFICATIONS_TEXT_ORDER        : "El estado del pedido paso de \"{0}\" a \"{1}\". Haz click para verlo.",
    NOTIFICATIONS_TITLE_CART        : "¡Complete su compra!",
    NOTIFICATIONS_TEXT_CART         : "Haz click para ver y completar su compra.",
    NOTIFICATIONS_TITLE_QUERY       : "¡Han respondido a su consulta!",
    NOTIFICATIONS_TEXT_QUERY        : "Tiene una nueva resupuesta en su consulta \"{0}\". Haz click para verla.",
    NOTIFICATIONS_TITLE_INVOICE     : "¡Tiene una nueva Factura!",
    NOTIFICATIONS_TEXT_INVOICE      : "Su factura \"{0}\" está lista. Puede descargarla desde aquí.",
    NOTIFICATIONS_TITLE_RECEIPT     : "¡Tiene un nuevo Recibo!",
    NOTIFICATIONS_TEXT_RECEIPT      : "Su recibo \"{0}\" está listo. Puede descargarlo desde aquí.",
    NOTIFICATIONS_TITLE_CREDITNOTE  : "¡Tiene una nueva Nota de Crédito!",
    NOTIFICATIONS_TEXT_CREDITNOTE   : "Su nota de crédito \"{0}\" está lista. Puede descargarla desde aquí.",
    NOTIFICATIONS_TITLE_DEBITNOTE   : "¡Tiene una nueva Nota de Débito!",
    NOTIFICATIONS_TEXT_DEBITNOTE    : "Su nota de débito \"{0}\" está lista. Puede descargarla desde aquí.",
    NOTIFICATIONS_TITLE_MESSAGE     : "¡Tiene nuevos Mensajes!",
    NOTIFICATIONS_TEXT_MESSAGE      : "Tiene {0} mensajes sin leer. Puede verlos desde aquí.",


    // Terms and Conditions
    TERMS_NAME                      : "Términos y condiciones",


    // Replazable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                  : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
    DATE_MONTH_NAMES                : [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],

    DATE_TIME                       : "{h}:{i}",
    DATE_DAY_TIME                   : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                  : "{d} de {mn}",
    DATE_DAY_MONTH_YEAR             : "{d} de {mn}, {y}",
    DATE_MONTH_YEAR                 : "{mn} {y}",
    DATE_REDUCED                    : "{dn} {d0}/{m0}",
    DATE_COMPLETE                   : "{dn}, {d} de {mn}, {y}",
    DATE_SHORT_COMPLETE             : "{d3}, {d} de {m3}, {y}",
    DATE_DASHES                     : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE             : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                : "{d0}-{m0}-{y} a las {h}:{i}",
    DATE_SLASHES                    : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                : "{d0}/{m0}",
    DATE_SORTABLE                   : "{y}/{m0}/{d0}",
    
    DATE_NOW                        : "Ahora",
    DATE_MIN_AGO                    : "{i} minuto atras",
    DATE_MINS_AGO                   : "{i} minutos atras",
    DATE_HOUR_AGO                   : "{h} hora atras",
    DATE_HOURS_AGO                  : "{h} horas atras",
    DATE_DAY_AGO                    : "{d} día atras",
    DATE_DAYS_AGO                   : "{d} días atras",
    DATE_IN_MIN                     : "En {i} minuto",
    DATE_IN_MINS                    : "En {i} minutos",
    DATE_IN_HOUR                    : "En {h} hora",
    DATE_IN_HOURS                   : "En {h} horas",
    DATE_IN_DAY                     : "En {d} día",
    DATE_IN_DAYS                    : "En {d} días",
    DATE_TOMORROW_AT                : "Mañana a las {h}:{i}",
    DATE_TODAY_AT                   : "Hoy a las {h}:{i}",
    DATE_YESTERDAY_AT               : "Ayer a las {h}:{i}",
    DATE_THIS_YEAR                  : "{d} de {m3} a las {h}:{i}",
    DATE_OTHER_YEAR                 : "{d} de {m3} {y} a las {h}:{i}",
    
    DATE_YESTERDAY                  : "Ayer",
    DATE_TODAY                      : "Hoy",
    DATE_TOMORROW                   : "Mañana",
    DATE_LAST_WEEK                  : "La Semana Pasada",
    DATE_THIS_WEEK                  : "Esta Semana",
    DATE_NEXT_WEEK                  : "La Semana Próxima",
    DATE_PARSED_WEEK                : "Semana del {0} al {1}",
    DATE_DURATION                   : "{0} - {1}",

};
