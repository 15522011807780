import React                from "react";
import PropTypes            from "prop-types";
import { Switch, Redirect } from "react-router-dom";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { fetchData }        from "Actions/Store/StoreActions";
import { setNotiRedirect }  from "Actions/Content/NotificationActions";
import { setCartRedirect }  from "Actions/Store/CartActions";

// Sections
import HomePage             from "Components/Content/Home/HomePage";
import CatalogPage          from "Components/Content/CatalogPage";
import NewsList             from "Components/Content/News/NewsList";
import NewsElem             from "Components/Content/News/NewsElem";
import ContactPage          from "Components/Content/ContactPage";
import ChatPage             from "Components/Content/ChatPage";
import NotificationPage     from "Components/Content/Notifications/NotificationPage";
import ErrorPage            from "Components/Content/ErrorPage";

import ProductPage          from "Components/Store/Product/ProductPage";
import CategoryPage         from "Components/Store/Product/CategoryPage";
import SearchPage           from "Components/Store/Product/SearchPage";
import NewestPage           from "Components/Store/Product/NewestPage";
import ImportantPage        from "Components/Store/Product/ImportantPage";
import FavoritePage         from "Components/Store/Product/FavoritePage";
import PurchasePage         from "Components/Store/Product/PurchasePage";
import HistoryPage          from "Components/Store/Product/HistoryPage";
import OfferList            from "Components/Store/Offer/OfferList";
import OfferElem            from "Components/Store/Offer/OfferElem";
import BrandList            from "Components/Store/Brand/BrandList";
import BrandElem            from "Components/Store/Brand/BrandElem";

import CartContainer        from "Components/Cart/CartContainer";
import CartResult           from "Components/Cart/CartResult";

import TermsUser            from "Components/Terms/TermsUser";
import ClientContainer      from "Components/Client/ClientContainer";

// Components
import Updater              from "Components/Core/Updater";
import Header               from "Components/Core/Header/Header";
import Navigation           from "Components/Core/Navigation";
import Floater              from "Components/Core/Floater";
import Footer               from "Components/Core/Footer";
import Bar                  from "Components/Core/Bar";
import ProductDialog        from "Components/Product/Item/ProductDialog";
import ProductPreview       from "Components/Product/Item/ProductPreview";

// Routes
import UserRoute            from "Components/Utils/Route/UserRoute";
import CartRoute            from "Components/Utils/Route/CartRoute";
import ClientRoute          from "Components/Utils/Route/ClientRoute";

// Styles
import "Styles/Components/Core/Container.css";



/**
 * The Container
 */
class Container extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { orderHash, cartRedirect, fetchData, setCartRedirect } = this.props;
        fetchData(orderHash);
        if (cartRedirect) {
            setCartRedirect("");
        }
    }

    /**
     * Redirect if required
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { isAuthenticated, notiRedirect, history, setNotiRedirect } = this.props;
        if (prevProps.notiRedirect !== notiRedirect && notiRedirect && isAuthenticated) {
            history.push(notiRedirect);
            setNotiRedirect("");
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, isAuthenticated, location, menuOpen } = this.props;

        return <>
            {isAuthenticated && <Updater />}
            <Header />
            <Navigation />

            <main className={"content-container" + (menuOpen ? " content-backdrop" : "")}>
                <Switch>
                    <UserRoute path="/productos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                     component={ProductPage}   exact />
                    <UserRoute path="/productos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                                component={ProductPage}   exact />
                    <UserRoute path="/productos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                                  component={ProductPage}   exact />
                    <UserRoute path="/productos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                             component={ProductPage}   exact />
                    <UserRoute path="/productos/:category([a-z][\w-]{2,})/:page(\d+)"                                                                               component={ProductPage}   exact />
                    <UserRoute path="/productos/:category([a-z][\w-]{2,})"                                                                                          component={ProductPage}   exact />
                    <UserRoute path="/productos/:page(\d+)"                                                                                                         component={ProductPage}   exact />
                    <UserRoute path="/productos"                                                                                                                    component={ProductPage}   exact />

                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:amount(a\d)/:page(\d+)"   component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:amount(a\d)"              component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:amount(a\d)/:page(\d+)"                                component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:amount(a\d)"                                           component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                        component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:amount(a\d)/:page(\d+)"                                                             component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})/:amount(a\d)"                                                                        component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:category([a-z][\w-]{2,})"                                                                                     component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:amount(a\d)/:page(\d+)"                                                                                       component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer/:amount(a\d)"                                                                                                  component={OfferElem}     exact />
                    <UserRoute path="/ofertas/:offer"                                                                                                               component={OfferElem}     exact />

                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                 component={BrandElem}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                            component={BrandElem}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                              component={BrandElem}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                         component={BrandElem}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})/:page(\d+)"                                                                           component={BrandElem}     exact />
                    <UserRoute path="/marcas/:brand/:category([a-z][\w-]{2,})"                                                                                      component={BrandElem}     exact />
                    <UserRoute path="/marcas/:brand/:page(\d+)"                                                                                                     component={BrandElem}     exact />
                    <UserRoute path="/marcas/:brand"                                                                                                                component={BrandElem}     exact />

                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                           component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                             component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                        component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})/:page(\d+)"                                                                          component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:category([a-z][\w-]{2,})"                                                                                     component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search/:page(\d+)"                                                                                                    component={SearchPage}    exact />
                    <UserRoute path="/buscar/:search"                                                                                                               component={SearchPage}    exact />

                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                        component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                                   component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                                     component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                                component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})/:page(\d+)"                                                                                  component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:category([a-z][\w-]{2,})"                                                                                             component={NewestPage}    exact />
                    <UserRoute path="/nuevos/:page(\d+)"                                                                                                            component={NewestPage}    exact />
                    <UserRoute path="/nuevos"                                                                                                                       component={NewestPage}    exact />

                    <ClientRoute path="/destacados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                  component={ImportantPage} exact />
                    <ClientRoute path="/destacados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                             component={ImportantPage} exact />
                    <ClientRoute path="/destacados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                               component={ImportantPage} exact />
                    <ClientRoute path="/destacados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                          component={ImportantPage} exact />
                    <ClientRoute path="/destacados/:category([a-z][\w-]{2,})/:page(\d+)"                                                                            component={ImportantPage} exact />
                    <ClientRoute path="/destacados/:category([a-z][\w-]{2,})"                                                                                       component={ImportantPage} exact />
                    <ClientRoute path="/destacados/:page(\d+)"                                                                                                      component={ImportantPage} exact />
                    <ClientRoute path="/destacados"                                                                                                                 component={ImportantPage} exact />

                    <ClientRoute path="/favoritos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                   component={FavoritePage}  exact />
                    <ClientRoute path="/favoritos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                              component={FavoritePage}  exact />
                    <ClientRoute path="/favoritos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                                component={FavoritePage}  exact />
                    <ClientRoute path="/favoritos/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                           component={FavoritePage}  exact />
                    <ClientRoute path="/favoritos/:category([a-z][\w-]{2,})/:page(\d+)"                                                                             component={FavoritePage}  exact />
                    <ClientRoute path="/favoritos/:category([a-z][\w-]{2,})"                                                                                        component={FavoritePage}  exact />
                    <ClientRoute path="/favoritos/:page(\d+)"                                                                                                       component={FavoritePage}  exact />
                    <ClientRoute path="/favoritos"                                                                                                                  component={FavoritePage}  exact />
                    
                    <ClientRoute path="/comprados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                   component={PurchasePage}  exact />
                    <ClientRoute path="/comprados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                              component={PurchasePage}  exact />
                    <ClientRoute path="/comprados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                                component={PurchasePage}  exact />
                    <ClientRoute path="/comprados/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                           component={PurchasePage}  exact />
                    <ClientRoute path="/comprados/:category([a-z][\w-]{2,})/:page(\d+)"                                                                             component={PurchasePage}  exact />
                    <ClientRoute path="/comprados/:category([a-z][\w-]{2,})"                                                                                        component={PurchasePage}  exact />
                    <ClientRoute path="/comprados/:page(\d+)"                                                                                                       component={PurchasePage}  exact />
                    <ClientRoute path="/comprados"                                                                                                                  component={PurchasePage}  exact />

                    <ClientRoute path="/historial/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})/:page(\d+)"                   component={HistoryPage}   exact />
                    <ClientRoute path="/historial/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:tercategory([a-z][\w-]{2,})"                              component={HistoryPage}   exact />
                    <ClientRoute path="/historial/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})/:page(\d+)"                                                component={HistoryPage}   exact />
                    <ClientRoute path="/historial/:category([a-z][\w-]{2,})/:subcategory([a-z][\w-]{2,})"                                                           component={HistoryPage}   exact />
                    <ClientRoute path="/historial/:category([a-z][\w-]{2,})/:page(\d+)"                                                                             component={HistoryPage}   exact />
                    <ClientRoute path="/historial/:category([a-z][\w-]{2,})"                                                                                        component={HistoryPage}   exact />
                    <ClientRoute path="/historial/:page(\d+)"                                                                                                       component={HistoryPage}   exact />
                    <ClientRoute path="/historial"                                                                                                                  component={HistoryPage}   exact />

                    <UserRoute path="/novedades/:page"        component={NewsList}         exact />
                    <UserRoute path="/novedades"              component={NewsList}         exact />
                    <UserRoute path="/novedad/:news"          component={NewsElem}         exact />

                    <UserRoute path="/categorias"             component={CategoryPage}     exact />
                    <UserRoute path="/ofertas"                component={OfferList}        exact />
                    <UserRoute path="/marcas"                 component={BrandList}        exact />
                    <UserRoute path="/catalogo"               component={CatalogPage}      exact />
                    <UserRoute path="/contacto"               component={ContactPage}      exact />
                    <UserRoute path="/terminos-y-condiciones" component={TermsUser}        exact />

                    <ClientRoute path="/chat"                 component={ChatPage}         exact />
                    <ClientRoute path="/notificaciones"       component={NotificationPage} exact />
                    <ClientRoute path="/cuenta"               component={ClientContainer}        />

                    <CartRoute path="/compras/:order"         component={CartResult}       exact />
                    <CartRoute path="/compras"                component={CartContainer}    exact />

                    <UserRoute path="/404"                    component={ErrorPage}        exact />
                    <UserRoute path="/"                       component={HomePage}         exact />

                    <Redirect from="*" to="/" />
                </Switch>
            </main>

            <Floater />
            {isApp ? <Bar path={location.pathname} /> : <Footer />}

            <ProductDialog />
            <ProductPreview />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchData       : PropTypes.func.isRequired,
        setCartRedirect : PropTypes.func.isRequired,
        setNotiRedirect : PropTypes.func.isRequired,
        history         : PropTypes.object.isRequired,
        isApp           : PropTypes.bool.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        menuOpen        : PropTypes.bool.isRequired,
        orderHash       : PropTypes.string.isRequired,
        cartRedirect    : PropTypes.string.isRequired,
        notiRedirect    : PropTypes.string.isRequired,
        location        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp           : state.core.isApp,
            isAuthenticated : state.auth.isAuthenticated,
            menuOpen        : state.store.menuOpen,
            orderHash       : state.cart.elem.orderHash,
            cartRedirect    : state.cart.redirect,
            notiRedirect    : state.notification.redirect,
        };
    }
}

export default withRouter(connect(Container.mapStateToProps, {
    fetchData, setCartRedirect, setNotiRedirect,
})(Container));
