import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOffers }      from "Actions/Store/OfferActions";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";

// Components
import ProductBanner        from "Components/Product/Item/ProductBanner";
import Title                from "Components/Utils/Common/Title";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Store/Offers.css";



/**
 * The Offer List
 */
class OfferList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchOffers();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { loading, banners, list } = this.props;

        const showBanners = Boolean(banners.length > 0);
        const showList    = Boolean(list.length    > 0);
        const showNone    = !loading && !showBanners && !showList;
        const showContent = loading || showList || showNone;

        return <>
            <Title message="OFFERS_TITLE" />
            <Wrapper withSpacing>
                {showBanners && <section className="offers-banners">
                    {banners.map((elem) => <ProductBanner key={elem.id} data={elem} />)}
                </section>}
                {showContent && <Card className="offers-content" withSpacing withBorder>
                    {loading  && <h3>{NLS.get("GENERAL_LOADING")}</h3>}
                    {showList && <ul className="offers-grid no-list">
                        {list.map((elem) => <li key={elem.id}>
                            <HyperLink variant="none" href={`${Url.OFFERS}/${elem.id}`}>
                                <div><img src={elem.image} alt={elem.name} /></div>
                                <h2>{elem.name}</h2>
                            </HyperLink>
                        </li>)}
                    </ul>}
                    {showNone && <div className="nothing nothing-center">
                        <h3>{NLS.get("OFFERS_NONE_AVAILABLE")}</h3>
                        <Button
                            variant="primary"
                            href={Url.PRODUCTS}
                            message="OFFERS_BUY_MORE"
                        />
                    </div>}
                </Card>}
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOffers : PropTypes.func.isRequired,
        loading     : PropTypes.bool.isRequired,
        banners     : PropTypes.array.isRequired,
        list        : PropTypes.array.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading : state.offer.loading,
            banners : state.offer.banners,
            list    : state.offer.list,
        };
    }
}

export default connect(OfferList.mapStateToProps, {
    fetchOffers,
})(OfferList);
