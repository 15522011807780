import Utils                from "Utils/Common/Utils";

// Types
import {
    NEWS_LOADING, NEWS_LIST, NEWS_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading : false,
    error   : false,
    list    : [],
    total   : 0,
    elem    : {
        title : "GENERAL_LOADING",
    },
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, NEWS_LIST, NEWS_ELEM)) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case NEWS_LOADING:
        return {
            ...state,
            loading    : true,
        };
    
    case NEWS_LIST:
        return {
            ...state,
            loading : false,
            error   : false,
            list    : action.data.list,
            total   : action.data.total,
        };
    case NEWS_ELEM:
        return {
            ...state,
            loading : false,
            error   : false,
            elem    : action.data,
        };
    default:
        return state;
    }
};
