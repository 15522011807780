import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { setPreview }       from "Actions/Store/ProductActions";
import NLS                  from "Utils/App/NLS";
import ClassList            from "Utils/Common/ClassList";

// Components
import Slider               from "Components/Utils/Common/Slider";
import Image                from "Components/Utils/Common/Image";
import HyperLink            from "Components/Utils/Common/HyperLink";


// Styles
import "Styles/Components/Product/Item/ProductImage.css";



/**
 * The Product Image
 */
class ProductImage extends React.Component {
    // The Current State
    state = {
        index : 0,
    };

    /**
     * Opens the Preview
     * @returns {Void}
     */
    openPreview = () => {
        const { setPreview, data } = this.props;
        setPreview({ ...data, index : this.state.index });
    }
    
    /**
     * Handles the Slider Switch
     * @param {Number} index
     * @return {Void}
     */
    handleSwitch = (index) => {
        this.setState({ index });
    }

    /**
     * Stop Clicks
     */
    handleClick = (e) => {
        const { onClick, clickPreview, data } = this.props;
        if (onClick) {
            onClick();
        } else if (clickPreview && data.hasImages) {
            this.openPreview();
        }
    }



    /**
     * Renders the Image
     * @returns {Object}
     */
    renderImage() {
        const { variant, data, withZoom, sliderIndex } = this.props;
        const { name, hasImages, image, images       } = data;

        const hasManyImages = Boolean(hasImages && images.length > 1);

        // Show a Slider for many images
        if (hasManyImages) {
            return <Slider
                variant={variant}
                data={images}
                index={sliderIndex}
                onSwitch={this.handleSwitch}
                withZoom={withZoom}
                centerImage
            />;
        }
        
        // Show the Single Image
        if (hasImages) {
            return <Image
                source={image[variant]}
                zoomed={image.large}
                name={image.name}
                withZoom={withZoom}
            />;
        }

        // Just show the Name
        return <h3>{name}</h3>;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, data, withPreview, clickPreview, showUnavailable } = this.props;

        const showPreview = withPreview && data.hasImages;
        const classes     = new ClassList("product-image", className);
        classes.addIf("product-image-click", clickPreview);

        return <div className={classes.get()} onClick={this.handleClick}>
            {this.renderImage()}
            {showUnavailable && <div className="product-image-unavailable">
                {NLS.get("PRODUCTS_UNAVAILABLE")}
            </div>}
            {showPreview && <HyperLink
                className="product-image-zoom"
                variant="icon"
                icon="zoom"
                onClick={this.openPreview}
            />}
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        setPreview      : PropTypes.func.isRequired,
        data            : PropTypes.object.isRequired,
        variant         : PropTypes.string,
        className       : PropTypes.string,
        onClick         : PropTypes.func,
        withPreview     : PropTypes.bool,
        clickPreview    : PropTypes.bool,
        withZoom        : PropTypes.bool,
        showUnavailable : PropTypes.bool,
        sliderIndex     : PropTypes.number,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className       : "",
        variant         : "small",
        withPreview     : false,
        clickPreview    : false,
        withZoom        : false,
        showUnavailable : false,
        sliderIndex     : 0,
    }
}

export default connect(null, { setPreview })(ProductImage);
