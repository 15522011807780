import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { toggleMenu }       from "Actions/Store/StoreActions";
import Notifications        from "Utils/Common/Notifications";

// Components
import NotificationItem     from "Components/Content/Notifications/NotificationItem";
import Button               from "Components/Utils/Form/Button";



/**
 * The Notification Menu
 */
class NotificationMenu extends React.Component {
    // The Current State
    state = {
        show : true,
    }

    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, isAuthenticated, notifications, toggleMenu } = this.props;
        if (isAuthenticated && notifications.length && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Handles the Notification Enabled
     * @returns {Void}
     */
    handleNotifications = async () => {
        const response = await Notifications.requestPermission();
        if (response) {
            this.setState({ show : false });
        }
    }

    /**
     * Handles the Notification Enabled
     * @returns {Void}
     */
    handleDelete = () => {
        const { notifications, onLeave } = this.props;
        if (notifications.length <= 1 && onLeave) {
            onLeave();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, isAuthenticated, notifications, onEnter, onLeave, onClose } = this.props;
        const { show                                                            } = this.state;

        if (!open || !isAuthenticated || !notifications.length) {
            return <React.Fragment />;
        }
        const hasButton = Notifications.canGrant() && show;

        return <nav
            className="submenu-secondary submenu-notification"
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="no-list">
                {notifications.map((elem, index) => <li key={elem.key}>
                    <NotificationItem
                        data={elem}
                        index={index}
                        onClose={onClose}
                        onDelete={this.handleDelete}
                    />
                </li>)}
                {hasButton && <li className="submenu-button">
                    <Button
                        variant="primary"
                        message="NOTIFICATIONS_ENABLE"
                        onClick={this.handleNotifications}
                        fullWidth
                    />
                </li>}
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        toggleMenu      : PropTypes.func.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        notifications   : PropTypes.array.isRequired,
        open            : PropTypes.bool.isRequired,
        onLeave         : PropTypes.func.isRequired,
        onEnter         : PropTypes.func.isRequired,
        onClose         : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            notifications   : state.notification.list,
        };
    }
}

export default connect(NotificationMenu.mapStateToProps, {
    toggleMenu,
})(NotificationMenu);
