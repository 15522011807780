import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Utils/Common/Utils";

// Styles
import "Styles/Components/Utils/Common/Image.css";



/**
 * The Image
 */
class Image extends React.Component {
    // The Current State
    state = {
        canZoom      : false,
        isZooming    : false,
        izMoving     : false,
        startX       : 0,
        startY       : 0,
        smallBounds  : {},
        largeBounds  : {},
        zoomBounds   : {},
        zoomedBounds : {},
        zoomed       : "",
    };

    // References
    imageRef = React.createRef();



    /**
     * Load the Zoomed Image size on mount
     * @returns {Void}
     */
    componentDidMount() {
        const { withZoom, zoomed } = this.props;
        if (withZoom && zoomed) {
            this.loadImage(zoomed);
        }
    }

    /**
     * Load the Zoomed Image size on update
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { withZoom, zoomed } = this.props;
        if (withZoom && zoomed !== prevProps.zoomed) {
            this.loadImage(zoomed);
        }
    }

    /**
     * Load the Zoomed Image size
     * @param {String} zoomed
     * @returns {Void}
     */
    loadImage(zoomed) {
        const img  = new window.Image();
        img.src    = zoomed;
        img.onload = () => {
            const largeBounds = { width : img.width, height : img.height };
            if (largeBounds.width > 700 || largeBounds.height > 700) {
                this.setState({ largeBounds, canZoom : true, zoomed });
            }
        }
    }

    /**
     * Starts the Zoom
     * @param {Event} e
     * @returns {Void}
     */
    startZoom = (e) => {
        if (!this.state.canZoom) {
            return;
        }
        const node = Utils.getNode(this.imageRef);
        if (node) {
            this.setState({
                isZooming   : true,
                isMoving    : false,
                startX      : e.clientX,
                startY      : e.clientY,
                smallBounds : node.getBoundingClientRect(),
            });
        }
    }

    /**
     * Moves the Zoom
     * @param {Event} e
     * @returns {Void}
     */
    moveZoom = (e) => {
        const { isZooming, isMoving, startX, startY, smallBounds, largeBounds } = this.state;
        if (!isZooming) {
            return;
        }
        if (isZooming && !isMoving) {
            const posX = e.clientX - startX;
            const posY = e.clientY - startY;
            const dist = posX * posX + posY * posY;
            if (dist < 25) {
                return;
            }
        }

        const smallX = e.clientX - smallBounds.left;
        const smallY = e.clientY - smallBounds.top;
        const largeX = smallX / smallBounds.width  * largeBounds.width;
        const largeY = smallY / smallBounds.height * largeBounds.height;
        const width  = 300 / largeBounds.width  * smallBounds.width;
        const height = 300 / largeBounds.height * smallBounds.height;

        const zoomBounds = {
            width, height,
            x : Utils.clamp(smallX - width / 2,  0, smallBounds.width  - width),
            y : Utils.clamp(smallY - height / 2, 0, smallBounds.height - height),
        };
        const zoomedBounds = {
            left : smallBounds.width,
            x    : Utils.clamp(largeX - 150, 0, largeBounds.width  - 300),
            y    : Utils.clamp(largeY - 150, 0, largeBounds.height - 300),
        };
        
        this.setState({ isMoving : true, zoomedBounds, zoomBounds });
        
        if (this.props.onZoom) {
            this.props.onZoom(true, zoomedBounds);
        }
    }

    /**
     * Ends the Zoom
     * @returns {Void}
     */
    endZoom = () => {
        this.setState({ isZooming : false, isMoving : false });
        if (this.props.onZoom) {
            this.props.onZoom(false, null);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { className, withZoom, hideZoomed, source, zoomed, name, onClick } = this.props;
        const { isMoving, zoomedBounds, zoomBounds                             } = this.state;
        
        if (!withZoom) {
            return <img src={source} alt={name} onClick={onClick} />;
        }

        const showZoom  = isMoving && !hideZoomed;
        const zoomStyle = {};
        const style     = {};
        const imgStyle  = {};

        if (isMoving) {
            zoomStyle.transform = `translate(${zoomBounds.x}px, ${zoomBounds.y}px)`;
            zoomStyle.width     = `${zoomBounds.width}px`;
            zoomStyle.height    = `${zoomBounds.height}px`;
            style.left          = `${zoomedBounds.left}px`;
            imgStyle.transform  = `translate(-${zoomedBounds.x}px, -${zoomedBounds.y}px)`;
        }
        
        return <div
            ref={this.imageRef}
            className={`image ${className}`}
            onMouseEnter={this.startZoom}
            onMouseMove={this.moveZoom}
            onMouseLeave={this.endZoom}
            onClick={onClick}
        >
            <img src={source} alt={name} />
            {isMoving && <div className="image-zoom" style={zoomStyle} />}
            {showZoom && <div className="image-zoomed" style={style} onMouseEnter={this.endZoom}>
                <img src={zoomed} alt={name} style={imgStyle} />
            </div>}
        </div>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        source     : PropTypes.string.isRequired,
        name       : PropTypes.string.isRequired,
        className  : PropTypes.string,
        zoomed     : PropTypes.string,
        withZoom   : PropTypes.bool,
        hideZoomed : PropTypes.bool,
        onClick    : PropTypes.func,
        onZoom     : PropTypes.func,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className  : "",
        withZoom   : false,
        hideZoomed : false,
    }
}

export default Image;
