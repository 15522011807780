import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { setNotiRedirect }  from "Actions/Content/NotificationActions";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";
import Utils                from "Utils/Common/Utils";

// Components
import Icon                 from "Components/Utils/Common/Icon";

// Styles
import "Styles/Components/Utils/Common/HyperLink.css";



/**
 * A HyperLink
 */
class HyperLink extends React.Component {
    /**
     * Handles the Click
     * @param {Event} e
     * @returns {Void}
     */
    onClick = (e) => {
        const {
            history, onClick, href, target, tel, mail, whatsapp, dontStop,
            platform, setNotiRedirect,
        } = this.props;

        let clicked = false;
        let handled = false;

        if (onClick) {
            onClick(e);
            clicked = true;
        }

        // Href Links
        if (href && !tel && !mail && !whatsapp) {
            if (href === Url.LOGIN) {
                setNotiRedirect(history.location.pathname);
            }
            
            // Internal Links that start with the URL
            if (Utils.hrefInApp(href) && target !== "_blank") {
                history.push(Utils.getUrl(href));
                handled = true;
            
            // Internal link that are paths
            } else if (!href.startsWith("http") && target !== "_blank") {
                history.push(href);
                handled = true;
            
            // Voucher paths in Android
            } else if (href.startsWith(process.env.REACT_APP_VOUCHER) && platform === "android") {
                navigator.app.loadUrl(href, { openExternal : true });
                handled = true;

            // Voucher paths in iOS
            } else if (href.startsWith(process.env.REACT_APP_VOUCHER) && platform === "ios") {
                window.cordova.InAppBrowser.open(href, "_system");
                handled = true;
            }
        }

        // WhatsApp in iOS
        if (!handled && whatsapp && platform === "ios") {
            window.cordova.InAppBrowser.open(`https://api.whatsapp.com/send?phone=549${href}`, "_system");
            handled = true;
        }

        if (handled || (clicked && !dontStop)) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    /**
     * Returns the Href and Target
     * @returns {{href: String, target: String}}
     */
    getHref() {
        const { href, target, tel, mail, whatsapp, isApp } = this.props;
        const result = { href, target };
        
        if (tel) {
            result.href = `tel:${href}`;
        } else if (mail) {
            result.href = `mailto:${href}`;
        } else if (whatsapp) {
            if (isApp) {
                result.href   = `whatsapp://send?phone=549${href}`;
            } else {
                result.href   = `https://api.whatsapp.com/send?phone=549${href}`;
                result.target = "_blank";
            }
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isButton, variant, message, children, icon, afterIcon, badge,
            className, onMouseEnter, onMouseLeave, onTouchEnd, style,
        } = this.props;

        const { href, target } = this.getHref();
        const content   = children || NLS.get(message);
        const baseClass = isButton ? `btn btn-${variant}` : `link link-${variant}`;
        const iconClass = icon && variant !== "icon" && content ? "link-with" : "";

        return <a className={`${baseClass} ${className} ${iconClass}`}
            href={href}
            target={target}
            onClick={this.onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onTouchEnd={onTouchEnd}
            style={style}
        >
            {!!icon      && <Icon className="link-preicon" variant={icon} />}
            {!!content   && <span className="link-content">{content}</span>}
            {!!afterIcon && <Icon className="link-aftericon" variant={afterIcon} />}
            {badge > 0   && <span className="badge">{badge}</span>}
        </a>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history         : PropTypes.object.isRequired,
        setNotiRedirect : PropTypes.func.isRequired,
        platform        : PropTypes.string,
        isApp           : PropTypes.bool,
        isButton        : PropTypes.bool,
        variant         : PropTypes.string,
        message         : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        href            : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        target          : PropTypes.string,
        tel             : PropTypes.bool,
        mail            : PropTypes.bool,
        whatsapp        : PropTypes.bool,
        icon            : PropTypes.string,
        afterIcon       : PropTypes.string,
        badge           : PropTypes.number,
        className       : PropTypes.string,
        dontStop        : PropTypes.bool,
        onClick         : PropTypes.func,
        onMouseEnter    : PropTypes.func,
        onMouseLeave    : PropTypes.func,
        onTouchEnd      : PropTypes.func,
        style           : PropTypes.object,
        children        : PropTypes.any,
    }
    
    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isButton  : false,
        variant   : "primary",
        href      : "#",
        target    : "_self",
        badge     : 0,
        className : "",
        dontStop  : false,
        style     : {},
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp    : state.core.isApp,
            platform : state.core.platform,
        };
    }
}

export default withRouter(connect(HyperLink.mapStateToProps, {
    setNotiRedirect,
})(HyperLink));
