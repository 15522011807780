import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Utils/App/NLS";

// Components
import Card                 from "Components/Utils/Common/Card";
import SubTitle             from "Components/Utils/Common/SubTitle";
import SVGIcon              from "Components/Utils/Common/SVGIcon";



/**
 * The Cart Option
 */
class CartOption extends React.Component {
    /**
     * Renders the Content
     * @returns {Object}
     */
    render() {
        const { show, data, icon, message, help, selected, onClick } = this.props;

        const amount = Math.min(data.length, 3);

        if (!show) {
            return <React.Fragment />;
        }
        return <Card className="cart-options-card" withBorder>
            <SubTitle message={message} icon={icon} />
            {!!help && <p className="cart-help">{NLS.get(help)}</p>}
            <ul className={`cart-options cart-options-${amount} no-list spacing`}>
                {data.map((elem) => <li
                    key={elem.type}
                    onClick={() => onClick(elem.type)}
                    className={selected === elem.type ? "cart-selected" : ""}
                >
                    {!!elem.icon && <div className="cart-options-icon">
                        <SVGIcon variant={elem.icon} />
                    </div>}
                    <p>{elem.name}</p>
                </li>)}
            </ul>
        </Card>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        show     : PropTypes.bool.isRequired,
        data     : PropTypes.array.isRequired,
        icon     : PropTypes.string.isRequired,
        message  : PropTypes.string.isRequired,
        help     : PropTypes.string,
        selected : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
        onClick  : PropTypes.func.isRequired,
    }
}

export default CartOption;
