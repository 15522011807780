import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import NLS                  from "Utils/App/NLS";
import Url                  from "Utils/App/Url";
import Utils                from "Utils/Common/Utils";

// Components
import Content              from "Components/Utils/Common/Content";
import SubTitle             from "Components/Utils/Common/SubTitle";
import Form                 from "Components/Utils/Form/Form";
import TextField            from "Components/Utils/Form/TextField";
import Button               from "Components/Utils/Form/Button";


// Actions
import {
    fetchAddress, editAddress,
} from "Actions/Client/AddressActions";



/**
 * The Address Elem
 */
class AddressElem extends React.Component {
    // The Initial Data
    initialData = {
        addressID      : "",
        name           : "",
        street         : "",
        streetNumber   : "",
        betweenStreets : "",
        floor          : "",
        appartment     : "",
        locality       : "",
        province       : "",
        zipCode        : "",
        contactName    : "",
        dni            : "",
        email          : "",
        phone          : "",
        schedule       : "",
        isDefault      : 0,
    }
    
    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
        error   : "",
    }



    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        if (this.props.match.params.address) {
            this.props.fetchAddress(this.props.match.params.address);
        }
    }

    /**
     * Updates the State
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.elem.addressID !== prevProps.elem.addressID && this.props.elem.addressID) {
            const data = Utils.extend(this.initialData, this.props.elem);
            this.setState({ data, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {String} value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @param {Event} e
     * @returns {Void}
     */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { data, loading } = this.state;
        if (loading) {
            return;
        }
        
        this.setState({ loading : true, errors : {}, error : "" });
        try {
            await this.props.editAddress(data);
            this.setState({ loading : false });
            this.props.history.push(Url.ADDRESSES);
        } catch (errors) {
            this.setState({ loading : false, errors, error : errors.form });
        }
    }

    /**
     * Closes the Alert
     * @returns {Void}
     */
    closeAlert = () => {
        this.setState({ error : "" });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { match, provinces, loading, elem, page     } = this.props;
        const { data, loading : isDisabled, errors, error } = this.state;
        
        const isEdit  = !!match.params.address;
        const message = isEdit   ? "ADDRESSES_EDIT" : "ADDRESSES_CREATE";
        const backUrl = page > 1 ? `${Url.ADDRESSES}/${page}` : Url.ADDRESSES;

        return <>
            <SubTitle message={message} icon="address" />
            <Content loading={loading} hasContent withCard>
                {elem.isPending && <p className="client-warning">
                    {NLS.get("ADDRESSES_PENDING")}
                </p>}

                <Form
                    className="client-form spacing"
                    columns="3"
                    error={error}
                    onSubmit={this.handleSubmit}
                    onClose={this.closeAlert}
                >
                    <TextField
                        name="name"
                        label="ADDRESSES_ALIAS"
                        value={data.name}
                        error={errors.name}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <TextField
                        name="street"
                        label="ADDRESSES_STREET"
                        value={data.street}
                        error={errors.street}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <TextField
                        name="streetNumber"
                        type="number"
                        label="ADDRESSES_STREET_NUMBER"
                        value={data.streetNumber || ""}
                        error={errors.streetNumber}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <TextField
                        name="betweenStreets"
                        label="ADDRESSES_BETWEEN_STREETS"
                        value={data.betweenStreets}
                        error={errors.betweenStreets}
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="floor"
                        type="number"
                        label="ADDRESSES_FLOOR"
                        value={data.floor || ""}
                        error={errors.floor}
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="appartment"
                        label="ADDRESSES_APPARTMENT"
                        value={data.appartment}
                        error={errors.appartment}
                        onChange={this.handleChange}
                    />

                    <TextField
                        name="zipCode"
                        label="ADDRESSES_ZIP_CODE"
                        value={data.zipCode}
                        error={errors.zipCode}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <TextField
                        name="locality"
                        label="ADDRESSES_LOCALITY"
                        value={data.locality}
                        error={errors.locality}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <TextField
                        type="select"
                        name="province"
                        label="ADDRESSES_PROVINCE"
                        options={provinces}
                        value={data.province}
                        error={errors.province}
                        onChange={this.handleChange}
                        withNone
                        isRequired
                    />
                    
                    <TextField
                        name="contactName"
                        label="ADDRESSES_CONTACT_NAME"
                        helperText="ADDRESSES_CONTACT_HELP"
                        value={data.contactName}
                        error={errors.contactName}
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="dni"
                        label="ADDRESSES_DNI"
                        value={data.dni}
                        error={errors.dni}
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="email"
                        label="ADDRESSES_EMAIL"
                        value={data.email}
                        error={errors.email}
                        onChange={this.handleChange}
                    />
                    <TextField
                        name="phone"
                        label="ADDRESSES_PHONE"
                        value={data.phone}
                        error={errors.phone}
                        onChange={this.handleChange}
                    />

                    <TextField
                        name="schedule"
                        label="ADDRESSES_SCHEDULE"
                        value={data.schedule}
                        error={errors.schedule}
                        onChange={this.handleChange}
                    />
                    <TextField
                        type="checkbox"
                        name="isDefault"
                        label="ADDRESSES_IS_DEFAULT"
                        value={data.isDefault}
                        error={errors.isDefault}
                        onChange={this.handleChange}
                        isRequired
                    />

                    <div className="columns-btn">
                        <Button
                            variant="primary"
                            message="GENERAL_SAVE"
                            isDisabled={isDisabled}
                            fullWidth
                        />
                        <Button
                            variant="cancel"
                            message="GENERAL_CANCEL"
                            href={backUrl}
                            fullWidth
                        />
                    </div>
                </Form>
            </Content>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        history      : PropTypes.object.isRequired,
        fetchAddress : PropTypes.func.isRequired,
        editAddress  : PropTypes.func.isRequired,
        match        : PropTypes.object.isRequired,
        provinces    : PropTypes.array.isRequired,
        loading      : PropTypes.bool.isRequired,
        elem         : PropTypes.object.isRequired,
        page         : PropTypes.number.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            provinces : state.core.entities.provinces,
            loading   : state.address.loading,
            elem      : state.address.elem,
            page      : state.client.page,
        };
    }
}

export default withRouter(connect(AddressElem.mapStateToProps, {
    fetchAddress, editAddress,
})(AddressElem));
