import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Document, Page }   from "react-pdf";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";
import Utils                from "Utils/Common/Utils";

// Components
import ErrorPage            from "Components/Content/ErrorPage";
import Wrapper              from "Components/Utils/Common/Wrapper";
import Card                 from "Components/Utils/Common/Card";
import Title                from "Components/Utils/Common/Title";
import Loader               from "Components/Utils/Common/Loader";
import HyperLink            from "Components/Utils/Common/HyperLink";
import Button               from "Components/Utils/Form/Button";

// Styles
import "Styles/Components/Content/Catalog.css";



/**
 * The Catalog Page
 */
class CatalogPage extends React.Component {
    // The Current State
    state = {
        isMounted   : false,
        loaded      : false,
        width       : 900,
        currentPage : 1,
        totalPages  : 0,
    }

    /**
     * Sets the Mounted state
     * @returns {Void}
     */
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.setState({ isMounted : true });
    }

    /**
     * Unsets the Mounted state
     * @returns {Void}
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.setState({ isMounted : true });
    }

    /**
     * Handle a Resize
     * @returns {Void}
     */
    handleResize = () => {
        const width = window.innerWidth - 24 * 2;
        if (this.state.width !== width && width > 400 && width < 900) {
            this.setState({ width });
        }
    }

    /**
     * Sets the total pages of the pdf
     * @param {Object} data
     * @returns {Void}
     */
    onLoad = (data) => {
        if (this.state.isMounted) {
            this.setState({ loaded : true, totalPages : data.numPages });
        }
    }

    /**
     * Handles the Prev Page button
     * @returns {Void}
     */
    handlePrevPage = () => {
        const { isMounted, currentPage } = this.state;
        if (isMounted && currentPage > 1) {
            window.scrollTo(0, 0);
            this.setState({ currentPage : currentPage - 1 });
        }
    }

    /**
     * Handles the Next Page button
     * @returns {Void}
     */
    handleNextPage = () => {
        const { isMounted, currentPage, totalPages } = this.state;
        if (isMounted && currentPage < totalPages) {
            window.scrollTo(0, 0);
            this.setState({ currentPage : currentPage + 1 });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isApp, loading, catalogue              } = this.props;
        const { loaded, width, currentPage, totalPages } = this.state;

        if (!loading && Utils.isEmpty(catalogue)) {
            return <ErrorPage />;
        }
        const backUrl      = isApp ? Url.ACCOUNT : "";
        const name         = catalogue.name || "GENERAL_LOADING";
        const prevDisabled = currentPage === 1;
        const nextDisabled = currentPage >= totalPages;
        const showPages    = loaded && totalPages > 1;

        return <>
            <Title message={name} href={backUrl} />
            <Wrapper withSpacing>
                <div className="catalog-download">
                    <HyperLink
                        variant="primary"
                        message="GENERAL_DOWNLOAD"
                        href={catalogue.url}
                        target="_blank"
                        isButton
                    />
                </div>
                <Card className="catalog-container" withBorder>
                    <Document
                        file={catalogue.url}
                        onLoadSuccess={this.onLoad}
                        loading={<Loader variant="app" show />}
                    >
                        <Page pageNumber={currentPage} width={width} />
                    </Document>
                </Card>
                {showPages && <div className="catalog-pages">
                    <Button
                        className="catalog-prev"
                        message="GENERAL_PREV"
                        variant="primary"
                        icon="left"
                        onClick={this.handlePrevPage}
                        isDisabled={prevDisabled}
                    />
                    <p>{NLS.format("CATALOG_PAGE", String(currentPage), String(totalPages))}</p>
                    <Button
                        className="catalog-next"
                        message="GENERAL_NEXT"
                        variant="primary"
                        afterIcon="right"
                        onClick={this.handleNextPage}
                        isDisabled={nextDisabled}
                    />
                </div>}
                {/* <iframe
                    title={NLS.get("CATALOG_NAME")}
                    src={`https://docs.google.com/gview?url=${catalogue.url}&embedded=true`}
                    frameBorder="0"
                    allowFullScreen
                /> */}
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isApp     : PropTypes.bool.isRequired,
        loading   : PropTypes.bool.isRequired,
        catalogue : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isApp     : state.core.isApp,
            loading   : state.core.loading,
            catalogue : state.store.catalogue,
        };
    }
}

export default connect(CatalogPage.mapStateToProps)(CatalogPage);
