import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchOffer }       from "Actions/Store/OfferActions";
import NLS                  from "Utils/App/NLS";
import Url                  from "Utils/App/Url";
import Production           from "Utils/Common/Production";
import Utils                from "Utils/Common/Utils";

// Components
import ProductsContainer    from "Components/Product/List/ProductsContainer";
import ProductsHeader       from "Components/Product/List/ProductsHeader";
import ProductsOptions      from "Components/Product/List/ProductsOptions";
import ProductsContent      from "Components/Product/List/ProductsContent";
import ProductsAside        from "Components/Product/List/ProductsAside";
import ProductsList         from "Components/Product/List/ProductsList";



/**
 * The Offer Elem
 */
class OfferElem extends React.Component {
    // The Production
    production = new Production(this.props.fetchOffer, "offer");

    /**
     * Load the Data on Mount
     * @returns {Void}
     */
    componentDidMount() {
        const { loaded, settings, preferences, match } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params);
            this.production.fetch();
        }
    }

    /**
     * Load the Data if the Brand changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { loaded, settings, preferences, match } = this.props;
        if (loaded) {
            this.production.setSettings(settings, preferences, match.params);
            const oldParams = this.production.getParams(prevProps.match.params);
            const newParams = this.production.getParams(match.params);

            if (!prevProps.loaded || !Utils.areObjectsEqual(oldParams, newParams)) {
                this.production.fetch();
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isMobile, loading, error, elem        } = this.props;
        const { offer, category, subcategory, tercategory, page } = this.production.getParams();

        this.production.startRender(elem, `${Url.OFFERS}/${offer}`);
        this.production.addCrumb(Url.OFFERS, "OFFERS_TITLE");
        this.production.addCrumb(this.production.url, elem.name);
        this.production.addCategory(category);
        this.production.addSubcategory(subcategory);
        this.production.addTercategory(tercategory);

        const image      = isMobile ? elem.mobile : elem.banner;
        const showBanner = Boolean(settings.showOfferBanner && image);
        const results    = NLS.pluralize("PRODUCTS_RESULTS", elem.results);

        return <ProductsContainer
            getUrl={this.production.createUrl}
            onChange={this.production.fetch}
            hasFilters={this.production.hasFilters()}
            loading={loading}
            error={error}
        >
            <ProductsHeader
                message={elem.name}
                submessage={results}
                banner={image}
                height={settings.offerHeight}
                showBanner={showBanner}
            />
            <ProductsOptions crumbs={this.production.crumbs} />
            <ProductsContent>
                <ProductsAside
                    name={elem.name}
                    results={elem.results}
                    amounts={elem.amounts}
                    categories={this.production.categories}
                    category={this.production.category}
                    subcategories={this.production.subcategories}
                    subcategory={this.production.subcategory}
                    tercategories={this.production.tercategories}
                    tercategory={this.production.tercategory}
                    prices={elem.prices}
                    showBanner={showBanner}
                />
                <ProductsList
                    products={elem.products}
                    pages={elem.pages}
                    page={page}
                    url={this.production.baseUrl}
                />
            </ProductsContent>
        </ProductsContainer>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOffer  : PropTypes.func.isRequired,
        match       : PropTypes.object.isRequired,
        isMobile    : PropTypes.bool.isRequired,
        loaded      : PropTypes.bool.isRequired,
        settings    : PropTypes.object.isRequired,
        preferences : PropTypes.object.isRequired,
        loading     : PropTypes.bool.isRequired,
        error       : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMobile    : state.core.isMobile,
            settings    : state.core.settings,
            loaded      : state.store.loaded,
            preferences : state.store.preferences,
            loading     : state.offer.loading,
            error       : state.offer.error,
            elem        : state.offer.elem,
        };
    }
}

export default connect(OfferElem.mapStateToProps, {
    fetchOffer,
})(OfferElem);
