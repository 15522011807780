import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { withRouter }       from "react-router";
import { logout }           from "Actions/Core/AuthActions";
import { toggleMenu }       from "Actions/Store/StoreActions";
import MenuItems            from "Utils/App/MenuItems";
import Url                  from "Utils/App/Url";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Client Menu
 */
class ClientMenu extends React.Component {
    /**
     * Toggles the Menu
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { open, isAuthenticated, toggleMenu } = this.props;
        if (isAuthenticated && open && !prevProps.open) {
            toggleMenu(true);
        }
    }

    /**
     * Handles the Logout
     * @returns {Void}
     */
    handleLogout = () => {
        const { onClose, logout } = this.props;
        onClose();
        logout();
    }

    /**
     * Returns the Item Class
     * @param {String} key
     * @param {String} href
     * @param {String} path
     * @returns {String}
     */
    getItemClass(key, href, path) {
        if (href === path) {
            return "client-selected";
        }
        if (key === "voucher" && path.startsWith(Url.VOUCHERS)) {
            return "client-selected";
        }
        if (key === "purchases" && path.startsWith(Url.PURCHASES)) {
            return "client-selected";
        }
        if (key === "favorite" && path.startsWith(Url.FAVORITES)) {
            return "client-selected";
        }
        if (key === "history" && path.startsWith(Url.HISTORY)) {
            return "client-selected";
        }
        return "";
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { settings, isAuthenticated, open, onEnter, onLeave, onClose, history } = this.props;

        if (!open || !isAuthenticated) {
            return <React.Fragment />;
        }
        const clientMenu = MenuItems.getClient(settings, true);
        const path       = history.location.pathname;

        return <nav
            className="submenu-secondary submenu-client"
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
        >
            <ul className="no-list">
                {clientMenu.map(({ type, href, message }) => <li key={type}>
                    <HyperLink
                        className={this.getItemClass(type, href, path)}
                        variant="none"
                        href={href}
                        message={message}
                        icon={type}
                        onClick={onClose}
                    />
                </li>)}
                {settings.hasProductPurchases && <li>
                    <HyperLink
                        className={this.getItemClass("purchases", Url.PURCHASES, path)}
                        variant="none"
                        icon="purchases"
                        href={Url.PURCHASES}
                        message="PURCHASES_TITLE"
                        onClick={onClose}
                    />
                </li>}
                {settings.hasProductFavorites && <li>
                    <HyperLink
                        className={this.getItemClass("favorite", Url.FAVORITES, path)}
                        variant="none"
                        icon="favorite"
                        href={Url.FAVORITES}
                        message="FAVORITES_TITLE"
                        onClick={onClose}
                    />
                </li>}
                {settings.hasProductHistory && <li>
                    <HyperLink
                        className={this.getItemClass("history", Url.HISTORY, path)}
                        variant="none"
                        icon="history"
                        href={Url.HISTORY}
                        message="HISTORY_TITLE"
                        onClick={onClose}
                    />
                </li>}
                <li>
                    <HyperLink
                        variant="none"
                        message="CLIENT_LOGOUT"
                        icon="logout"
                        onClick={this.handleLogout}
                    />
                </li>
            </ul>
        </nav>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout          : PropTypes.func.isRequired,
        toggleMenu      : PropTypes.func.isRequired,
        settings        : PropTypes.object.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        history         : PropTypes.object.isRequired,
        open            : PropTypes.bool.isRequired,
        onEnter         : PropTypes.func.isRequired,
        onLeave         : PropTypes.func.isRequired,
        onClose         : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            settings        : state.core.settings,
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default withRouter(connect(ClientMenu.mapStateToProps, {
    logout, toggleMenu,
})(ClientMenu));
