import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import { logout }           from "Actions/Core/AuthActions";
import Url                  from "Utils/App/Url";
import NLS                  from "Utils/App/NLS";
import MenuItems            from "Utils/App/MenuItems";

// Sections
import AccountPage          from "./AccountPage";
import AddressList          from "./Address/AddressList";
import AddressElem          from "./Address/AddressElem";
import InvoicePage          from "./Voucher/InvoicePage";
import ReceiptPage          from "./Voucher/ReceiptPage";
import CreditNotePage       from "./Voucher/CreditNotePage";
import DebitNotePage        from "./Voucher/DebitNotePage";
import OrderList            from "./Order/OrderList";
import OrderElem            from "./Order/OrderElem";
import QueryList            from "./Query/QueryList";
import QueryElem            from "./Query/QueryElem";
import QueryCreate          from "./Query/QueryCreate";

// Components
import Wrapper              from "Components/Utils/Common/Wrapper";
import Title                from "Components/Utils/Common/Title";
import Card                 from "Components/Utils/Common/Card";
import HyperLink            from "Components/Utils/Common/HyperLink";
import ClientRoute          from "Components/Utils/Route/ClientRoute";

// Styles
import "Styles/Components/Client/Client.css";



/**
 * The Client Container
 */
class ClientContainer extends React.Component {
    /**
     * A Blank Page
     * @returns {Function}
     */
    blankPage = () => {
        return <Card className="client-blank" />;
    }
    
    /**
     * Returns the Title Depending on the Path
     * @param {String} path
     * @returns {String}
     */
    getTitle(path) {
        let result = "CLIENT_NAME";
        if (path.startsWith(Url.ACCOUNT_DATA)) {
            result = "ACCOUNT_NAME";
        } else if (path.startsWith(Url.ADDRESSES)) {
            result = "ADDRESSES_NAME";
        } else if (path.startsWith(Url.ADDRESSES_VIEW)) {
            result = "ADDRESSES_SINGULAR";
        } else if (path.startsWith(Url.INVOICES)) {
            result = "VOUCHERS_INVOICES";
        } else if (path.startsWith(Url.RECEIPTS)) {
            result = "VOUCHERS_RECEIPTS";
        } else if (path.startsWith(Url.CREDIT_NOTES)) {
            result = "VOUCHERS_CREDIT_NOTES";
        } else if (path.startsWith(Url.DEBIT_NOTES)) {
            result = "VOUCHERS_DEBIT_NOTES";
        } else if (path.startsWith(Url.ORDERS)) {
            result = "ORDERS_NAME";
        } else if (path.startsWith(Url.ORDERS_VIEW)) {
            result = "ORDERS_SINGULAR";
        } else if (path.startsWith(Url.QUERIES)) {
            result = "QUERIES_TITLE";
        } else if (path.startsWith(Url.QUERIES_VIEW)) {
            result = "QUERIES_SINGULAR";
        }
        return result;
    }

    /**
     * Returns the Back Url Depending on the Path
     * @param {String} path
     * @returns {String}
     */
    getBackUrl(path) {
        const { isMicro, isMobile, type, page } = this.props;
        const showBack = isMicro || isMobile;
        let   result   = showBack ? Url.ACCOUNT : "";
        
        if (path === Url.ACCOUNT || path === Url.VOUCHERS) {
            result = "";
        } else if (showBack && path.startsWith(Url.VOUCHERS)) {
            result = Url.VOUCHERS;
        } else if (path === Url.ADDRESSES_CREATE || (path.startsWith(Url.ADDRESSES_VIEW) && !path.startsWith(Url.ADDRESSES))) {
            result = Url.ADDRESSES;
        } else if (path.startsWith(Url.ORDERS_VIEW) && !path.startsWith(Url.ORDERS)) {
            result = Url.ORDERS;
        } else if (path === Url.QUERIES_CREATE || (path.startsWith(Url.QUERIES_VIEW) && !path.startsWith(Url.QUERIES))) {
            result = Url.QUERIES;
        }
        if (result && type) {
            result = `${result}/${type}`;
        }
        if (result && page > 1) {
            result = `${result}/${page}`;
        }
        return result;
    }

    /**
     * Returns the ClassName for the Navigation Link
     * @param {String} icon
     * @param {String} path
     * @param {String} url
     * @returns {String}
     */
    getClassName(icon, path, url) {
        const result = [];
        if (icon === "voucher") {
            result.push("client-sub");
        }
        let isSelected = false;
        if (path.startsWith(url)) {
            isSelected = true;
        } else if (url === path) {
            isSelected = true;
        } else {
            for (const uri of [ Url.ADDRESSES_VIEW, Url.ORDERS_VIEW, Url.QUERIES_VIEW ]) {
                if (url.startsWith(uri) && path.startsWith(uri)) {
                    isSelected = true;
                }
            }
        }
        if (isSelected) {
            result.push("client-selected");
        }
        return result.join(" ");
    }

    /**
     * Returns the ClassName for the Navigation Link
     * @param {String} icon
     * @param {String} path
     * @param {String} url
     * @returns {String}
     */
    getAfterIcon(icon, path, url) {
        if (icon === "voucher" && path.startsWith(url)) {
            return "down";
        }
        if (icon === "voucher") {
            return "right";
        }
        return null;
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isMobile, credential, logout, settings, menuData, location } = this.props;
        const { phone, whatsapp, headerPhone, headerWhatsapp               } = settings;

        const clientItems    = MenuItems.getClient(settings);
        const secondaryItems = MenuItems.getClientSecondary(menuData);
        const path           = location.pathname;
        const isBlank        = path === Url.ACCOUNT || path === Url.VOUCHERS;
        const title          = isMobile ? this.getTitle(path) : "CLIENT_NAME";
        const subtitle       = !isMobile || isBlank ? credential.name : "";
        const backUrl        = this.getBackUrl(path);

        return <>
            <Title
                className="client-title"
                message={title}
                submessage={subtitle}
                href={backUrl}
            />
            <Wrapper className={isBlank ? " client-menuonly" : ""} withSpacing>
                <div className="client-container">
                    <div className="client-sidebar">
                        <Card className="client-mainnav">
                            <ul className="client-nav no-list">
                                {clientItems.map(({ key, icon, href, message, submenu }) => <li key={key}>
                                    <HyperLink
                                        variant="none"
                                        href={href}
                                        message={message}
                                        icon={icon}
                                        afterIcon={this.getAfterIcon(key, path, href)}
                                        className={this.getClassName(key, path, href)}
                                    />
                                    {key === "voucher" && path.startsWith(href) ? <ul className="no-list">
                                        {submenu.map(({ key, href, message }) => <li key={key}>
                                            <HyperLink
                                                variant="none"
                                                href={href}
                                                message={message}
                                                className={href === path ? "client-selected" : ""}
                                            />
                                        </li>)}
                                    </ul> : ""}
                                </li>)}
                                {settings.hasProductPurchases && <li className="client-extra">
                                    <HyperLink
                                        variant="none"
                                        icon="purchases"
                                        href={Url.PURCHASES}
                                        message="PURCHASES_TITLE"
                                    />
                                </li>}
                                {settings.hasProductFavorites && <li className="client-extra">
                                    <HyperLink
                                        variant="none"
                                        icon="favorite"
                                        href={Url.FAVORITES}
                                        message="FAVORITES_TITLE"
                                    />
                                </li>}
                                {settings.hasProductHistory && <li className="client-extra">
                                    <HyperLink
                                        variant="none"
                                        icon="history"
                                        href={Url.HISTORY}
                                        message="HISTORY_TITLE"
                                    />
                                </li>}
                            </ul>
                        </Card>

                        <Card className="client-subnav">
                            <ul className="client-nav no-list">
                                {settings.hasContact && <li>
                                    <HyperLink
                                        variant="none"
                                        icon="email"
                                        href={Url.CONTACT}
                                        message="CONTACT_NAME"
                                    />
                                </li>}
                                {headerWhatsapp && <li>
                                    <HyperLink
                                        variant="none"
                                        message={NLS.format("HEADER_WHATSAPP_NUMBER", whatsapp)}
                                        href={whatsapp}
                                        icon="whatsapp"
                                        whatsapp
                                    />
                                </li>}
                                {headerPhone && <li>
                                    <HyperLink
                                        variant="none"
                                        message={NLS.format("HEADER_PHONE_NUMBER", phone)}
                                        href={phone}
                                        icon="phone"
                                        tel
                                    />
                                </li>}
                                {secondaryItems.map(({ key, icon, href, message }) => <li key={key}>
                                    <HyperLink
                                        variant="none"
                                        href={href}
                                        message={message}
                                        icon={icon}
                                    />
                                </li>)}
                                <li>
                                    <HyperLink
                                        variant="none"
                                        message="CLIENT_LOGOUT"
                                        icon="logout"
                                        onClick={logout}
                                    />
                                </li>
                            </ul>
                        </Card>
                    </div>
                    
                    <div className="client-content">
                        <Switch>
                            <ClientRoute path={Url.ACCOUNT}           component={this.blankPage} exact />
                            <ClientRoute path={Url.ACCOUNT_DATA}      component={AccountPage}    exact />
                            
                            <ClientRoute path={Url.ADDRESSES}         component={AddressList}    exact />
                            <ClientRoute path={Url.ADDRESSES_CREATE}  component={AddressElem}    exact />
                            <ClientRoute path={Url.ADDRESSES_PAGE}    component={AddressList}    exact />
                            <ClientRoute path={Url.ADDRESSES_ONE}     component={AddressElem}    exact />

                            <ClientRoute path={Url.VOUCHERS}          component={this.blankPage} exact />
                            <ClientRoute path={Url.INVOICES}          component={InvoicePage}    exact />
                            <ClientRoute path={Url.INVOICES_PAGE}     component={InvoicePage}    exact />
                            <ClientRoute path={Url.RECEIPTS}          component={ReceiptPage}    exact />
                            <ClientRoute path={Url.RECEIPTS_PAGE}     component={ReceiptPage}    exact />
                            <ClientRoute path={Url.CREDIT_NOTES}      component={CreditNotePage} exact />
                            <ClientRoute path={Url.CREDIT_NOTES_PAGE} component={CreditNotePage} exact />
                            <ClientRoute path={Url.DEBIT_NOTES}       component={DebitNotePage}  exact />
                            <ClientRoute path={Url.DEBIT_NOTES_PAGE}  component={DebitNotePage}  exact />
                            
                            <ClientRoute path={Url.ORDERS}            component={OrderList}      exact />
                            <ClientRoute path={Url.ORDERS_PAGE}       component={OrderList}      exact />
                            <ClientRoute path={Url.ORDERS_ONE}        component={OrderElem}      exact />
                            
                            <ClientRoute path={Url.QUERIES}           component={QueryList}      exact />
                            <ClientRoute path={Url.QUERIES_TYPE}      component={QueryList}      exact />
                            <ClientRoute path={Url.QUERIES_TYPE_PAGE} component={QueryList}      exact />
                            <ClientRoute path={Url.QUERIES_ONE}       component={QueryElem}      exact />
                            <ClientRoute path={Url.QUERIES_CREATE}    component={QueryCreate}    exact />
                            
                            <Redirect from="*" to={Url.ACCOUNT} />
                        </Switch>
                    </div>
                </div>
            </Wrapper>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout     : PropTypes.func.isRequired,
        location   : PropTypes.object.isRequired,
        isMicro    : PropTypes.bool.isRequired,
        isMobile   : PropTypes.bool.isRequired,
        settings   : PropTypes.object.isRequired,
        credential : PropTypes.object.isRequired,
        menuData   : PropTypes.object.isRequired,
        page       : PropTypes.number.isRequired,
        type       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isMicro    : state.core.isMicro,
            isMobile   : state.core.isMobile,
            settings   : state.core.settings,
            credential : state.auth.credential,
            menuData   : state.store.menuData,
            page       : state.client.page,
            type       : state.client.type,
        };
    }
}

export default connect(ClientContainer.mapStateToProps, {
    logout,
})(ClientContainer);
