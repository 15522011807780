import React                from "react";
import PropTypes            from "prop-types";

// Components
import HyperLink            from "Components/Utils/Common/HyperLink";



/**
 * The Dialog Content
 */
class DialogContent extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { hasTitle, onClose, className, withSpacing, children } = this.props;

        return <main className={`dialog-content ${className}` + (withSpacing ? " spacing" : "")}>
            {!hasTitle && <HyperLink
                className="dialog-close-float"
                variant="icon"
                icon="close"
                onClick={onClose}
            />}
            {children}
        </main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        onClose     : PropTypes.func,
        className   : PropTypes.string,
        hasTitle    : PropTypes.bool,
        withSpacing : PropTypes.bool,
        children    : PropTypes.any,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className   : "",
        withSpacing : false,
    }
}

export default DialogContent;
