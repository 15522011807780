export default {
    // Auth Urls
    LOGIN               : "/ingresar",
    REGISTER            : "/registrarse",
    RECOVER             : "/recuperar",
    RESET               : "/resetear",
    CODE                : "/resetear/:code",


    // Product Urls
    HOME                : "/",
    PRODUCTS            : "/productos",
    CATEGORIES          : "/categorias",
    OFFERS              : "/ofertas",
    BRANDS              : "/marcas",

    NEWESTS             : "/nuevos",
    IMPORTANTS          : "/destacados",
    FAVORITES           : "/favoritos",
    PURCHASES           : "/comprados",
    HISTORY             : "/historial",

    // Content Urls
    NEWS                : "/novedades",
    NEWS_VIEW           : "/novedad",

    CATALOG             : "/catalogo",
    CHAT                : "/chat",
    NOTIFICATIONS       : "/notificaciones",
    CART                : "/compras",
    CONTACT             : "/contacto",
    TERMS               : "/terminos-y-condiciones",

    // Account Urls
    ACCOUNT             : "/cuenta",
    ACCOUNT_DATA        : "/cuenta/datos",

    ADDRESSES           : "/cuenta/domicilios",
    ADDRESSES_PAGE      : "/cuenta/domicilios/:page",
    ADDRESSES_CREATE    : "/cuenta/domicilios/crear",
    ADDRESSES_VIEW      : "/cuenta/domicilio",
    ADDRESSES_ONE       : "/cuenta/domicilio/:address",

    QUERIES             : "/cuenta/consultas",
    QUERIES_TYPE        : "/cuenta/consultas/:type(abiertas|cerradas)",
    QUERIES_TYPE_PAGE   : "/cuenta/consultas/:type(abiertas|cerradas)/:page",
    QUERIES_OPEN        : "/cuenta/consultas/abiertas",
    QUERIES_CLOSED      : "/cuenta/consultas/cerradas",
    QUERIES_CREATE      : "/cuenta/consultas/crear",
    QUERIES_VIEW        : "/cuenta/consulta",
    QUERIES_ONE         : "/cuenta/consulta/:query",

    ORDERS              : "/cuenta/pedidos",
    ORDERS_PAGE         : "/cuenta/pedidos/:page",
    ORDERS_VIEW         : "/cuenta/pedido",
    ORDERS_ONE          : "/cuenta/pedido/:order",

    VOUCHERS            : "/cuenta/comprobantes",
    INVOICES            : "/cuenta/comprobantes/facturas",
    INVOICES_PAGE       : "/cuenta/comprobantes/facturas/:page",
    RECEIPTS            : "/cuenta/comprobantes/recibos",
    RECEIPTS_PAGE       : "/cuenta/comprobantes/recibos/:page",
    CREDIT_NOTES        : "/cuenta/comprobantes/notas-de-credito",
    CREDIT_NOTES_PAGE   : "/cuenta/comprobantes/notas-de-credito/:page",
    DEBIT_NOTES         : "/cuenta/comprobantes/notas-de-debito",
    DEBIT_NOTES_PAGE    : "/cuenta/comprobantes/notas-de-debito/:page",
};
